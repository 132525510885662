import React, { FC } from "react";
import { Button, Form, FormField, MessageField } from "@epcnetwork/core-ui-kit";

import { validationSchema } from "./sources-form.constants";
import useSourcesFormHook from "./sources-form.hook";

import styles from "./sources-form.module.css";
import closeImg from "assets/images/close-secrets.svg";

type Props = {
  id: string;
  onCloseModal: VoidFunction;
  refresh: VoidFunction;
  refreshMediaBuy?: VoidFunction;
};

const SourcesForm: FC<Props> = ({ id = "", onCloseModal, refresh, refreshMediaBuy }: Props) => {
  const { formProps, error, onSubmit } = useSourcesFormHook(
    id,
    onCloseModal,
    refresh,
    refreshMediaBuy,
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={closeImg} alt="" onClick={onCloseModal} />
      </div>
      <div className={styles.body}>
        <div className={styles.name}>
          {id ? `Edit: ${formProps.initialValues?.name || "Source"}` : `New Source`}
        </div>
        <MessageField message={error} className={styles.errorMsg} />
        <Form {...formProps} onSubmit={onSubmit} validationSchema={validationSchema}>
          <>
            <FormField type="text" name="name" label="Name" placeholder="Fill the name" required />
            <div className={styles.buttonWrapper}>
              <Button
                className={styles.cancelButton}
                type="button"
                appearance="secondary"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
              <Button type="submit">{id ? "Save" : "Add"}</Button>
            </div>
          </>
        </Form>
      </div>
    </div>
  );
};

export default SourcesForm;
