import React, { FC, useState } from "react";
import cn from "classnames";
import {
  Button,
  notification,
  FormButtons,
  FormField,
  useSubmit,
  useFormValues,
  Form,
  MessageField,
  NonNullableKeys,
  useAutocomplete,
} from "@epcnetwork/core-ui-kit";

import { MemberModel } from "models";
import { getMember, getGroups, postMembers, putMember } from "api";
import { usersRoles } from "../list/users-list.constants";
import { initialData, validationSchema, initialContent } from "./users-form.constants";
import { Values } from "./users-form.types";

import styles from "./users-form.module.css";
import addImg from "assets/images/plus-blue.svg";
import closeImg from "assets/images/delete.svg";

type Props = {
  id: string | null;
  handleItemChange?: (value: MemberModel) => void;
  handleCloseModal?: VoidFunction;
  refresh?: VoidFunction;
  isGroupTab?: boolean;
};

const UsersForm: FC<Props> = ({
  id,
  handleItemChange,
  handleCloseModal,
  refresh,
  isGroupTab = false,
}: Props) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState(initialContent);
  const { formProps, mapInitialValues } = useFormValues(
    initialData,
    getMember.setParams({ memberId: id as string }),
    !!id,
  );
  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    postMembers,
    putMember.setParams({ memberId: id as string }),
    !!id,
  );

  mapInitialValues((payload) => {
    return {
      ...payload,
      lastName: payload?.user?.lastname || "",
      name: payload?.user?.name,
      email: payload?.user?.email,
      items: [{ role: payload?.role, groupId: payload?.group?.id || "" }],
    };
  });

  onSubmitSuccess((payload) => {
    if (!!id && handleItemChange) {
      handleItemChange(payload);
    }

    if (!id && refresh) {
      refresh();
    }
    handleCloseModal && handleCloseModal();
    const title = id ? "User edited" : "Invitation sent";
    const subtitle = id ? "Successfully edited user" : `${email} has been sent an invitation`;
    notification.success(title, subtitle);
  });

  onSubmitError((error) => {
    setError(error.message);
  });

  const onSubmit = onSubmitMapping((values: NonNullableKeys<Values>) => {
    if (id) {
      return {
        items: values.items,
      };
    }

    return {
      email: values.email,
      items: values.items,
    };
  });

  const handleChangeEmail = (value: string) => setEmail(value);

  const handleAddGroup = () => {
    setContent((prev) => [
      ...prev,
      {
        id: prev.length,
        group: { value: "", label: "" },
        role: { value: "", label: "" },
      },
    ]);
  };

  const handleDeleteGroup = (groupId: number) => {
    const currentGroups = content.filter(({ id }) => id !== groupId);
    setContent(currentGroups);
  };

  const groupsAutocomplete = useAutocomplete(getGroups, "id", "name");

  return (
    <div className={styles.container}>
      <Form {...formProps} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values, setFieldValue }) => {
          return (
            <>
              <div className={cn(styles.title, { [styles.groupTitle]: isGroupTab })}>
                {!id ? "New User" : "Edit user"}
              </div>
              <MessageField message={error} size="small" />
              <div>
                <FormField
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="Enter email"
                  required
                  onChange={handleChangeEmail}
                  className={styles.field}
                  disabled={!!id}
                />
              </div>
              <div className={styles.row}>
                {id && (
                  <>
                    <FormField
                      label="First Name"
                      type="text"
                      name="name"
                      disabled
                      className={styles.field}
                    />

                    <FormField
                      label="Last Name"
                      type="text"
                      name="lastName"
                      disabled
                      className={styles.field}
                    />
                  </>
                )}
              </div>
              {content.map(({ id }, index) => {
                const disabled =
                  !isGroupTab &&
                  (!values.items[index] ||
                    !values.items[index].role ||
                    values.items[index].role === 50);

                const rolesOptions = usersRoles.filter((role) => {
                  if (isGroupTab) {
                    return role.value !== 50 && role.value !== 100;
                  }
                  return role;
                });
                return (
                  <div className={styles.contentWrapp} key={id}>
                    <FormField
                      type="select"
                      name={`items.${index}.role`}
                      placeholder="Choose a group role"
                      label="Role"
                      required
                      options={rolesOptions}
                      className={styles.field}
                      disableSingleOptionUncheck
                      disableClearing
                      onChange={(option) => {
                        if (option.value === 50) {
                          setFieldValue(`items.${index}.groupId`, undefined);
                          return;
                        }
                        setFieldValue(`items.${index}.groupId`, "");
                      }}
                    />

                    <FormField
                      type="select"
                      name={`items.${index}.groupId`}
                      label="Group"
                      placeholder="Choose a group"
                      asyncOptions={groupsAutocomplete}
                      disabled={disabled}
                      disableArrow={disabled}
                      className={styles.field}
                    />

                    {content.length > 1 && (
                      <Button
                        imageBtn
                        className={styles.copyWrapper}
                        onClick={() => handleDeleteGroup(id)}
                      >
                        <img src={closeImg} alt="" />
                      </Button>
                    )}
                  </div>
                );
              })}

              <Button
                type="button"
                onClick={handleAddGroup}
                appearance="secondary"
                className={styles.addGroupBtn}
              >
                <img src={addImg} alt="" className={styles.icon} />
                Add new row
              </Button>
              <FormButtons className={styles.buttonWrapper}>
                <Button
                  type="button"
                  onClick={handleCloseModal}
                  appearance="secondary"
                  className={styles.btn}
                >
                  Cancel
                </Button>
                <Button type="submit">{id ? "Save" : "Invite"}</Button>
              </FormButtons>
            </>
          );
        }}
      </Form>
    </div>
  );
};
export default UsersForm;
