import React, { memo } from "react";
import { useFetch } from "@epcnetwork/core-ui-kit";

import { GanttTimeline } from "components";
import { GanttGroupType } from "components/ui/gantt-timeline/gantt-timeline.types";
import { getGanttChartData } from "api";
import { ganttChartStatusMap } from "../campaigns-list.constants";

const GanttChart = memo(() => {
  const { payload } = useFetch(getGanttChartData);

  const clientsData = payload || [];

  const data: GanttGroupType[] = clientsData.map(({ name, campaigns }) => ({
    name,
    items: campaigns.map(({ name, startDate, endDate, allMediaBuysCount, status }) => ({
      name,
      startDate,
      endDate,
      description: `Medias: ${allMediaBuysCount}`,
      legend: {
        name: status,
        color: ganttChartStatusMap[status],
      },
    })),
  }));

  return (
    <div>
      <GanttTimeline title="Clients" data={data} />
    </div>
  );
});

export default GanttChart;
