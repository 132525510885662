import React, { FC } from "react";

import { SecretsAPITypesKeys } from "models";
import RestApi from "./rest-api";
import Bronto from "./bronto";
import Salesforce from "./salesforce";
import Iterable from "./iterable";

type Props = {
  apiType: SecretsAPITypesKeys;
};

const VendorsAPIDispatcher: FC<Props> = ({ apiType }: Props) => {
  switch (apiType) {
    case "restApi":
      return <RestApi />;
    case "bronto":
      return <Bronto />;
    case "salesforce":
      return <Salesforce />;
    case "iterable":
      return <Iterable />;
    default:
      return null;
  }
};

export default VendorsAPIDispatcher;
