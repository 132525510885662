import React, { FC, useState } from "react";
import { FormikHelpers } from "formik";
import cn from "classnames";
import {
  Button,
  FormButtons,
  FormField,
  useCall,
  Form,
  MessageField,
} from "@epcnetwork/core-ui-kit";

import { postMember } from "api";
import { usersRoles } from "pages/settings/users/list/users-list.constants";
import { initialValues, validationSchema } from "./invite-form.constants";
import { Values } from "./invite-form.types";
import ConfirmModal from "./confirm-modal/confirm-modal";

import styles from "./invite-form.module.css";

type Props = {
  id: string | undefined;
  handleChangeTab: (tabIndex: number) => void;
};

const InviteForm: FC<Props> = ({ id, handleChangeTab }: Props) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const { submit, onCallSuccess, onCallError } = useCall(postMember);

  onCallSuccess(() => {
    setIsOpen(true);
  });

  onCallError((error) => {
    setError(error.message);
  });

  const onSubmit = async (values: Values, helpers: FormikHelpers<Values>) => {
    await submit({ params: { groupId: id as string }, data: values });
    helpers.setSubmitting(false);
    helpers.resetForm();
  };

  const handleChangeEmail = (value: string) => setEmail(value);

  const handleModalClose = () => setIsOpen((prev) => !prev);

  const setInformationTab = () => {
    handleChangeTab(0);
    handleModalClose();
  };

  const rolesOptions = usersRoles.filter((role) => {
    return role.value !== 50 && role.value !== 100;
  });

  return (
    <div className={styles.container}>
      <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        <div className={cn(styles.title, styles.groupTitle)}>Invite User</div>
        <MessageField message={error} size="small" />
        <div className={styles.contentWrapp}>
          <FormField
            type="text"
            name="email"
            label="Email"
            placeholder="Enter email"
            required
            onChange={handleChangeEmail}
            className={styles.field}
          />
          <FormField
            type="select"
            name="role"
            placeholder="Choose a group role"
            label="Role"
            required
            options={rolesOptions}
            className={styles.field}
            disableSingleOptionUncheck
            disableClearing
          />
        </div>
        <FormButtons className={styles.buttonWrapper}>
          <Button type="submit">Invite</Button>
        </FormButtons>
        {isOpen && (
          <ConfirmModal
            isOpen={isOpen}
            email={email}
            setClose={handleModalClose}
            setInformationTab={setInformationTab}
          />
        )}
      </Form>
    </div>
  );
};

export default InviteForm;
