import React, { FC } from "react";
import { useField, useFormikContext } from "formik";
import CodeMirror from "react-codemirror";
import { MessageField, Label } from "@epcnetwork/core-ui-kit";

import { UrlParamType } from "../../vendors-form.types";

type Props = {
  initialValue?: string;
  wrapClassName?: string;
  codeMirrorClassName?: string;
  label?: string;
  name: string;
  error?: string;
};

export const options = {
  mode: { name: "javascript", json: true },
  lineNumbers: true,
  lineWrapping: true,
};

const JsonEditor: FC<Props> = ({
  initialValue,
  wrapClassName = "",
  codeMirrorClassName = "",
  label,
  ...rest
}: Props) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helpers] = useField({ name: rest.name });

  const { touched, error } = meta;
  const { setValue, setTouched } = helpers;

  const isTouched = Boolean(submitCount || touched);
  const errorMessage = rest?.error || (isTouched && error) || "";

  const handleChange = (value: string) => {
    setValue(value);
  };

  const handleFocus = (value: boolean) => {
    if (!value) {
      setTouched(value);
    }
  };

  return (
    <div className={wrapClassName}>
      {label && <Label text={label} floating={false} />}
      <CodeMirror
        className={codeMirrorClassName}
        value={field.value}
        onChange={handleChange}
        options={options}
        onFocusChange={handleFocus}
      />
      <MessageField message={errorMessage} size="medium" />
    </div>
  );
};

export default JsonEditor;

export const getJsonValueFromArray = (
  array: UrlParamType[] | null | undefined,
): string | undefined => {
  if (array && Array.isArray(array)) {
    const output = array.reduce<{ [key: string]: string }>((acc, { key, value }) => {
      acc[key] = value;
      return acc;
    }, {});
    return JSON.stringify(output);
  }
  return undefined;
};

export const structurePreview = (json: string | undefined): string | undefined => {
  if (json) {
    return json.replace(/,/, ",\n").replace(/{/, "{\n").replace(/}/, "\n}");
  }
  return undefined;
};
