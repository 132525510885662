import { createContext } from "react";

import { GanttContextStateType, GanttReactContextType } from "./gantt-timeline.types";

export const panningMovementWidth = 10;
export const panningMultiplier = 1;

export const GANTT_ITEM_MIN_WIDTH = 2;
export const GANTT_TITLE_WRAPPER_WIDTH = 240;

export const initialGanttContextState: GanttContextStateType = {
  timePeriodsWidth: 0,
};

export const GanttTimelineContext: GanttReactContextType = createContext(initialGanttContextState);
