import { array, mixed, object, SchemaOf, string } from "yup";
import { AnyObject } from "yup/es/types";
import { AnySchema } from "yup";

import { jsonValidation, mergeSchemas } from "utils";
import { SecretsAPITypes } from "models";
import { HttpMethods, HttpMethodsTypes } from "constants/forms.constants";
import {
  APISchemasMapType,
  BrontoType,
  IterableType,
  RestApiType,
  SalesforceType,
  UrlParamType,
  VendorAPIType,
  VendorFormInitialValuesType,
} from "./vendors-form.types";
import { paramsSchema } from "./api-type-components/components/url-parameter";

export const vendorModalInitialValues: VendorFormInitialValuesType = {
  name: "",
  apiType: null,
  secretId: "",
};

const defaultSchema = object().shape({
  name: string().min(3, "Field must have minimum 3 characters").required("This field is required"),
  apiType: string().required("This field is required").nullable(),
  secretId: string()
    .min(1, "Secret id is required")
    .typeError("Secret id is required")
    .required("Secret id is required"),
});

export const validationSchema = (
  apiType: VendorAPIType | null | undefined,
): AnyObject | APISchemasMapType => {
  if (!apiType) return defaultSchema;
  return mergeSchemas(defaultSchema, APISchemasMap[apiType] as AnySchema);
};

export const arrayToObject = (
  array: UrlParamType[] | null | undefined,
): { [key: string]: string } | undefined => {
  if (array && Array.isArray(array)) {
    return array.reduce<{ [key: string]: string }>((acc, { key, value }) => {
      acc[key] = value;
      return acc;
    }, {});
  }
  return undefined;
};

export const objectToArray = (
  object: { [key: string]: string } | null | undefined,
): Array<Record<string, string>> | undefined => {
  if (object) {
    return Object.entries(object).map(([key, value]) => ({ key, value }));
  }
  return undefined;
};

const httpMethodsValues: HttpMethods[] = Object.values(HttpMethods);

export const restApiSchema: SchemaOf<RestApiType> = object().shape({
  url: string().required("Url is required"),
  contentType: string().required("This field is required"),
  httpMethod: mixed().oneOf(httpMethodsValues).required("Api is required"),
  queryParams: array()
    .of(paramsSchema)
    .required("Params are required")
    .min(1, "Params list is empty"),
  bodyParams: array().when("httpMethod", (httpMethod: HttpMethodsTypes) => {
    switch (httpMethod) {
      case "POST":
        return array().of(paramsSchema).nullable().min(1, "Params list is empty");
      case "PUT":
        return array().of(paramsSchema).nullable().min(1, "Params list is empty");
      default:
        return array();
    }
  }),
});

export const brontoSchema: SchemaOf<BrontoType> = object({
  list: object()
    .shape({ id: string().required(), name: string() })
    .required("List Type is required")
    .nullable(),
});

export const vendorBrontoTypeOptions = Object.entries(SecretsAPITypes).map(([key, value]) => ({
  value: key as keyof typeof SecretsAPITypes,
  label: value,
}));

export const salesforceSchema: SchemaOf<SalesforceType> = object().shape({
  list: object()
    .shape({ id: string().required(), name: string() })
    .required("List Type is required")
    .nullable(),
  soapBody: string()
    .required("This field is required")
    .test("json", "Body is not a valid json", jsonValidation),
});

export const iterableSchema: SchemaOf<IterableType> = object().shape({
  list: object()
    .shape({ id: string().required(), name: string() })
    .required("List Type is required")
    .nullable(),
});

export const APISchemasMap: APISchemasMapType = {
  restApi: restApiSchema,
  bronto: brontoSchema,
  salesforce: salesforceSchema,
  getResponse: undefined,
  infusionsoft: undefined,
  iterable: iterableSchema,
  egoi: undefined,
  ontraport: undefined,
};
