import { Link } from "react-router-dom";
import React, { FC } from "react";
import { getFillColor } from "@epcnetwork/core-ui-kit";
import cn from "classnames";

import { ReactComponent as HomeImageSVG } from "assets/images/home.svg";
import styles from "../breadcrumbs.module.css";

const HomeCrumb: FC = () => {
  const imgStyles = cn(getFillColor({ status: "text-secondary" }));
  return (
    <li className={styles.li}>
      <Link className={styles.link} to="/">
        <HomeImageSVG className={imgStyles} />
      </Link>
    </li>
  );
};

export default HomeCrumb;
