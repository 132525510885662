import React, { FC } from "react";
import { Modal, Button } from "@epcnetwork/core-ui-kit";

import styles from "./confirm-modal.module.css";
import CheckMarkImg from "assets/images/circle-check-mark-green.svg";

type PopupProps = {
  email: string;
  isOpen: boolean;
  setClose: VoidFunction;
  setInformationTab?: VoidFunction;
};

const ConfirmModal: FC<PopupProps> = ({
  email,
  isOpen,
  setClose,
  setInformationTab,
}: PopupProps) => {
  return (
    <Modal isOpen={isOpen} setClose={setClose} contentClassName={styles.modal}>
      <img src={CheckMarkImg} className={styles.checkMark} alt="" />
      <div className={styles.title}>Invitation sent</div>
      <div className={styles.subtitle}>{`${email} has been sent an invitation`}</div>
      <div className={styles.buttonsWrapper}>
        <Button appearance="secondary" onClick={setClose} className={styles.button}>
          Add More Users
        </Button>
        <Button appearance="primary" onClick={setInformationTab} className={styles.button}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
