import { useState } from "react";
import {
  useCall,
  usePagination,
  useFilters,
  notification,
  useDevice,
  useFetch,
  getPaginatedList,
} from "@epcnetwork/core-ui-kit";

import { deleteSecret, getSecrets } from "api";
import { SecretModel } from "models";
import { filtersOptions, getQueryKeys } from "./secrets-list.constants";

interface Output {
  loading: boolean;
  refresh: VoidFunction;
  error: string | undefined;
  modalFormIsOpen: boolean;
  isMobileDevice: boolean;
  editId: string;
  onCloseModal: VoidFunction;
  addSecretHandle: VoidFunction;
  handleEdit: (id: string) => void;
  handleDeleteClick: (id: string) => void;
  pagination: ReturnType<typeof usePagination>;
  searchValue: string;
  setSearch: (value: string) => void;
  list: SecretModel[];
}

const useSecretsListHook = (): Output => {
  const { isMobileDevice } = useDevice();
  const { queryString, setSearch, searchValue } = useFilters({
    searchKey: filtersOptions.searchKey,
    nonClearableKeys: getQueryKeys(isMobileDevice),
  });

  const { payload, loading, refresh, error, actions } = useFetch(
    getSecrets.setQueryParams(queryString),
    { dependencies: [queryString] },
  );
  const list = getPaginatedList(payload?.data);
  const pagination = usePagination(payload);
  const { onCallSuccess, onCallError, submit } = useCall(deleteSecret);

  const [modalFormIsOpen, setModalFormIsOpen] = useState(false);
  const [editId, setEditId] = useState("");

  const setFilteredList = (list: SecretModel[]) => {
    if (payload) {
      const { setData } = actions;
      setData({ ...payload, data: list });
    }
  };

  onCallSuccess(() => {
    notification.success("Secret deleted", "Successfully deleted secret");
  });

  onCallError((error) => {
    refresh();
    notification.error("Deleting error", `Secret was ${error.message}`);
  });

  const onCloseModal = () => {
    setEditId("");
    setModalFormIsOpen(false);
  };

  const addSecretHandle = () => setModalFormIsOpen(true);

  const handleEdit = (id: string) => {
    setEditId(id);
    setModalFormIsOpen(true);
  };

  const handleDeleteClick = (id: string) => {
    notification.confirm("Deleting Secret?", "Are you sure?", {
      onOk: () => {
        submit({ params: { secretId: id } });
        setFilteredList(list.filter((el) => el.id !== id));
      },
      icon: "delete",
    });
  };

  return {
    loading,
    error: error?.message,
    refresh,
    modalFormIsOpen,
    editId,
    onCloseModal,
    addSecretHandle,
    handleEdit,
    handleDeleteClick,
    pagination,
    setSearch,
    searchValue,
    list,
    isMobileDevice,
  };
};

export default useSecretsListHook;
