import { TableColumnsType } from "types";
import { CollapseState, FiltersType, SearchFilterType } from "./secrets-list.types";

export const tableColumns = (isMobile: boolean): TableColumnsType[] => [
  { label: "Name", queryKey: "name", required: true },
  { label: "Type", queryKey: "apiType" },
  { label: "Created at", queryKey: "createdAt", selected: !isMobile },
  { label: "", queryKey: "" },
];

export const filtersOptions: SearchFilterType = { searchKey: "search" };

export const getQueryKeys = (isMobile: boolean): string[] =>
  tableColumns(isMobile).map(({ queryKey }) => `order[${queryKey}]`);

export const initialFilters: FiltersType = {
  createdAt: null,
  updatedAt: null,
};

export const entityName = "secrets-table";

export const initialCollapseState: CollapseState = {
  created: {
    isOpen: true,
  },
  updated: {
    isOpen: true,
  },
};
