import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";

import { LOGOUT_PAGE } from "constants/routes.constants";

import styles from "../navbar.module.css";

type Props = { history: RouteComponentProps["history"] };

const Menu: FC<Props> = ({ history }: Props) => {
  const handleLogout = () => history.push(LOGOUT_PAGE.path);

  return (
    <div className={styles.menu} data-testid="navbar-menu">
      <button className={styles.item} onClick={handleLogout}>
        Log out
      </button>
    </div>
  );
};

export default Menu;
