import { List } from "@epcnetwork/core-ui-kit";

import { ClientModel, CampaignModel, CampaignCountModel, ClientGanttModel } from "models";
import { apiMiddleware } from "../api.middleware";
import { PostClientData } from "./clients.interface";

export const getClients = apiMiddleware<List<ClientModel>>()({
  method: "get",
  endpoint: "/client",
});

export const getClient = apiMiddleware<ClientModel>()({
  method: "get",
  endpoint: `/client/:clientId`,
});

export const getCampaignsByClient = apiMiddleware<List<CampaignModel> & CampaignCountModel>()({
  method: "get",
  endpoint: "/client/:id/campaigns",
});

export const getGanttChartData = apiMiddleware<ClientGanttModel[]>()({
  method: "get",
  endpoint: "/client/info",
});

export const postClient = apiMiddleware<ClientModel, PostClientData>()({
  method: "post",
  endpoint: "/client",
});

export const putClient = apiMiddleware<ClientModel, PostClientData>()({
  method: "put",
  endpoint: `/client/:clientId`,
});

export const deleteClient = apiMiddleware()({
  method: "delete",
  endpoint: `/client/:clientId`,
});
