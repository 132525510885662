import React, { FC, ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider, NotificationProvider, ErrorBoundary } from "@epcnetwork/core-ui-kit";

import store from "store";
import { lightTheme } from "constants/themes.constants";
import AuthorizationGuard from "../authorization/authorization";
import { DEFAULT_PAGE } from "constants/routes.constants";

type Props = {
  children: ReactNode;
};

const themes = [lightTheme];

const Providers: FC<Props> = ({ children }: Props) => {
  const handleRefresh = () => (window.location.href = DEFAULT_PAGE.path);

  return (
    <Provider store={store}>
      <AuthorizationGuard>
        <ErrorBoundary
          title="Oops! Something went wrong"
          subtitle="Page could not be loaded. Please try later"
          refreshText="Go to homepage"
          refreshClick={handleRefresh}
        >
          <BrowserRouter>
            <ThemeProvider themes={themes} initialTheme={themes[0]}>
              <NotificationProvider>{children}</NotificationProvider>
            </ThemeProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </AuthorizationGuard>
    </Provider>
  );
};

export default Providers;
