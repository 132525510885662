import React, { FC, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  FetchingError,
  NotFound,
  Modal,
  useFetch,
  Loader,
  useFilters,
} from "@epcnetwork/core-ui-kit";

import { getCampaign, getCampaignStats } from "api";
import { usePayload, useShowContent } from "hooks";
import InfoTabs from "./info-tabs";
import MediaBuyListPage from "pages/media-buy/list";
import CampaignsForm from "../form";
import { CAMPAIGNS_LIST_PAGE } from "constants/routes.constants";
import Breadcrumbs from "./breadcrumbs/breadcrumbs";

import styles from "./campaigns-details.module.css";

type ParamsType = {
  id: string;
};

export type SearchFilterType = {
  searchKey: string;
};

const filtersOptions: SearchFilterType = { searchKey: "search" };

const CampaignsDetailsPage: FC = () => {
  const history = useHistory();
  const { id = "" } = useParams<ParamsType>();
  const { queryString } = useFilters(filtersOptions);

  const {
    payload: campaign,
    loading,
    refresh,
    error,
    details,
    actions,
  } = useFetch(getCampaign.setParams({ campaignId: id }).setQueryParams(queryString), {
    dependencies: [queryString],
  });

  const { showContent, showError, showLoader, showNoContent } = useShowContent({
    payload: campaign,
    loading,
    error,
    details,
  });

  const apiResponseCampaignStats = useFetch(getCampaignStats.setParams({ campaignId: id }));
  const { payload: campaignStats, refresh: refreshCampaignStats } =
    usePayload(apiResponseCampaignStats);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [clientId, setClientId] = useState("");
  const [campaignId, setCampaignId] = useState("");

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
    setClientId("");
    setCampaignId("");
  };

  const handleEdit = (clientId: string, campaignId: string) => {
    setClientId(clientId);
    setCampaignId(campaignId);
    setIsOpenEditModal(true);
  };

  const goToCampaigns = () => {
    history.push(CAMPAIGNS_LIST_PAGE.path);
  };

  if (!campaign) {
    return null;
  }

  return (
    <>
      <Breadcrumbs name={campaign?.name || ""} campaign={campaign} />
      <Container contentClassName={styles.containerWrap}>
        {showContent && (
          <InfoTabs
            campaign={campaign}
            handleEdit={handleEdit}
            campaignStats={campaignStats}
            setData={actions.setData}
          />
        )}
        {showLoader && <Loader />}
        {showNoContent && <NotFound btnText="Go to Campaigns" btnClick={goToCampaigns} />}
        {showError && (
          <FetchingError
            title="Cannot fetch the campaign"
            subtitle={error?.message || ""}
            refreshText="Go back"
            refreshClick={goToCampaigns}
          />
        )}
        {!showNoContent && !showError && (
          <MediaBuyListPage
            refreshCampaignPage={refreshCampaignStats}
            campaignStatus={campaign?.status}
          />
        )}

        <Modal
          isOpen={isOpenEditModal}
          setClose={handleCloseEditModal}
          contentClassName={styles.modal}
        >
          <CampaignsForm
            handleCloseModal={handleCloseEditModal}
            editClientId={clientId}
            campaignId={campaignId}
            refresh={refresh}
          />
        </Modal>
      </Container>
    </>
  );
};

export default CampaignsDetailsPage;
