import { object, string } from "yup";

import { passwordValidation } from "utils";

export const initialValues = {
  email: "",
  name: "",
  lastname: "",
  password: "",
};

export const validationSchema = object().shape({
  email: string().email("Invalid Email").required("Email is required"),
  name: string().required("First Name is required"),
  lastname: string().required("Last Name is required"),
  password: string()
    .min(8, "Password needs to include at least 8 characters")
    .max(30, "Password is too long")
    .test("password validation", "", passwordValidation),
});
