import React, { FC, ReactNode } from "react";

import Crumb from "./components/crumb";
import CrumbSeparator from "./components/crumb-separator";

import styles from "./breadcrumbs.module.css";

type BreadcrumbsProps = {
  children: ReactNode;
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ children }: BreadcrumbsProps) => {
  return (
    <nav className={styles.wrapper}>
      <ul className={styles.list}>
        <Crumb appearance="home" path="/" />
        <CrumbSeparator />
        {children}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
