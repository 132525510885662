import { Location } from "history";
import { matchPath } from "react-router-dom";

import { RouteConfig, routes } from "config/routes.config";
import { CLIENTS_LIST_PAGE } from "constants/routes.constants";

export const isActiveRoute = (routePath: string, location: Location, exact = false): boolean => {
  if (routePath === CLIENTS_LIST_PAGE.path && location.pathname === "/") {
    return true;
  }

  return Boolean(matchPath(location.pathname, { path: routePath, exact }));
};

export const getActiveRoute = (location: Location, routesList: RouteConfig[]): typeof routes[0] => {
  const route = routesList.find((route) => isActiveRoute(route.path, location, route.exact));

  if (route) {
    return route;
  }
  return routesList[routesList.length - 1];
};

export const linkTo = (path: string, id: string): string => {
  return path.replace(/:id/, id);
};
