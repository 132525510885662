import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormikHelpers } from "formik";
import { useDidMount, useDidUpdate, useFetch, useSubmit, useQuery } from "@epcnetwork/core-ui-kit";

import { usePayload } from "hooks";
import { isRegistrationTokenValid, registration } from "api";
import { RegistrationData } from "api/auth/auth.interface";
import { LOGIN_PAGE } from "constants/routes.constants";

interface Output {
  onSubmit: (values: RegistrationData, helpers: FormikHelpers<RegistrationData>) => Promise<void>;
  errorMessage: string;
  isLinkExpired: boolean;
  userEmail: string;
}

const useSendEmailHook = (): Output => {
  const { query } = useQuery();
  const history = useHistory();

  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const queryValue = String(query.key || "");

  useDidMount(() => {
    if (!queryValue) {
      setIsLinkExpired(true);
    }
  });

  const { onSubmit, onSubmitSuccess } = useSubmit(registration.setParams({ key: queryValue }));

  const apiResponse = useFetch(isRegistrationTokenValid.setParams({ key: queryValue }));
  const { payload, error } = usePayload(apiResponse);

  useDidUpdate(() => {
    if (payload && payload.email && !userEmail) {
      setUserEmail(payload.email);
    }

    if (error) setIsLinkExpired(true);
  }, [payload, error]);

  onSubmitSuccess(() => {
    moveToLogin();
  });

  const moveToLogin = () => history.push(LOGIN_PAGE.path + "?created=true");

  const errorMessage = error?.message || "";

  return {
    onSubmit,
    errorMessage,
    isLinkExpired,
    userEmail,
  };
};

export default useSendEmailHook;
