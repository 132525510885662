import React, { FC } from "react";

import { Breadcrumbs as CoreBreadcrumbs, Crumb } from "components";

import { SECRETS_PAGE } from "constants/routes.constants";

const Breadcrumbs: FC = () => {
  return (
    <CoreBreadcrumbs>
      <Crumb appearance="text" name={SECRETS_PAGE.name} />
    </CoreBreadcrumbs>
  );
};

export default Breadcrumbs;
