import React, { FC, useState, useCallback } from "react";
import classnames from "classnames";
import {
  Filters,
  Select,
  DateInput,
  useAutocomplete,
  CollapseSelfControlled,
  Button,
} from "@epcnetwork/core-ui-kit";

import { getUsers } from "api";
import { appTimezone } from "constants/user.constants";
import { initialCollapseState, initialFilters } from "../users-list.constants";

import styles from "./list-filters.module.css";
import arrowImg from "assets/images/arrow-down.svg";

const ListFilters: FC = () => {
  const useClientsAutocomplete = useAutocomplete(getUsers, "userId", "email", {
    searchKey: "search",
  });

  const [isCollapseState, setIsCollapseState] = useState(initialCollapseState);

  const handleCollapseView = useCallback(
    (key: string) => {
      const stateCollapse = { ...isCollapseState };
      stateCollapse[key].isOpen = !stateCollapse[key].isOpen;
      setIsCollapseState(stateCollapse);
    },
    [isCollapseState],
  );

  const headerCollapse = (key: string) => {
    let label = key;

    if (key === "createdDate") {
      label = "Created At";
    }

    return (
      <Button
        appearance="text"
        className={styles.collapseWrapper}
        onClick={() => handleCollapseView(key)}
      >
        <div className={styles.leftPart}>
          <img
            src={arrowImg}
            alt=""
            className={classnames({ [styles.revertArrow]: isCollapseState[key].isOpen })}
          />
          <div className={styles.label}>{`By ${label}`}</div>
        </div>
      </Button>
    );
  };

  return (
    <Filters initialFilters={initialFilters}>
      {({ getDatePickerDate, setDatePickerDate, getSelect, setSelect }) => {
        const [createdFrom, createdTo] = getDatePickerDate("createdDate");
        return (
          <div>
            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={headerCollapse("email")}
                initiallyOpened={isCollapseState["email"].isOpen}
                removeFromDOMOnClosed
                triggerClassName={styles.triggerElement}
              >
                <Select
                  selectedOptionsKeys={getSelect("email", true)}
                  asyncOptions={useClientsAutocomplete}
                  onChange={setSelect("email", true)}
                  placeholder="Select User"
                  isMulti
                  disableError
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>

            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={headerCollapse("createdDate")}
                initiallyOpened={isCollapseState["createdDate"].isOpen}
                removeFromDOMOnClosed
                triggerClassName={styles.triggerElement}
              >
                <DateInput
                  className={styles.inputDate}
                  dateFrom={createdFrom}
                  dateTo={createdTo}
                  onChange={setDatePickerDate("createdDate", true)}
                  isMulti
                  disableError
                  timezone={appTimezone}
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>
          </div>
        );
      }}
    </Filters>
  );
};

export default ListFilters;
