import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

import styles from "./image-link.module.css";
import ExternalLinkImg from "assets/images/external-link.svg";

type ImageLinkProps = {
  to: string;
  children: ReactNode;
};

const ImageLink: FC<ImageLinkProps> = ({ to, children }: ImageLinkProps) => {
  return (
    <Link className={styles.link} to={to}>
      <img src={ExternalLinkImg} className={styles.externalIcon} alt="" />
      {children}
    </Link>
  );
};

export default ImageLink;
