import React, { FC, useState, useCallback } from "react";
import classnames from "classnames";
import {
  CollapseSelfControlled,
  Filters,
  Select,
  DateInput,
  useAutocomplete,
  useDevice,
  Button,
} from "@epcnetwork/core-ui-kit";

import { getClients } from "api";
import { appTimezone } from "constants/user.constants";
import { initialCollapseState, getQueryKeys } from "../campaigns-list.constants";

import styles from "./list-filters.module.css";
import arrowDownImg from "assets/images/arrow-down.svg";

type Props = {
  shouldShowFilter?: boolean;
};

const ListFilters: FC<Props> = ({ shouldShowFilter }: Props) => {
  const { isMobileDevice } = useDevice();
  const useClientsAutocomplete = useAutocomplete(getClients, "id", "name");
  const queryKeys = getQueryKeys(isMobileDevice);
  const nonClearableKeys = ["search", "status"].concat(queryKeys);

  const [isCollapseState, setIsCollapseState] = useState(initialCollapseState);

  const handleCollapseView = useCallback(
    (key: string) => {
      const stateCollapse = { ...isCollapseState };
      stateCollapse[key].isOpen = !stateCollapse[key].isOpen;
      setIsCollapseState(stateCollapse);
    },
    [isCollapseState],
  );

  const headerCollapse = (key: string) => {
    let label = key;

    if (key === "startDate") {
      label = "start date";
    }
    if (key === "endDate") {
      label = "end date";
    }

    return (
      <Button
        appearance="text"
        className={styles.collapseWrapper}
        onClick={() => handleCollapseView(key)}
      >
        <div className={styles.leftPart}>
          <img
            src={arrowDownImg}
            alt=""
            className={classnames({ [styles.revertArrow]: isCollapseState[key].isOpen })}
          />
          <div className={styles.label}>{`By ${label}`}</div>
        </div>
      </Button>
    );
  };

  return (
    <Filters nonClearableKeys={nonClearableKeys}>
      {({ getDatePickerDate, setDatePickerDate, getSelect, setSelect }) => {
        const [startFrom, startTo] = getDatePickerDate("startDate");
        const [endFrom, endTo] = getDatePickerDate("endDate");

        return (
          <div>
            {shouldShowFilter && (
              <>
                <Filters.ContentItem>
                  <CollapseSelfControlled
                    triggerElement={headerCollapse("client")}
                    initiallyOpened={isCollapseState["client"].isOpen}
                    removeFromDOMOnClosed
                    triggerClassName={styles.triggerElement}
                  >
                    <Select
                      selectedOptionsKeys={getSelect("clientId", true)}
                      asyncOptions={useClientsAutocomplete}
                      onChange={setSelect("clientId", true)}
                      placeholder="Select Client"
                      isMulti
                      disableError
                    />
                  </CollapseSelfControlled>
                </Filters.ContentItem>
              </>
            )}

            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={headerCollapse("startDate")}
                initiallyOpened={isCollapseState["startDate"].isOpen}
                removeFromDOMOnClosed
                triggerClassName={styles.triggerElement}
              >
                <DateInput
                  className={styles.inputDate}
                  dateFrom={startFrom}
                  dateTo={startTo}
                  onChange={setDatePickerDate("startDate", true)}
                  isMulti
                  disableError
                  timezone={appTimezone}
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>

            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={headerCollapse("endDate")}
                initiallyOpened={isCollapseState["endDate"].isOpen}
                removeFromDOMOnClosed
                triggerClassName={styles.triggerElement}
              >
                <DateInput
                  className={styles.inputDate}
                  dateFrom={endFrom}
                  dateTo={endTo}
                  onChange={setDatePickerDate("endDate", true)}
                  isMulti
                  disableError
                  timezone={appTimezone}
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>
          </div>
        );
      }}
    </Filters>
  );
};

export default ListFilters;
