export type SearchFilterType = {
  searchKey: string;
};

export type ParamsType = {
  id: string;
};

export enum StatusTypes {
  active = "Active",
  inactive = "Inactive",
  stopped = "Stopped",
}

export type CollapseStateKeys = "createdAt" | "updatedAt" | "clientId" | "campaignId" | "status";

export type StatusTypesKeys = keyof typeof StatusTypes;

export type CollapseState = {
  [key: string]: {
    isOpen: boolean;
  };
};

export type FiltersType = {
  campaignId: string[];
  clientId: string[];
  status: string[];
  createdAt: string | null;
  updatedAt: string | null;
};

export type TableColumnType = {
  label: string;
  queryKey: string;
  required?: boolean;
  selected?: boolean;
};
