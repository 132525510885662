import React, { FC, memo, useRef, useContext } from "react";
import {
  getJSDate,
  lerp,
  isWithinRange,
  normalizeClamped,
  applyCSSToElement,
  addClassesToElement,
  removeClassesFromElement,
  useTimeout,
} from "@epcnetwork/core-ui-kit";

import { GANTT_TITLE_WRAPPER_WIDTH, GanttTimelineContext } from "../gantt-timeline.constants";

import globalStyles from "assets/styles/global.module.css";
import styles from "../gantt-timeline.module.css";

export interface GanttCurrentTimeLineProps {
  visibleFrom: number;
  visibleTo: number;
}

const GanttCurrentTimeLine: FC<GanttCurrentTimeLineProps> = memo(
  ({ visibleFrom, visibleTo }: GanttCurrentTimeLineProps) => {
    const { timePeriodsWidth } = useContext(GanttTimelineContext);

    const timePeriodsWidthRef = useRef(timePeriodsWidth);
    timePeriodsWidthRef.current = timePeriodsWidth;

    const visibleRangeRef = useRef({ visibleFrom, visibleTo });
    visibleRangeRef.current = { visibleFrom, visibleTo };

    const lineRef = useRef<HTMLDivElement>(null);

    const handleCalc = () => {
      const currentTimestamp = +getJSDate();

      const { visibleFrom, visibleTo } = visibleRangeRef.current;

      const isWithinDates =
        isWithinRange(currentTimestamp, visibleFrom, visibleTo) ||
        isWithinRange(currentTimestamp, visibleFrom, visibleTo);

      const line = lineRef.current;
      if (!line || !isWithinDates) {
        addClassesToElement(line, globalStyles.deleteElement);
        return;
      }
      removeClassesFromElement(line, globalStyles.deleteElement);

      const normalized = normalizeClamped(currentTimestamp, visibleFrom, visibleTo);

      const widthProjection =
        lerp(0, timePeriodsWidthRef.current, normalized) + GANTT_TITLE_WRAPPER_WIDTH;

      applyCSSToElement(line, {
        left: `${widthProjection}px`,
      });
    };

    useTimeout(handleCalc, 5000, { type: "interval" });

    handleCalc();

    return <div className={styles.currentTimeLine} ref={lineRef} />;
  },
);

export default GanttCurrentTimeLine;
