import React, { FC, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FormikProps } from "formik";
import {
  Button,
  MessageField,
  Form,
  FormField,
  notification,
  useAutocomplete,
  useFormValues,
  useSubmit,
  useCall,
  NonNullableKeys,
  formatDate,
} from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import {
  getExternalVendors,
  postMediaBuy,
  getSources,
  postSource,
  getMediaBuy,
  putMediaBuy,
} from "api";
import {
  initialValues,
  PLACEHOLDER_VENDOR,
  validationSchema,
  radioOptions,
  PLACEHOLDER_SOURCE,
} from "./media-buy-form.constants";
import { InitialValues } from "./media-buy-form.types";
import { getDateWithTimezone } from "utils";

import styles from "./media-buy-form.module.css";
import closeImg from "assets/images/close-secrets.svg";
import urlImg from "assets/images/url.svg";
import calendarGreyImg from "assets/images/calendar-grey.svg";

interface Props {
  id: string;
  handleCloseModal: VoidFunction;
  campaignId: string;
  isDuplication: boolean;
  refresh: VoidFunction;
  refreshCampaignPage: VoidFunction;
}

const MediaBuyAddForm: FC<Props> = ({
  id,
  handleCloseModal,
  campaignId,
  isDuplication,
  refresh,
  refreshCampaignPage,
}: Props) => {
  const formRef = useRef<FormikProps<typeof initialValues>>(null);
  const { organization } = useSelector((state: RootState) => state.auth);

  const [error, setError] = useState("");

  const submitReq =
    id && !isDuplication
      ? putMediaBuy.setParams({ mediaBuyId: id })
      : postMediaBuy.setParams({ campaignId });
  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(submitReq);
  const { formProps, mapInitialValues, payload } = useFormValues(
    initialValues,
    getMediaBuy.setParams({ mediaBuyId: id }),
    !!id,
  );

  mapInitialValues((payload) => {
    return {
      externalVendors: payload.externalVendors.map(({ id }) => id),
      name: payload.name,
      redirectUrl: payload.redirectUrl,
      type: payload.type,
      sourceId: payload.sourceId || "",
      siteId: payload.siteId,
      postbackUrl: payload.postbackUrl || "",
    };
  });

  const { submit, submitting, onCallSuccess, onCallError } = useCall(postSource);

  const sourcesAutocomplete = useAutocomplete(getSources, "id", "name", {
    searchKey: "search",
  });

  const externalVendorsAutocomplete = useAutocomplete(getExternalVendors, "id", "name", {
    searchKey: "search",
  });

  onSubmitError((error) => {
    setError(error.message);
  });

  onSubmitSuccess(() => {
    handleCloseModal();
    notification.success(`Media buy created`, `Successfully created media buy`);
    refresh();
    refreshCampaignPage();
  });

  onCallSuccess((payload) => {
    const newSource = {
      ...payload,
      label: payload.name,
      value: payload.id,
    };

    formRef.current?.setFieldValue("sourceId", payload.id);
    sourcesAutocomplete.actions.setData(sourcesAutocomplete.fetchOptions.concat(newSource));
  });

  onCallError((error) => notification.error(`Cannot create source`, error.message));

  const handleSubmit = onSubmitMapping((values: NonNullableKeys<InitialValues>) => {
    return {
      name: values.name.trim(),
      externalVendors: values.externalVendors,
      sourceId: values.sourceId,
      type: values.type,
      redirectUrl: values.redirectUrl,
      postbackUrl: values.postbackUrl,
      siteId: values.siteId,
    };
  });

  const handleAddNewOption = (value: string) =>
    submit({ data: { name: value, organizationId: organization?.id as string } });

  const title = isDuplication
    ? "Duplicate"
    : id
    ? `Edit: ${payload?.name || "Media Buy"}`
    : `New Media Buy`;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={closeImg} alt="" onClick={handleCloseModal} />
      </div>
      <div className={styles.body}>
        <div className={styles.name}>{title}</div>
        <MessageField message={error} className={styles.errorMsg} />
        <Form
          {...formProps}
          onSubmit={handleSubmit}
          validationSchema={[validationSchema]}
          innerRef={formRef}
        >
          <FormField
            className={styles.input}
            name="name"
            type="text"
            label="Name"
            placeholder="Fill the name"
            required
          />
          <FormField
            className={`${styles.inputPadding} ${styles.input}`}
            name="redirectUrl"
            type="text"
            label="Redirect Url"
            required
          >
            <div className={styles.imgWrapper}>
              <img src={urlImg} alt="" />
            </div>
          </FormField>
          <FormField
            name="externalVendors"
            type="select"
            label="External Vendor"
            placeholder={PLACEHOLDER_VENDOR}
            asyncOptions={externalVendorsAutocomplete}
            required
            isMulti
          />
          <div className={styles.wrapper}>
            <span className={styles.labelType}>Type *</span>
            <div className={styles.types}>
              <FormField type="radio" name="type" options={radioOptions} />
            </div>
          </div>
          <FormField
            className={styles.input}
            name="sourceId"
            type="select"
            label="Source"
            placeholder={PLACEHOLDER_SOURCE}
            asyncOptions={{
              ...sourcesAutocomplete,
              loading: submitting || sourcesAutocomplete.loading,
            }}
            onCreateNewOption={handleAddNewOption}
            isTextEditable
            required
          />
          <FormField
            className={styles.input}
            name="siteId"
            type="text"
            label="Site Id"
            placeholder="Fill the site ID"
          />
          <FormField
            className={`${styles.inputPadding} ${styles.input}`}
            name="postbackUrl"
            type="text"
            label="Postback Url"
          >
            <div className={styles.imgWrapper}>
              <img src={urlImg} alt="" />
            </div>
          </FormField>
          {payload && (
            <div>
              <div className={styles.time}>
                Created At: <img src={calendarGreyImg} alt="" className={styles.icon} />
                <span className={styles.date}>
                  {formatDate(getDateWithTimezone(payload.createdAt), "MMM dd, yyyy hh:mm:ss")}
                </span>
              </div>
              <div className={styles.time}>
                Updated At: <img src={calendarGreyImg} alt="" className={styles.icon} />
                <span className={styles.date}>
                  {formatDate(getDateWithTimezone(payload.updatedAt), "MMM dd, yyyy hh:mm:ss")}
                </span>
              </div>
            </div>
          )}
          <div className={styles.buttonWrapper}>
            <Button
              type="button"
              appearance="secondary"
              onClick={handleCloseModal}
              className={styles.cancelButton}
            >
              Cancel
            </Button>
            <Button type="submit">{id ? "Edit" : "Add"}</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default MediaBuyAddForm;
