import React, { FC } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  TextEllipsis,
  formatDate,
} from "@epcnetwork/core-ui-kit";

import { getDateWithTimezone } from "utils";
import { SecretModel, SecretsAPITypes } from "models";

import styles from "./table-row.module.css";

type Props = {
  item: SecretModel;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
};

const Row: FC<Props> = ({ item, handleEdit, handleDelete }: Props) => {
  const { name, apiType, createdAt, id } = item;
  const handleDeleteClick = () => handleDelete(id);

  const handleEditClick = () => handleEdit(id);

  return (
    <TableRow id={id}>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {name}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {SecretsAPITypes[apiType]}
        </TextEllipsis>
      </TableCell>
      <TableCell>{formatDate(getDateWithTimezone(createdAt), "MMM d, yyyy")}</TableCell>
      <TableCell className={styles.hideOnMobile} data-testid="actionsButton">
        <Menu>
          <MenuButton onClick={handleEditClick} icon="edit">
            Edit
          </MenuButton>
          <MenuButton onClick={handleDeleteClick} appearance="delete" icon="delete">
            Delete
          </MenuButton>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default Row;
