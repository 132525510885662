import React, { FC, ReactNode } from "react";

import styles from "../breadcrumbs.module.css";

type TypographyProps = {
  children: ReactNode;
};

const Typography: FC<TypographyProps> = ({ children }: TypographyProps) => {
  return <li className={styles.typography}>{children}</li>;
};

export default Typography;
