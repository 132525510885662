import { CampaignStatusTypes } from "models";
import { CollapseState, Statuses } from "./campaigns-list.types";

import { TableColumnsType } from "types";

export const initialSearchFilters = {
  searchKey: "search",
  initialState: {
    search: "",
    status: "" as Statuses,
    offset: 0,
  },
};

export const initialCollapseState: CollapseState = {
  client: {
    isOpen: true,
  },
  startDate: {
    isOpen: true,
  },
  endDate: {
    isOpen: true,
  },
};

export const TABLE_COLUMNS = (isMobile: boolean): TableColumnsType[] => [
  { label: "Name", queryKey: "name", required: true },
  { label: "Client", queryKey: "clientId", selected: !isMobile },
  { label: "All Medias", queryKey: "allMediaBuysCount", required: true },
  { label: "Broken Medias", queryKey: "brokenMediaBuysCount", selected: !isMobile },
  { label: "Active Medias", queryKey: "activeMediaBuysCount", selected: false },
  { label: "Inactive Medias", queryKey: "inactiveMediaBuysCount", selected: false },
  { label: "Clicks", queryKey: "interactionsCount", selected: !isMobile },
  { label: "Type", queryKey: "type", selected: !isMobile },
  { label: "Start", queryKey: "startDate", selected: !isMobile },
  { label: "End", queryKey: "endDate" },
  { label: "", queryKey: "" },
];

export const getQueryKeys = (isMobile: boolean): string[] =>
  TABLE_COLUMNS(isMobile).map(({ queryKey }) => `order[${queryKey}]`);

export const TABS = {
  All: "All",
  Active: "Active",
  Paused: "Paused",
  Pending: "Pending",
  Completed: "Completed",
} as const;

export const tabStatesMap: Record<number, Statuses> = {
  0: "",
  1: "active",
  2: "paused",
  3: "pending",
  4: "completed",
};

export const entityName = "campaigns-table";

export const ganttChartStatusMap: Record<CampaignStatusTypes, string> = {
  completed: "#5178F6",
  pending: "#BEC4CF",
  paused: "#DDB453",
  active: "#5BB383",
  all: "",
};
