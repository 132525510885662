import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useWindowSize } from "@epcnetwork/core-ui-kit";

import Link from "./link";
import MenuLink from "./menu-link";
import {
  USERS_LIST_PAGE,
  SECRETS_PAGE,
  CLIENTS_LIST_PAGE,
  CAMPAIGNS_LIST_PAGE,
  EXTERNAL_VENDORS_PAGE,
  ROLES_LIST_PAGE,
  STRUCTURE_LIST_PAGE,
  SOURCES_LIST_PAGE,
} from "constants/routes.constants";
import { useAuthorization } from "hooks";
import { RootState } from "store";
import { AuthorizationActionType, AuthorizationEntityName } from "models";
import { ME_ROLE } from "constants/auth.constants";

import styles from "../sidebar.module.css";
import clientsIcon from "assets/images/menu-clients.svg";
import secretIcon from "assets/images/menu-secret.svg";
import campaignIcon from "assets/images/menu-campaign.svg";
import externalVendorsIcon from "assets/images/external-vendors.svg";
import usersIcon from "assets/images/gear.svg";
import organizationIcon from "assets/images/menu-organization.svg";
import sourcesIcon from "assets/images/source.svg";

export type LinksType = {
  src?: string;
  text: string;
  to: string;
  authAction?: AuthorizationActionType;
  authEntity?: AuthorizationEntityName;
  exact?: boolean;
  isMobile: boolean;
  links?: LinksType[];
  initiallyOpened?: boolean;
};

const Menu: FC = () => {
  const ability = useAuthorization();
  const { width } = useWindowSize();
  const { user } = useSelector((state: RootState) => state.auth);

  const isHiddenForMobile = (link: LinksType) => {
    return link.isMobile ? true : width > 992;
  };

  const getLists = () => {
    return user?.type === ME_ROLE.super_admin && !user?.member ? superAdminLinks : links;
  };

  const permittedLinks = getLists().filter((link) => {
    const { authEntity } = link;
    if (authEntity) {
      return ability.can(AuthorizationActionType.read, authEntity);
    }
    return true;
  });

  const availableLinks = permittedLinks.filter((link) => isHiddenForMobile(link));

  const menuComponent = (link: LinksType) => {
    const { links, text } = link;
    if (links) {
      return <MenuLink triggerLink={link} links={links} key={text} />;
    }
    return <Link {...link} key={text} />;
  };

  return <div className={styles.menu}>{availableLinks.map((link) => menuComponent(link))}</div>;
};

export default Menu;

const usersSettingsMenuLinks: LinksType[] = [
  {
    text: USERS_LIST_PAGE.name,
    to: USERS_LIST_PAGE.path,
    isMobile: true,
  },
  {
    text: ROLES_LIST_PAGE.name,
    to: ROLES_LIST_PAGE.path,
    authAction: AuthorizationActionType.readList,
    isMobile: true,
  },
  {
    text: STRUCTURE_LIST_PAGE.name,
    to: STRUCTURE_LIST_PAGE.path,
    authAction: AuthorizationActionType.readList,
    isMobile: true,
  },
];

const superAdminLinks: LinksType[] = [
  {
    src: organizationIcon,
    text: "Organization list",
    to: "/",
    isMobile: true,
  },
];

const links: LinksType[] = [
  {
    src: clientsIcon,
    text: CLIENTS_LIST_PAGE.name,
    to: CLIENTS_LIST_PAGE.path,
    isMobile: true,
    authEntity: AuthorizationEntityName.clients,
  },
  {
    src: campaignIcon,
    text: CAMPAIGNS_LIST_PAGE.name,
    to: CAMPAIGNS_LIST_PAGE.path,
    isMobile: true,
    authEntity: AuthorizationEntityName.campaigns,
  },
  {
    src: secretIcon,
    text: SECRETS_PAGE.name,
    to: SECRETS_PAGE.path,
    isMobile: true,
    authEntity: AuthorizationEntityName.secrets,
  },
  {
    src: externalVendorsIcon,
    text: EXTERNAL_VENDORS_PAGE.name,
    to: EXTERNAL_VENDORS_PAGE.path,
    isMobile: true,
    authEntity: AuthorizationEntityName.vendors,
  },
  {
    src: sourcesIcon,
    text: SOURCES_LIST_PAGE.name,
    to: SOURCES_LIST_PAGE.path,
    isMobile: true,
    authEntity: AuthorizationEntityName.sources,
  },
  {
    src: usersIcon,
    text: "Admin Settings",
    to: USERS_LIST_PAGE.path,
    isMobile: false,
    links: usersSettingsMenuLinks,
    initiallyOpened: true,
    authEntity: AuthorizationEntityName.adminSettings,
  },
];
