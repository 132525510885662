import { string, object } from "yup";

import { InitialValues } from "./sources-form.types";

export const validationSchema = object().shape({
  name: string().min(3, "Name must have minimum 3 characters").required("This field is required"),
});

export const initialValues: InitialValues = {
  name: "",
};
