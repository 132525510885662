import React, { FC, useState } from "react";
import cn from "classnames";
import {
  TableRow,
  TableCell,
  notification,
  MenuButton,
  Menu,
  useCall,
  formatDate,
  TextEllipsis,
  Button,
} from "@epcnetwork/core-ui-kit";

import { getDateWithTimezone } from "utils";
import { OrganizationModel } from "models";
import store, { setToken } from "store";
import { changeOrganization, deleteOrganization } from "api";
import { TOKEN_STORAGE_FIELD, REFRESH_TOKEN_STORAGE_FIELD } from "constants/auth.constants";

import styles from "./table-row.module.css";
import userPlaceholderImg from "assets/images/user.svg";

type Props = {
  item: OrganizationModel;
  handleEdit: (id: string) => void;
};

const Row: FC<Props> = ({ item, handleEdit }: Props) => {
  const [isDeleted, setDeleted] = useState(false);
  const { id, image, name, createdAt } = item;

  const { submit, onCallSuccess, onCallError } = useCall(deleteOrganization);
  const { submit: submitOrganization, onCallSuccess: onSuccessOrganization } =
    useCall(changeOrganization);

  onCallSuccess(() => {
    notification.success("Organization deleted", "Successfully deleted organization");
  });

  onCallError(() => {
    setDeleted(false);
    notification.error("Deleting error", "Cannot delete organization");
  });

  const handleVisitLink = () => {
    submitOrganization({ data: { organizationId: +id } });
  };

  onSuccessOrganization((payload) => {
    localStorage.setItem(TOKEN_STORAGE_FIELD, payload.token);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_FIELD, payload.refreshToken);
    store.dispatch(setToken(payload.token));
    window.location.reload();
  });

  const handleRemoveItem = (id: string) => {
    notification.confirm("Deleting organization?", "Are you sure?", {
      onOk: () => {
        setDeleted(true);
        submit({ params: { id } });
      },
      okText: "Delete",
      icon: "delete",
    });
  };

  if (isDeleted) {
    return null;
  }

  return (
    <TableRow id={id}>
      <TableCell>
        <div className={styles.organization}>
          <div className={styles.organizationField}>{`#${id}` || ""}</div>
        </div>
      </TableCell>
      <TableCell>
        <div className={cn(styles.organization, styles.organizationWrapper)}>
          <img src={image || userPlaceholderImg} alt="" className={styles.imageWrapper} />
          <div className={styles.long}>
            <TextEllipsis
              tooltipTrigger="hover"
              tooltipPosition="top-right"
              className={styles.organizationField}
            >
              {name || ""}
            </TextEllipsis>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.organization}>
          <div className={styles.organizationField}>
            {formatDate(getDateWithTimezone(createdAt), "MMM dd, yyyy") || "-"}
          </div>
        </div>
      </TableCell>
      <TableCell>
        <Button onClick={handleVisitLink} className={styles.visitLink} appearance="text">
          Visit
        </Button>
      </TableCell>
      <TableCell>
        <Menu>
          <MenuButton onClick={() => handleEdit(id)} icon="edit">
            Edit
          </MenuButton>
          <MenuButton
            appearance="delete"
            onClick={() => handleRemoveItem(id)}
            icon="delete"
            disabled
          >
            Delete
          </MenuButton>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default Row;
