import { SchemaOf, string, object } from "yup";
import { SelectOption, isEmpty } from "@epcnetwork/core-ui-kit";

import {
  RestApiBodyOptionTypes,
  RestApiBodyOptionTypesKeys,
  SecretsAPITypesKeys,
  SecretsRestApiTypes,
  SecretsRestApiTypesKeys,
} from "models";
import { HttpMethods } from "constants/forms.constants";
import {
  APISchemasMapType,
  BasicType,
  BearerType,
  BodyType,
  BrontoType,
  EGoiType,
  GetResponseType,
  HeaderType,
  InfusionSoftType,
  InitialValues,
  IterableType,
  OntraportType,
  QueryType,
  RestApiCheckpointType,
  RestApiSchemasMapType,
  SalesforceType,
} from "./secrets-form.types";

const urlRegExp =
  /(((http|ftp|https):\/{2})+(([0-9a-z_-]+\.)+([a-z]{2,6})(:[0-9]+)?((\/([~0-9a-zA-Z\#\+\%@\.\/_-]+))?(\?[0-9a-zA-Z\+\%@\/&\[\];=_-]+)?)?))/g;

export const initialValues: InitialValues = {
  secretName: "",
  apiType: null,
  apiTypeContent: undefined,
};

export const brontoSchema: SchemaOf<BrontoType> = object({
  apiKey: string()
    .default("")
    .min(5, "This field should have minimum 5 characters")
    .required("This field is required"),
});

export const eGoiSchema: SchemaOf<EGoiType> = object({
  apiKey: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
});

export const getResponseSchema: SchemaOf<GetResponseType> = object({
  apiKey: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
});

export const infusionSoftSchema: SchemaOf<InfusionSoftType> = object().shape({
  accessToken: string()
    .default("")
    .min(5, "This field should have minimum 5 characters")
    .required("This field is required"),
  refreshToken: string()
    .default("")
    .min(5, "This field should have minimum 5 characters")
    .required("This field is required"),
  clientId: string()
    .default("")
    .min(5, "This field should have minimum 5 characters")
    .required("This field is required"),
  authBase: string()
    .default("")
    .min(5, "This field should have minimum 5 characters")
    .required("This field is required"),
});

export const iterableSchema: SchemaOf<IterableType> = object({
  apiKey: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
});

export const ontraportSchema: SchemaOf<OntraportType> = object({
  apiKey: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
  appId: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
});

export const restApiSchema: SchemaOf<RestApiCheckpointType> = object().shape({
  authorizationPlacement: string()
    .typeError("This field is required")
    .required("This field is required"),
  restApiContent: object().when("authorizationPlacement", (apiType: SecretsRestApiTypesKeys) => {
    if (!apiType) return object();
    return restApiSchemasMap[apiType];
  }),
});

export const restApiOptions = Object.entries(SecretsRestApiTypes).map<
  SelectOption<keyof typeof SecretsRestApiTypes>
>(([key, value]) => ({
  value: key as SecretsRestApiTypesKeys,
  label: value,
}));

export const bearerSchema: SchemaOf<BearerType> = object({
  bearerToken: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
});
export const basicSchema: SchemaOf<BasicType> = object().shape(
  {
    userName: string().when("password", {
      is: isEmpty,
      then: string().required("At least one of the fields is required"),
    }),
    password: string().when("userName", {
      is: isEmpty,
      then: string().required("At least one of the fields is required"),
    }),
  },
  [["userName", "password"]],
);

export const headerSchema: SchemaOf<HeaderType> = object({
  headerKey: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
  headerValue: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
});

export const querySchema: SchemaOf<QueryType> = object({
  queryKey: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
  queryValue: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
});

export const bodySchema: SchemaOf<BodyType> = object().shape({
  bodyKey: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
  bodyValue: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
  jsonBody: string().required("jsonBody is required").nullable(),
}) as SchemaOf<BodyType>;

export const restApiSchemasMap: RestApiSchemasMapType = {
  bearer: bearerSchema,
  basic: basicSchema,
  header: headerSchema,
  query: querySchema,
  body: bodySchema,
};

export const contentTypeOptions = Object.entries(RestApiBodyOptionTypes).map<
  SelectOption<RestApiBodyOptionTypesKeys>
>(([key, value]) => {
  return {
    value: key as RestApiBodyOptionTypesKeys,
    label: value,
  };
});

export const httpMethodOptions = [
  {
    value: HttpMethods.get,
    label: HttpMethods.get,
  },
  {
    value: HttpMethods.post,
    label: HttpMethods.post,
  },
  {
    value: HttpMethods.put,
    label: HttpMethods.put,
  },
];

export const salesforceSchema: SchemaOf<SalesforceType> = object().shape({
  clientId: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
  clientSecret: string()
    .min(5, "This field should have minimum 5 characters")
    .default("")
    .required("This field is required"),
  origin: string()
    .matches(urlRegExp, "Url is not valid")
    .default("")
    .required("This field is required"),
  soapOrigin: string()
    .matches(urlRegExp, "Url is not valid")
    .default("")
    .required("This field is required"),
  authOrigin: string()
    .matches(urlRegExp, "Url is not valid")
    .default("")
    .required("This field is required"),
});

export const APISchemasMap: APISchemasMapType = {
  restApi: restApiSchema,
  bronto: brontoSchema,
  salesforce: salesforceSchema,
  getResponse: getResponseSchema,
  infusionsoft: infusionSoftSchema,
  iterable: iterableSchema,
  egoi: eGoiSchema,
  ontraport: ontraportSchema,
};

export const validationSchema: SchemaOf<Record<keyof InitialValues, unknown>> = object().shape({
  secretName: string()
    .min(3, "Name must have minimum 3 characters")
    .required("This field is required"),
  apiType: string().required("This field is required").nullable(),
  apiTypeContent: object().when("apiType", (apiType: SecretsAPITypesKeys) => {
    if (!apiType) return object();
    return APISchemasMap[apiType];
  }),
});
