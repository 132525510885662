import React, { FC } from "react";
import { Search, Button, Table, Modal } from "@epcnetwork/core-ui-kit";

import TableRow from "./table-row";
import ListFilters from "./list-filters/list-filters";
import { entityName, TABLE_COLUMNS } from "./media-buy-list.constants";
import useMediaBuyListHook from "./media-buy-list.hook";
import MediaBuyForm from "../form/media-buy-form";
import InlineForm from "./inline-form/inline-form";
import { CampaignStatusTypes } from "models";

import styles from "./media-buy-list.module.css";

interface Props {
  refreshCampaignPage: VoidFunction;
  campaignStatus: CampaignStatusTypes | undefined;
}

const ADD_MEDIA = "+ Add Media Buy" as const;

const MediaBuyListPage: FC<Props> = ({ refreshCampaignPage, campaignStatus }: Props) => {
  const {
    campaignId,
    editId,
    searchValue,
    isOpenEditModal,
    loading,
    isDuplication,
    error,
    pagination,
    list,
    handleAddMedia,
    handleCloseEditModal,
    refresh,
    setSearch,
    handleSetId,
    handleDuplicateRowClick,
    handleInlineFormRowClick,
    handleCloseInlineModal,
    isOpenInlineModal,
    isInlineVendor,
    isMobileDevice,
  } = useMediaBuyListHook({ campaignStatus });

  return (
    <>
      <div className={styles.sectionName}>Media Buy</div>
      <div className={styles.headerWrapper}>
        <div className={styles.searchHeaderWrapper}>
          <div className={styles.searchFormContainer}>
            <Search
              searchValue={searchValue}
              setSearch={setSearch}
              width="small"
              className={styles.search}
            />
            <ListFilters />
          </div>
        </div>
        <Button appearance="primary" onClick={handleAddMedia} className={styles.addFullScreen}>
          {ADD_MEDIA}
        </Button>

        <Button appearance="primary" onClick={handleAddMedia} className={styles.addSmallScreen}>
          + Add
        </Button>
      </div>

      <Table
        className={styles.table}
        entityName={entityName}
        columns={TABLE_COLUMNS(isMobileDevice)}
        list={list}
        loading={loading}
        error={error}
        refresh={refresh}
        pagination={pagination}
        row={(item) => (
          <TableRow
            key={item.name}
            item={item}
            handleSetId={handleSetId}
            refresh={refresh}
            refreshCampaignPage={refreshCampaignPage}
            handleDuplicateRowClick={handleDuplicateRowClick}
            handleInlineFormRowClick={handleInlineFormRowClick}
          />
        )}
      />
      <Modal
        isOpen={isOpenEditModal}
        setClose={handleCloseEditModal}
        contentClassName={styles.editModal}
      >
        <MediaBuyForm
          handleCloseModal={handleCloseEditModal}
          id={editId}
          refresh={refresh}
          isDuplication={isDuplication}
          campaignId={campaignId}
          refreshCampaignPage={refreshCampaignPage}
        />
      </Modal>
      <Modal
        isOpen={isOpenInlineModal}
        setClose={handleCloseInlineModal}
        contentClassName={styles.modal}
      >
        <InlineForm
          currentId={editId}
          handleCloseInlineModal={handleCloseInlineModal}
          refresh={refresh}
          isInlineVendor={isInlineVendor}
        />
      </Modal>
    </>
  );
};

export default MediaBuyListPage;
