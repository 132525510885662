import React, { FC, useState } from "react";
import {
  Container,
  getPaginatedList,
  Table,
  useFetch,
  Tabs,
  useFilters,
  useDidMount,
} from "@epcnetwork/core-ui-kit";

import { TABLE_COLUMNS, TABS, tabStatesMap, initialFilters } from "./roles-list.constants";
import { TabsType } from "./roles-list.types";
import TableRow from "./table-row";
import { getRoles } from "api";

import styles from "./roles-list.module.css";

const RolesListPage: FC = () => {
  const { queryString, setValue, updateQueryParams } = useFilters({
    initialState: initialFilters.initialState,
  });
  const [activeTab, setActiveTab] = useState(0);

  const { payload, loading, refresh, error } = useFetch(getRoles.setQueryParams(queryString), {
    dependencies: [queryString],
  });

  useDidMount(() => updateQueryParams({ section: tabStatesMap[activeTab] }));

  const handleChangeTab = (tabIndex: number) => {
    setActiveTab(tabIndex);
    setValue("section")(tabStatesMap[tabIndex]);
  };

  const list = getPaginatedList(payload?.data);

  const tabs: TabsType = [
    {
      name: TABS.admin,
      list,
    },
    {
      name: TABS.owner,
      list,
    },
    {
      name: TABS.groupManager,
      list,
    },
    {
      name: TABS.user,
      list,
    },
    {
      name: TABS.trial,
      list,
    },
  ];

  const tabsComponents = tabs.map((tab) => ({
    tab: { name: `${tab.name}` },
    tabComponent: (
      <Table
        className={styles.table}
        contentClassName={styles.contentTable}
        columns={TABLE_COLUMNS}
        list={tab.list}
        loading={loading}
        error={error?.message}
        refresh={refresh}
        row={(item) => <TableRow key={item.id} item={item} />}
        isTabTable
      />
    ),
  }));

  return (
    <Container>
      <div className={styles.tabsWrapper}>
        <Tabs tabs={tabsComponents} activeTab={activeTab} onTabClick={handleChangeTab} />
      </div>
    </Container>
  );
};

export default RolesListPage;
