import React, { FC } from "react";
import { useFormikContext } from "formik";
import { useWillUnmount, useDidMount, Select, useAutocomplete } from "@epcnetwork/core-ui-kit";

import { getVendorProviderList } from "api";
import {
  SalesforceType,
  VendorFormInitialValuesType,
} from "pages/external-vendors/form/vendors-form.types";
import JsonEditor from "./components/json-editor";
import { resetFormState } from "../vendors-form.utils";

import styles from "./api-type.module.css";

const Salesforce: FC = () => {
  const { values, setFormikState, setFieldValue, touched, errors } = useFormikContext<
    VendorFormInitialValuesType & Partial<SalesforceType>
  >();

  const providersAutocomplete = useAutocomplete(
    getVendorProviderList.setParams({ secretId: values.secretId }),
    "id",
    "name",
    {
      dependencies: [values.secretId],
    },
  );

  useDidMount(() => {
    setFieldValue("list", null);
  });

  useWillUnmount(() => setFormikState(resetFormState));

  const error = touched.list && errors.list ? (String(errors.list) as string) : "";

  return (
    <>
      <Select
        label="List"
        placeholder="Choose list"
        error={error}
        asyncOptions={providersAutocomplete}
        onChange={(option) => {
          const { value = "", label = "" } = option || {};
          if (!option) return setFieldValue("list", null);
          setFieldValue("list", { id: value, name: label });
        }}
        selectedOptionsKeys={values?.list?.id ? values?.list?.id : ""}
        required
      />
      <JsonEditor
        name="soapBody"
        wrapClassName={styles.jsonEditorWrap}
        codeMirrorClassName={styles.jsonEditor}
        label="ET JSON Body:"
      />
    </>
  );
};

export default Salesforce;
