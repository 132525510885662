import { useState } from "react";
import { FormikHelpers } from "formik";
import { useSelector } from "react-redux";
import { NonNullableKeys, useFormValues, useSubmit, notification } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { getSource, postSource, putSource } from "api";
import { initialValues } from "./sources-form.constants";
import { FormPropsType, InitialValues } from "./sources-form.types";

interface Output {
  error: string;
  formProps: FormPropsType;
  onSubmit: (values: InitialValues, helpers: FormikHelpers<InitialValues>) => Promise<void>;
}

const useSourcesFormHook = (
  id: string,
  onCloseModal: VoidFunction,
  refresh: VoidFunction,
  refreshMediaBuy?: VoidFunction,
): Output => {
  const { organization } = useSelector((state: RootState) => state.auth);
  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    postSource,
    putSource.setParams({ sourceId: id }),
    !!id,
  );
  const { formProps, mapInitialValues } = useFormValues(
    initialValues,
    getSource.setParams({ sourceId: id }),
    !!id,
  );

  const [error, setError] = useState("");

  const onSubmit = onSubmitMapping((values: NonNullableKeys<InitialValues>) => {
    return {
      ...values,
      organizationId: organization?.id as string,
    };
  });

  mapInitialValues((payload) => {
    return {
      ...payload,
      name: payload.name,
    };
  });

  onSubmitSuccess(() => {
    const title = id ? "Source updated" : "Source created";
    const message = id ? "Successfully updated source" : "Successfully created source";
    notification.success(title, message);
    onCloseModal();
    refresh();
    if (refreshMediaBuy) refreshMediaBuy();
  });

  onSubmitError((error) => {
    setError(error.message);
  });

  return {
    error,
    formProps,
    onSubmit,
  };
};

export default useSourcesFormHook;
