import React, { FC } from "react";
import { Button, FormField } from "@epcnetwork/core-ui-kit";

import styles from "../api-type.module.css";
import deleteImg from "assets/images/delete-grey.svg";

type Props = {
  index: number;
  prefix: "queryParams" | "bodyParams";
  canDelete: boolean;
  handleDelete: (id: number) => void;
};

const UrlParameterInput: FC<Props> = ({ index, prefix, canDelete, handleDelete }: Props) => {
  return (
    <div className={styles.urlParameterWrapper}>
      <div className={styles.urlParameterBody}>
        <FormField type="text" name={`${prefix}[${index}].key`} placeholder="f_name" required />
        <FormField
          type="text"
          name={`${prefix}[${index}].value`}
          placeholder="{{first_Name}}"
          required
        />
        {canDelete && (
          <div className={styles.urlParameterImgWrapper}>
            <Button type="button" appearance="text" onClick={() => handleDelete(index)}>
              <img src={deleteImg} alt="" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UrlParameterInput;
