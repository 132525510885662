import React, { FC } from "react";
import { useDidUpdate, Table, useFetch, usePagination } from "@epcnetwork/core-ui-kit";

import { getMembers } from "api";
import { usePayload } from "hooks";
import { NoContent } from "components";
import TableRow from "../table-row/table-row";
import { entityName, TABLE_COLUMNS } from "./members.constants";

import styles from "./members.module.css";

type MembersProps = {
  handleChangeTab: (tabIndex: number) => void;
  groupId?: string;
};

const Members: FC<MembersProps> = ({ groupId, handleChangeTab }: MembersProps) => {
  const apiResponse = useFetch(getMembers.setQueryParams(`?groupId=${groupId}`));

  const pagination = usePagination(apiResponse.payload);
  const { list, refresh, error, loading } = usePayload(apiResponse);

  useDidUpdate(() => {
    if (!groupId) return null;

    refresh();
  }, [groupId]);

  const handleEdit = () => {
    return false;
  };
  const isNoContent = !loading && !list.length;

  return (
    <div className={styles.container}>
      {isNoContent ? (
        <NoContent
          showBtn={true}
          textBtn="Go to invite user tab"
          onClick={() => handleChangeTab(1)}
        />
      ) : (
        <Table
          columns={TABLE_COLUMNS}
          list={list}
          entityName={entityName}
          error={error?.message}
          loading={loading}
          refresh={refresh}
          pagination={pagination}
          isTabTable
          row={(item) => <TableRow key={item.id} item={item} handleEdit={handleEdit} />}
        />
      )}
    </div>
  );
};

export default Members;
