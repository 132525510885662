import React, { FC } from "react";
import { useFormikContext } from "formik";
import { FormField, useDidMount } from "@epcnetwork/core-ui-kit";

import { BrontoType, InitialValues } from "../secrets-form.types";

const Bronto: FC = () => {
  const { values, setFieldValue } = useFormikContext<InitialValues<BrontoType>>();

  useDidMount(() => {
    const brontoValues = values.apiTypeContent;
    !brontoValues?.apiKey && setFieldValue("apiTypeContent.apiKey", "");
  });

  return (
    <>
      <FormField
        type="text"
        name="apiTypeContent.apiKey"
        label="AUTHENTICATION Token"
        placeholder=""
        required
      />
    </>
  );
};

export default Bronto;
