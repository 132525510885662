import React, { FC, ChangeEvent } from "react";
import cn from "classnames";
import { Button } from "@epcnetwork/core-ui-kit";

import styles from "../sidebar/sidebar.module.css";
import CloseCircleImg from "assets/images/close-in-circle-red.svg";
import CheckMarkImg from "assets/images/circle-check-mark-green.svg";

type EditableFieldProps = {
  selected: boolean;
  editId: string;
  value: string;
  handleChangeValue: (e: ChangeEvent<HTMLInputElement>) => void;
  handleClose: VoidFunction;
  handleSubmit: VoidFunction;
};

const EditableField: FC<EditableFieldProps> = ({
  selected,
  editId,
  handleChangeValue,
  handleSubmit,
  handleClose,
  value,
}: EditableFieldProps) => {
  return (
    <div className={styles.wrapper}>
      <input
        className={cn(styles.roleBtn, {
          [styles.activeBtn]: selected,
          [styles.editInput]: editId,
        })}
        value={value}
        onChange={handleChangeValue}
      />
      <div className={styles.editIcons}>
        <Button appearance="text" imageBtn onClick={handleClose}>
          <img src={CloseCircleImg} alt="" width={18} height={18} className={styles.icon} />
        </Button>
        <Button appearance="text" imageBtn onClick={handleSubmit}>
          <img src={CheckMarkImg} alt="" width={16} height={16} className={styles.icon} />
        </Button>
      </div>
    </div>
  );
};

export default EditableField;
