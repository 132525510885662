import React, { FC } from "react";
import { useFetch, useQuery } from "@epcnetwork/core-ui-kit";

import { CrumbSeparator, Breadcrumbs as CoreBreadcrumbs, Crumb } from "components";
import { getClient } from "api";
import { CLIENTS_LIST_PAGE, CAMPAIGNS_LIST_PAGE } from "constants/routes.constants";
import { QueryBreadcrumbsType } from "../campaigns-list.types";

const Breadcrumbs: FC = () => {
  const { query } = useQuery<QueryBreadcrumbsType>();

  const onlyOneClientQuery = query.clientId ? query.clientId.length === 1 : false;
  const id = onlyOneClientQuery ? query.clientId[0] : "";

  const { payload, loading } = useFetch(getClient.setParams({ clientId: id }));

  return (
    <CoreBreadcrumbs>
      {onlyOneClientQuery && !loading ? (
        <>
          <Crumb appearance="link" name={CLIENTS_LIST_PAGE.name} path={CLIENTS_LIST_PAGE.path} />
          <CrumbSeparator />
          <Crumb appearance="text" name={payload?.name || `Client ${id}`} />
        </>
      ) : (
        <Crumb appearance="text" name={CAMPAIGNS_LIST_PAGE.name} />
      )}
    </CoreBreadcrumbs>
  );
};

export default Breadcrumbs;
