import React, { FC } from "react";
import { useFormikContext } from "formik";
import { useDidUpdate } from "@epcnetwork/core-ui-kit";

import { SecretsAPITypesKeys } from "models";
import RestApi from "./rest-api";
import Bronto from "./bronto";
import Salesforce from "./salesforce";
import GetResponse from "./getresponse";
import InfusionSoft from "./infusion-soft";
import Iterable from "./iterable";
import EGoi from "./e-goi";
import Ontraport from "./ontraport";
import { DispatchType, InitialValues } from "../secrets-form.types";

type Props = {
  apiType: SecretsAPITypesKeys;
};

const SecretsAPIDispatcher: FC<Props> = ({ apiType, ...rest }: Props) => {
  const { setFieldValue } = useFormikContext<InitialValues>();

  const APIMapDispatch: DispatchType = {
    restApi: <RestApi {...rest} />,
    bronto: <Bronto {...rest} />,
    salesforce: <Salesforce {...rest} />,
    getResponse: <GetResponse {...rest} />,
    infusionsoft: <InfusionSoft {...rest} />,
    iterable: <Iterable {...rest} />,
    egoi: <EGoi {...rest} />,
    ontraport: <Ontraport {...rest} />,
  };

  useDidUpdate(() => {
    setFieldValue("apiTypeContent", undefined);
  }, [apiType]);

  return APIMapDispatch[apiType];
};

export default SecretsAPIDispatcher;
