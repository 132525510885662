import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import auth from "./reducers/auth";
import ui from "./reducers/ui";

const reducer = combineReducers({
  auth,
  ui,
});

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer,
  middleware: [sagaMiddleware],
});

export * from "./reducers/auth";
export * from "./reducers/ui";

export type RootState = ReturnType<typeof reducer>;
export default store;
