import React, { FC } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  TextEllipsis,
  formatDate,
} from "@epcnetwork/core-ui-kit";

import { ImageLink } from "components";
import { SourceModel } from "models";
import { linkTo, getDateWithTimezone } from "utils";
import { SOURCES_DETAILS_PAGE } from "constants/routes.constants";

import styles from "./table-row.module.css";

type Props = {
  item: SourceModel;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
};

const Row: FC<Props> = ({ item, handleEdit, handleDelete }: Props) => {
  const { name, createdAt, id, allMediaBuysCount, activeMediaBuysCount, inactiveMediaBuysCount } =
    item;

  const handleDeleteClick = () => handleDelete(id);
  const handleEditClick = () => handleEdit(id);

  const linkToSourceDetails = linkTo(SOURCES_DETAILS_PAGE.path, id);

  return (
    <TableRow id={id}>
      <TableCell className={styles.nameCell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {name}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <ImageLink to={linkToSourceDetails}>{allMediaBuysCount}</ImageLink>
      </TableCell>
      <TableCell>
        <div>{activeMediaBuysCount}</div>
      </TableCell>
      <TableCell>
        <div>{inactiveMediaBuysCount}</div>
      </TableCell>
      <TableCell>{formatDate(getDateWithTimezone(createdAt), "MMM d, yyyy")}</TableCell>
      <TableCell className={styles.hideOnMobile}>
        <Menu>
          <MenuButton onClick={handleEditClick} icon="edit">
            Edit
          </MenuButton>
          <MenuButton onClick={handleDeleteClick} appearance="delete" icon="delete">
            Delete
          </MenuButton>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default Row;
