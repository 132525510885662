import React, { FC } from "react";
import { formatDate } from "@epcnetwork/core-ui-kit";

import { getDateWithTimezone } from "utils";

import styles from "./info-line.module.css";
import calendarImg from "assets/images/calendar-grey.svg";
import clockImg from "assets/images/clock-grey.svg";

type Props = {
  title: string;
  body: string;
};

const InfoLine: FC<Props> = ({ title, body }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.body}>
        <img src={calendarImg} alt="" />
        <span>{formatDate(getDateWithTimezone(body), "MMM d, yyyy")}</span>
        <img src={clockImg} alt="" />
        <span>{formatDate(getDateWithTimezone(body), "hh:mm")}</span>
      </div>
    </div>
  );
};

export default InfoLine;
