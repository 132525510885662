import { FiltersType, CollapseState } from "./users-list.types";

export const TABLE_COLUMNS = [
  { label: "Email", queryKey: "email" },
  { label: "Role", queryKey: "type" },
  { label: "Group", queryKey: "group" },
  { label: "Created at" },
  { label: "" },
];

export const initialFilters: FiltersType = {
  email: "",
  createdDate: null,
};

export const initialCollapseState: CollapseState = {
  email: {
    isOpen: true,
  },
  createdDate: {
    isOpen: true,
  },
};

export const initialSearchFilters = {
  searchKey: "search",
};

export const entityName = "users-table";

export const usersRoles = [
  { value: 50, label: "Admin" },
  { value: 100, label: "Owner" },
  { value: 10, label: "Group Manager" },
  { value: 5, label: "user" },
  { value: 2, label: "Trial" },
];
