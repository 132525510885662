import React, { FC } from "react";

import styles from "../breadcrumbs.module.css";

import arrowRightImg from "assets/images/arrow-right.svg";

type CrumbSeparatorProps = {
  imgSrc?: string;
};

export const CrumbSeparator: FC<CrumbSeparatorProps> = ({ imgSrc }: CrumbSeparatorProps) => {
  const img = imgSrc ? imgSrc : arrowRightImg;

  return <img className={styles.separator} src={img} alt="" />;
};

export default CrumbSeparator;
