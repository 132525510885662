import React, { FC } from "react";
import { Container, Search, Table, Button, Modal } from "@epcnetwork/core-ui-kit";

import TableRow from "./table-row/table-row";
import ListFilters from "./list-filters/list-filters";
import VendorViewModalContent from "./components/view-external-vendor-content";
import VendorsForm from "../form/vendors-form";
import { entityName, TABLE_COLUMNS } from "./external-vendors-list.constants";
import useExternalVendorsListHook from "./external-vendors-list.hook";
import Breadcrumbs from "./breadcrumbs/breadcrumbs";

import styles from "./external-vendors-list.module.css";

const ADD_BUTTON = "+ Add External Vendor API" as const;

const ExternalVendorsListPage: FC = () => {
  const {
    handleCloseViewModal,
    handleCloseModal,
    handleViewItem,
    handleRemoveItem,
    handleEdit,
    handleAdd,
    editId,
    isMainModalOpen,
    viewId,
    isViewModalOpen,
    loading,
    refresh,
    setSearch,
    searchValue,
    pagination,
    list,
    isMobileDevice,
    error,
  } = useExternalVendorsListHook();

  return (
    <>
      <Breadcrumbs />
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.tableHeader}>
            <div className={styles.searchWrapper}>
              <Search searchValue={searchValue} setSearch={setSearch} className={styles.search} />
              <ListFilters />
            </div>
            <Button onClick={handleAdd} className={styles.addBtn}>
              {ADD_BUTTON}
            </Button>
            <Button appearance="primary" onClick={handleAdd} className={styles.addSmallScreen}>
              + Add
            </Button>
          </div>
          <Table
            className={styles.table}
            entityName={entityName}
            columns={TABLE_COLUMNS(isMobileDevice)}
            list={list}
            error={error}
            loading={loading}
            refresh={refresh}
            pagination={pagination}
            row={(item) => (
              <TableRow
                key={item.id}
                item={item}
                handleEdit={handleEdit}
                handleRemoveItem={handleRemoveItem}
                handleViewItem={handleViewItem}
              />
            )}
          />
          <Modal
            contentClassName={styles.mainModal}
            isOpen={isMainModalOpen}
            setClose={handleCloseModal}
          >
            <VendorsForm onCloseModal={handleCloseModal} id={editId} refresh={refresh} />
          </Modal>
          <Modal
            contentClassName={styles.viewModal}
            isOpen={isViewModalOpen}
            setClose={handleCloseViewModal}
          >
            <VendorViewModalContent handleCloseViewModal={handleCloseViewModal} id={viewId} />
          </Modal>
        </div>
      </Container>
    </>
  );
};

export default ExternalVendorsListPage;
