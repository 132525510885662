import { SecretsAPITypesKeys } from "models";

export type CollapseState = {
  [key: string]: {
    isOpen: boolean;
  };
};

export enum ExternalVendorsMethodUppercaseTypes {
  GET = "GET",
  PUT = "PUT",
  POST = "POST",
}

export type ExternalVendorsMethodTypesValues = keyof typeof ExternalVendorsMethodUppercaseTypes;

export type FiltersType = {
  createdAt: string[] | null;
  updatedAt: string[] | null;
  apiType: SecretsAPITypesKeys | null;
  httpMethod: ExternalVendorsMethodTypesValues | null;
};

export type CollapseStateKeys = "type" | "method" | "created" | "updated";
