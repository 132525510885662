import React, { FC } from "react";
import { useFormikContext } from "formik";
import { useDidMount, FormField } from "@epcnetwork/core-ui-kit";

import {
  IterableType,
  VendorFormInitialValuesType,
} from "pages/external-vendors/form/vendors-form.types";
import { resetFormState } from "../vendors-form.utils";

const Iterable: FC = () => {
  const { setFieldValue, setFormikState } = useFormikContext<
    VendorFormInitialValuesType & Partial<IterableType>
  >();

  useDidMount(() => {
    setFieldValue("list", { id: "", name: "" });
  });

  useDidMount(() => setFormikState(resetFormState));

  return <FormField name="list.id" type="text" label="List id" placeholder="List id" required />;
};

export default Iterable;
