import React, { FC, memo, useState } from "react";
import cn from "classnames";
import { Sidebar as SidebarComponent, useDidUpdate, useWindowSize } from "@epcnetwork/core-ui-kit";

import Logo from "./components/logo";
import Menu from "./components/menu";
import {
  sidebarWidth,
  collapsedDesktop,
  collapsedMobile,
  placement,
  entityName,
} from "./sidebar.constants";

import styles from "./sidebar.module.css";
import togglerArrow from "assets/images/arrow-right-grey.svg";

type SidebarProps = {
  collapsed: boolean;
};

const Sidebar: FC<SidebarProps> = ({ collapsed }: SidebarProps) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const { width } = useWindowSize();

  useDidUpdate(() => {
    handleCollapsed();
  }, [collapsed]);

  const isMobile = width < 992;

  const getCollapsedWidth = () => (isMobile ? collapsedMobile : collapsedDesktop);

  const collapsedWidth = getCollapsedWidth();
  const handleCollapsed = () => setIsCollapsed((prev) => !prev);

  const togglerStyles = cn(styles.toggler, styles[placement], { [styles.collapsed]: isCollapsed });
  const sidebarStyles = cn(styles.sidebar, { [styles.collapsed]: isCollapsed });

  return (
    <SidebarComponent
      wrapperClassName={styles.sidebarWrapper}
      contentClassName={sidebarStyles}
      setIsCollapsed={setIsCollapsed}
      isCollapsed={isCollapsed}
      placement={placement}
      sidebarWidth={sidebarWidth}
      collapsedWidth={collapsedWidth}
      entityName={entityName}
      isContentOffset={true}
    >
      <Logo isCollapsed={isCollapsed} />
      {!isMobile && (
        <button className={togglerStyles} onClick={handleCollapsed}>
          <img className={styles.togglerImage} src={togglerArrow} alt="" />
        </button>
      )}
      <Menu />
    </SidebarComponent>
  );
};

export default memo(Sidebar);
