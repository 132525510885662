import React, { FC } from "react";
import { Button } from "@epcnetwork/core-ui-kit";

import noContentImg from "assets/images/error-no-content.svg";
import styles from "./no-content.module.css";

type NoContentProps = {
  showBtn?: boolean;
  textBtn?: string;
  onClick?: VoidFunction;
};

const NoContent: FC<NoContentProps> = ({
  showBtn = false,
  textBtn = "",
  onClick,
}: NoContentProps) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <div className={styles.container}>
      <img src={noContentImg} alt="" />
      <div className={styles.title}>Nothing to show yet</div>
      {showBtn && (
        <Button onClick={handleClick} className={styles.btn}>
          {textBtn}
        </Button>
      )}
    </div>
  );
};

export default NoContent;
