import React, { FC } from "react";
import { Loader, Button, FetchingError, useFetch, formatDate } from "@epcnetwork/core-ui-kit";

import { getDateWithTimezone } from "utils";

import { SecretsAPITypes } from "models";
import { getExternalVendor } from "api";
import { usePayload, useShowContent } from "hooks";
import ContentRow from "./content-row";

import styles from "./view-external-vendor-content.module.css";
import closeImg from "assets/images/close-secrets.svg";
import clockImg from "assets/images/clock-grey.svg";
import calendarImg from "assets/images/calendar-grey.svg";

type Props = {
  handleCloseViewModal: () => void;
  id: string;
};

const VendorViewModalContent: FC<Props> = ({ handleCloseViewModal, id = "" }: Props) => {
  const vendorResponse = useFetch(getExternalVendor.setParams({ externalVendorId: id }));
  const { showContent, showError, showLoader } = useShowContent(vendorResponse);
  const { payload, errorMessage, refresh } = usePayload(vendorResponse);
  const { createdAt, updatedAt } = payload || {};

  return (
    <div>
      <div className={styles.deleteHeader}>
        <img src={closeImg} alt="" onClick={handleCloseViewModal} />
      </div>
      {showError && (
        <FetchingError
          title="Cannot download vendor"
          subtitle={errorMessage}
          refreshClick={refresh}
        />
      )}
      {showLoader && <Loader />}
      {showContent && (
        <div className={styles.textWrapper}>
          <div className={styles.title}>
            <span>View External Vendor API</span>
          </div>
          <ContentRow title="name" body={payload.name} className={styles.boldBody} />
          <ContentRow title="secret" body={payload.secret?.name} />
          <ContentRow title="api type" body={SecretsAPITypes[payload.apiType]} />
          <ContentRow title="created at">
            <img className={styles.calendarImg} src={calendarImg} alt="start date" />
            {formatDate(getDateWithTimezone(createdAt), "MMM dd, yyyy")}
            <img src={clockImg} alt="" />
            {formatDate(getDateWithTimezone(createdAt), "hh:mm")}
          </ContentRow>
          <ContentRow title="updated at">
            <img className={styles.calendarImg} src={calendarImg} alt="end date" />
            {formatDate(getDateWithTimezone(updatedAt), "MMM dd, yyyy")}
            <img src={clockImg} alt="" />
            {formatDate(getDateWithTimezone(updatedAt), "hh:mm")}
          </ContentRow>
        </div>
      )}
      <div className={styles.buttonsWrapper}>
        <Button type="button" appearance="secondary" onClick={handleCloseViewModal}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default VendorViewModalContent;
