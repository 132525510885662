import React, { FC } from "react";
import { Button, Form, FormField, MessageField, TextEllipsis } from "@epcnetwork/core-ui-kit";

import useClientsFormHook from "./clients-form.hook";
import { InfoLine } from "components";
import { clientsFormValidationSchema } from "./clients-form.constants";

import closeImg from "assets/images/close-secrets.svg";
import styles from "./clients-form.module.css";

type Props = {
  id: string;
  refresh: VoidFunction;
  onCloseModal: VoidFunction;
};

const ClientsForm: FC<Props> = ({ onCloseModal, id = "", refresh }: Props) => {
  const {
    payload,
    error,
    onSubmit,
    formTitle,
    formProps,
    buttonText,
    handleChangePhone,
    handleCopyPastePhone,
  } = useClientsFormHook(id, onCloseModal, refresh);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={closeImg} alt="" onClick={onCloseModal} />
      </div>
      <div className={styles.body}>
        <div className={styles.name}>
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
            {formTitle}
          </TextEllipsis>
        </div>
        <MessageField message={error} className={styles.error} />
        <Form {...formProps} onSubmit={onSubmit} validationSchema={[clientsFormValidationSchema]}>
          <FormField
            type="text"
            name="name"
            label="CLIENT NAME"
            placeholder="Fill the name"
            required
          />
          <FormField type="text" name="email" label="EMAIL" placeholder="Fill the email" required />
          <FormField
            type="text"
            name="contactPerson"
            label="CONTACT PERSON"
            placeholder="Fill the First name and Last name"
            required
          />
          <FormField
            type="text"
            name="phone"
            label="TELEPHONE NUMBER"
            placeholder="Fill the number"
            onKeyPress={handleChangePhone}
            onPaste={handleCopyPastePhone}
          />
          <div className={styles.textArea}>
            <FormField
              type="textarea"
              rows={4}
              name="comments"
              label="COMMENTS"
              placeholder="Feel free to add any information related to the Client"
            />
          </div>
          {payload && (
            <div className={styles.infoWrapper}>
              <InfoLine title="created at" body={payload.createdAt} />
              <InfoLine title="updated at" body={payload.updatedAt} />
            </div>
          )}
          <div className={styles.buttonWrapper}>
            <Button
              type="button"
              appearance="secondary"
              onClick={onCloseModal}
              className={styles.cancelButton}
            >
              Cancel
            </Button>
            <Button type="submit">{buttonText}</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default ClientsForm;
