import React, { FC } from "react";
import { TableRow, TableCell } from "@epcnetwork/core-ui-kit";

import { MemberModel } from "models";

import styles from "../users-details.module.css";

type Props = {
  item: MemberModel;
};

const Row: FC<Props> = ({ item }: Props) => {
  return (
    <TableRow id={item.id}>
      <TableCell>{item.group?.name || ""}</TableCell>
      <TableCell className={styles.cell}>{item.roleName || ""}</TableCell>
    </TableRow>
  );
};

export default Row;
