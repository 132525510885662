import { useAbility } from "@casl/react";

import { AbilityContext } from "components/client/authorization/authorization";
import { Ability } from "@casl/ability";

const useAuthorization = (): Ability => {
  return useAbility(AbilityContext);
};

export default useAuthorization;
