import { apiMiddleware } from "../api.middleware";
import { List } from "@epcnetwork/core-ui-kit";
import {
  CampaignModel,
  MediaBuyModel,
  CampaignData,
  CampaignStatsModel,
  CampaignCountModel,
  CampaignStatusData,
} from "models";

export const getCampaigns = apiMiddleware<List<CampaignModel> & CampaignCountModel>()({
  method: "get",
  endpoint: "/campaign",
});

export const getCampaign = apiMiddleware<CampaignModel>()({
  method: "get",
  endpoint: `/campaign/:campaignId`,
});

export const getMediaBuysByCampaignId = apiMiddleware<List<MediaBuyModel>>()({
  method: "get",
  endpoint: "/campaign/:id/media-buys",
});

export const postCampaign = apiMiddleware<CampaignModel, CampaignData>()({
  method: "post",
  endpoint: `/client/:clientId/campaigns`,
});

export const putCampaign = apiMiddleware<CampaignModel, CampaignData>()({
  method: "put",
  endpoint: `/campaign/:campaignId`,
});

export const deleteCampaign = apiMiddleware<MediaBuyModel>()({
  method: "delete",
  endpoint: `/campaign/:campaignId`,
});

export const getCampaignStats = apiMiddleware<CampaignStatsModel[]>()({
  method: "get",
  endpoint: `/campaign/:campaignId/click-stats`,
});

export const putCampaignStatus = apiMiddleware<CampaignModel, CampaignStatusData>()({
  method: "put",
  endpoint: `/campaign/:campaignId/status`,
});
