import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import { Providers } from "components";
import App from "./app";

import "assets/styles/index.css";
import "assets/styles/theme.css";
import "@epcnetwork/core-ui-kit/dist/assets/styles/index.css";

require("codemirror/lib/codemirror.css");
require("codemirror/mode/javascript/javascript");

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <div className="theme">
        <App />
      </div>
    </Providers>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
