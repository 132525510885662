import { Dispatch, SetStateAction } from "react";
import {
  averageValue,
  addDays,
  addMonths,
  getJSDate,
  getDayType,
  isSameDay,
  isSameMonth,
  addZero,
  formatDateName,
  CoreKitDate,
  getMonthName,
  getYear,
  isSameYear,
  getStartOfDay,
  getEndOfDay,
  clamp,
  getStartOfYear,
  getEndOfYear,
  DateArgType,
  DateMeasure,
} from "@epcnetwork/core-ui-kit";

import { VisibleRangeType, SupportedViews, GanttTimePeriodType } from "./gantt-timeline.types";

export const getGanttView = (visibleFrom: number, visibleTo: number): SupportedViews => {
  const { year, month31, day } = DateMeasure;
  const range = visibleTo - visibleFrom;

  const shouldShowYearView = range / year >= 1;
  if (shouldShowYearView) return "years";

  const shouldShowMonthView = range / month31 >= 1;
  if (shouldShowMonthView) return "months";

  const shouldShowWeekdaysView = range / day < 8;
  if (shouldShowWeekdaysView) return "weekdays";

  return "days";
};

export const handleCalcShift = (
  valueMultiplier: number,
  signMultiplier: number,
  view: SupportedViews,
  setFunc: Dispatch<SetStateAction<VisibleRangeType>>,
): void => {
  const sign = Math.sign(signMultiplier);

  switch (view) {
    case "years": {
      const valueToCalc = 12 * valueMultiplier * sign;
      return setFunc((prevState) => ({
        visibleFrom: addMonths(valueToCalc, prevState.visibleFrom),
        visibleTo: addMonths(valueToCalc, prevState.visibleTo),
      }));
    }
    case "months": {
      const valueToCalc = valueMultiplier * sign;
      return setFunc((prevState) => ({
        visibleFrom: addMonths(valueToCalc, prevState.visibleFrom),
        visibleTo: addMonths(valueToCalc, prevState.visibleTo),
      }));
    }
    case "weekdays":
    case "days":
    default: {
      const valueToCalc = valueMultiplier * sign;
      return setFunc((prevState) => ({
        visibleFrom: +addDays(valueToCalc, prevState.visibleFrom),
        visibleTo: +addDays(valueToCalc, prevState.visibleTo),
      }));
    }
  }
};

export const handleCalcZoom = (
  e: WheelEvent,
  prevFrom: number,
  prevTo: number,
  view: SupportedViews,
): [from: number, to: number] => {
  const { ctrlKey, deltaY } = e;

  const signMultiplier = Math.sign(deltaY);
  const valueMultiplier = ctrlKey ? 3 : 1;
  const multiplier = valueMultiplier * signMultiplier;

  let from;
  let to;

  const avg = averageValue(prevFrom, prevTo);

  switch (view) {
    case "years": {
      from = +new CoreKitDate(prevFrom).addMonths(-(12 * multiplier)).getStartOfYear();
      to = +new CoreKitDate(prevTo).addMonths(12 * multiplier).getEndOfYear();

      const avg = averageValue(from, to);
      const diff = to - from;

      if (diff / DateMeasure.month31 <= 12) {
        from = +new CoreKitDate(avg).addMonths(-5).getStartOfMonth();
        to = +new CoreKitDate(avg).addMonths(5).getEndOfMonth();
      }

      break;
    }
    case "months": {
      from = +new CoreKitDate(prevFrom).addMonths(-multiplier).getStartOfMonth();
      to = +new CoreKitDate(prevTo).addMonths(multiplier).getEndOfMonth();

      const avg = averageValue(from, to);
      const diff = to - from;

      if (diff / DateMeasure.day <= 30) {
        from = +new CoreKitDate(avg).addDays(-15).getStartOfDay();
        to = +new CoreKitDate(avg).addDays(15).getEndOfDay();
      }

      break;
    }
    case "weekdays":
    case "days":
    default: {
      from = +new CoreKitDate(prevFrom).addDays(-multiplier).getStartOfDay();
      to = +new CoreKitDate(prevTo).addDays(multiplier).getEndOfDay();
      break;
    }
  }

  // min/max zooming
  let fromToSet = clamp(from, avg - DateMeasure.year * 5, Math.floor(avg));
  let toToSet = clamp(to, Math.floor(avg), avg + DateMeasure.year * 5);

  if (fromToSet === toToSet) {
    fromToSet = +getStartOfDay(fromToSet);
    toToSet = +getEndOfDay(fromToSet);
  }
  if ((toToSet - fromToSet) / DateMeasure.year > 5) {
    fromToSet = +getStartOfYear(fromToSet);
    toToSet = +getEndOfYear(toToSet);
  }

  return [fromToSet, toToSet];
};

export const getGanttDatePeriods = (
  from: DateArgType,
  to: DateArgType,
  view: SupportedViews,
): GanttTimePeriodType[] => {
  const periods: GanttTimePeriodType[] = [];

  let dayOffset = getJSDate(from);

  switch (view) {
    case "years": {
      while (+dayOffset < +getJSDate(to)) {
        periods.push({
          key: +dayOffset,
          name: getYear(dayOffset).toString(),
          isPresentDate: isSameYear(getJSDate(), dayOffset),
        });
        dayOffset = getJSDate(addMonths(12, dayOffset));
      }
      break;
    }
    case "months": {
      while (+dayOffset < +getJSDate(to)) {
        periods.push({
          key: +dayOffset,
          name: getMonthName(dayOffset),
          isPresentDate: isSameMonth(getJSDate(), dayOffset),
        });
        dayOffset = getJSDate(addMonths(1, dayOffset));
      }
      break;
    }
    case "weekdays": {
      while (+dayOffset < +getJSDate(to)) {
        const { date, dayOfWeek, dayOfMonth, monthIndex } = getDayType(dayOffset);
        periods.push({
          key: +date,
          name: dayOfWeek.toString(),
          decoration: dayOfMonth + "." + addZero(monthIndex + 1),
          isPresentDate: isSameDay(getJSDate(), date),
          isWeekendDate: dayOfWeek === "Sunday" || dayOfWeek === "Saturday",
        });
        dayOffset = getJSDate(addDays(1, dayOffset));
      }
      break;
    }
    case "days":
    default: {
      while (+dayOffset < +getJSDate(to)) {
        const { date, dayOfMonth, dayOfWeek } = getDayType(dayOffset);
        periods.push({
          key: +date,
          name: dayOfMonth.toString(),
          decoration: formatDateName(dayOfWeek, "1-char"),
          isPresentDate: isSameDay(getJSDate(), date),
          isWeekendDate: dayOfWeek === "Sunday" || dayOfWeek === "Saturday",
        });
        dayOffset = getJSDate(addDays(1, dayOffset));
      }
      break;
    }
  }

  return periods;
};
