import { SecretsAPITypes, SecretsAPITypesKeys } from "models";
import { TableColumnsType } from "types";
import {
  CollapseState,
  ExternalVendorsMethodTypesValues,
  ExternalVendorsMethodUppercaseTypes,
  FiltersType,
} from "./external-vendors-list.types";

export const TABLE_COLUMNS = (isMobile: boolean): TableColumnsType[] => [
  { label: "Vendor name", queryKey: "name", required: true },
  { label: "Secret name", queryKey: "secret.name" },
  { label: "Type", queryKey: "apiType", selected: !isMobile },
  { label: "Method", queryKey: "httpMethod", selected: false },
  { label: "Url", queryKey: "url", selected: false },
  { label: "Created at", queryKey: "createdAt", selected: !isMobile },
  { label: "", queryKey: "" },
];

export const getQueryKeys = (isMobile: boolean): string[] =>
  TABLE_COLUMNS(isMobile).map(({ queryKey }) => `order[${queryKey}]`);

export const filtersOptions = { searchKey: "search" };

export const initialFilters: FiltersType = {
  createdAt: null,
  updatedAt: null,
  apiType: null,
  httpMethod: null,
};

export const initialCollapseState: CollapseState = {
  type: {
    isOpen: true,
  },
  method: {
    isOpen: true,
  },
  created: {
    isOpen: true,
  },
  updated: {
    isOpen: true,
  },
};

export const entityName = "external-vendors-table";

export const httpMethodOptions: Array<{
  value: ExternalVendorsMethodTypesValues;
  label: string;
}> = Object.entries(ExternalVendorsMethodUppercaseTypes).map(([key, value]) => ({
  value: key as ExternalVendorsMethodTypesValues,
  label: value,
}));

export const apiTypes: Array<{ value: SecretsAPITypesKeys; label: string }> = Object.entries(
  SecretsAPITypes,
).map(([key, value]) => ({
  value: key as SecretsAPITypesKeys,
  label: value,
}));
