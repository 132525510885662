import { AuthorizationActionType, AuthorizationEntityName } from "models";

export enum UserRoles {
  superAdmin = 100,
  user = 50,
}

export type UserPermissionType = Record<UserRoles, Record<AuthorizationEntityName, string[]>>;

const ALL_PERMISSIONS = [
  AuthorizationActionType.read,
  AuthorizationActionType.create,
  AuthorizationActionType.update,
  AuthorizationActionType.delete,
  AuthorizationActionType.readList,
];

export const USERS_PERMISSION: UserPermissionType = {
  50: {
    [AuthorizationEntityName.clients]: [
      AuthorizationActionType.read,
      AuthorizationActionType.readList,
    ],
    [AuthorizationEntityName.campaigns]: ALL_PERMISSIONS,
    [AuthorizationEntityName.secrets]: [],
    [AuthorizationEntityName.sources]: ALL_PERMISSIONS,
    [AuthorizationEntityName.vendors]: ALL_PERMISSIONS,
    [AuthorizationEntityName.adminSettings]: [],
    [AuthorizationEntityName.organizationSwitcher]: [],
    [AuthorizationEntityName.groupSwitcher]: [],
    [AuthorizationEntityName.users]: [],
    [AuthorizationEntityName.roles]: [],
    [AuthorizationEntityName.structure]: [],
  },
  100: {
    [AuthorizationEntityName.clients]: [],
    [AuthorizationEntityName.campaigns]: [],
    [AuthorizationEntityName.secrets]: [],
    [AuthorizationEntityName.sources]: [],
    [AuthorizationEntityName.vendors]: [],
    [AuthorizationEntityName.adminSettings]: [],
    [AuthorizationEntityName.organizationSwitcher]: [],
    [AuthorizationEntityName.groupSwitcher]: [],
    [AuthorizationEntityName.users]: [],
    [AuthorizationEntityName.roles]: [],
    [AuthorizationEntityName.structure]: [],
  },
};
