import { object, string, number, AnyObjectSchema } from "yup";

import { Values } from "./invite-form.types";

export const initialValues: Values = {
  email: "",
  role: 0,
};

export const validationSchema = (): AnyObjectSchema =>
  object().shape({
    email: string().email("Email is not valid").required("Email is required"),
    role: number()
      .min(1, "This field is required")
      .typeError("This field is required")
      .required("This field is required"),
  });
