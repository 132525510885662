import React, { FC, ReactNode } from "react";
import { Button } from "@epcnetwork/core-ui-kit";

import Typography from "./typography";
import LinkCrumb from "./link-crumb";
import HomeCrumb from "./home-crumb";

export enum CrumbAppearanceTypes {
  home = "home",
  link = "link",
  text = "text",
  button = "button",
}

export type CrumbAppearanceTypesKeys = keyof typeof CrumbAppearanceTypes;

export type CrumbProps = {
  appearance: CrumbAppearanceTypesKeys;
  path?: string;
  name?: string;
  className?: string;
  classNameLink?: string;
  onClick?: VoidFunction;
  children?: ReactNode;
};

export const Crumb: FC<CrumbProps> = ({
  name = "",
  path = "/",
  children,
  className,
  classNameLink,
  onClick,
  appearance,
}: CrumbProps) => {
  switch (appearance) {
    case "home":
      return <HomeCrumb />;
    case "text":
      return <Typography>{name?.toLowerCase()}</Typography>;
    case "button":
      return (
        <Button appearance="text" onClick={onClick} className={className}>
          {name}
        </Button>
      );
    default:
      return (
        <LinkCrumb
          name={name?.toLowerCase()}
          path={path}
          className={className}
          classNameLink={classNameLink}
        >
          {children}
        </LinkCrumb>
      );
  }
};

export default Crumb;
