import { TableColumnsType } from "types";
import { FiltersType, CollapseState } from "./media-buy-list.types";

export const TABLE_COLUMNS = (isMobile: boolean): TableColumnsType[] => [
  { label: "Name", queryKey: "name", required: true },
  { label: "Redirect url", queryKey: "redirectUrl", selected: !isMobile },
  { label: "Traffic url", queryKey: "trafficUrl", selected: !isMobile },
  { label: "Vendor", queryKey: "externalVendors", selected: !isMobile },
  { label: "Source", queryKey: "sourceId", selected: !isMobile },
  { label: "Type", queryKey: "type" },
  { label: "Clicks", queryKey: "interactionsCount" },
  { label: "Status", queryKey: "", selected: !isMobile },
  { label: "", queryKey: "" },
];

export const initialSearchFilters = {
  searchKey: "search",
};

export const initialFilters: FiltersType = {
  type: null,
  sourceId: "",
  createdAt: null,
  updatedAt: null,
};

export const initialCollapseState: CollapseState = {
  createdAt: {
    isOpen: true,
  },
  updatedAt: {
    isOpen: true,
  },
  type: {
    isOpen: true,
  },
  sourceId: {
    isOpen: true,
  },
};

export const entityName = "media-buys-table";
