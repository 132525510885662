import { MouseEvent, useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  usePagination,
  useFilters,
  useDevice,
  useFetch,
  getPaginatedList,
  useDebounce,
} from "@epcnetwork/core-ui-kit";

import { getMediaBuysByCampaignId } from "api";
import { CampaignStatusTypes, MediaBuyModel } from "models";
import { ParamsType, FiltersType, QueryType } from "./media-buy-list.types";
import { initialSearchFilters } from "./media-buy-list.constants";

interface Input {
  campaignStatus: CampaignStatusTypes | undefined;
}

interface Output {
  campaignId: string;
  editId: string;
  searchValue: string;
  isOpenEditModal: boolean;
  isOpenInlineModal: boolean;
  isInlineVendor: boolean;
  loading: boolean;
  isDuplication: boolean;
  currentToggle: boolean;
  isMobileDevice: boolean;
  error: string | undefined;
  pagination: ReturnType<typeof usePagination>;
  list: MediaBuyModel[];
  handleAddMedia: VoidFunction;
  handleCloseEditModal: VoidFunction;
  handleCloseInlineModal: VoidFunction;
  handleInlineFormRowClick: (id: string, isInlineVendor: boolean) => void;
  refresh: VoidFunction;
  handleRowClick: (event: MouseEvent<HTMLTableRowElement>) => void;
  setSearch: (value: string) => void;
  handleSetId: (id: string, isToggle: boolean) => void;
  handleDuplicateRowClick: (id: string, isToggle: boolean) => void;
}

const useMediaBuyListHook = ({ campaignStatus }: Input): Output => {
  const { id = "" } = useParams<ParamsType>();

  const { queryString, searchValue, setSearch } = useFilters<QueryType & FiltersType>(
    initialSearchFilters,
  );

  const { payload, loading, refresh, error } = useFetch(
    getMediaBuysByCampaignId.setParams({ id }).setQueryParams(queryString),
    { dependencies: [queryString] },
  );
  const list = getPaginatedList(payload?.data);
  const pagination = usePagination(payload);
  const { isMobileDevice } = useDevice();
  const { debounce } = useDebounce();

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenInlineModal, setIsOpenInlineModal] = useState(false);
  const [isInlineVendor, setIsInlineVendor] = useState(false);
  const [editId, setEditId] = useState("");
  const [currentToggle, setCurrentToggle] = useState(true);
  const [isDuplication, setIsDuplication] = useState(false);

  useEffect(() => {
    debounce(() => refresh());
  }, [campaignStatus]);

  const handleRowClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
    event.stopPropagation();
  }, []);

  const handleAddMedia = () => {
    setEditId("");
    setIsOpenEditModal(true);
  };

  const handleCloseInlineModal = () => setIsOpenInlineModal(false);
  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
    setIsDuplication(false);
  };

  const handleInlineFormRowClick = (id: string, isInlineVendor: boolean) => {
    setEditId(id);
    setIsInlineVendor(isInlineVendor);
    setIsOpenInlineModal(true);
  };

  const handleSetId = (id: string, isToggle: boolean) => {
    setCurrentToggle(isToggle);
    setEditId(id);
    setIsOpenEditModal(true);
  };

  const handleDuplicateRowClick = (id: string, isToggle: boolean) => {
    setIsDuplication(true);
    handleSetId(id, isToggle);
  };

  return {
    campaignId: id,
    editId,
    searchValue,
    isOpenEditModal,
    isOpenInlineModal,
    isInlineVendor,
    loading,
    isDuplication,
    currentToggle,
    error: error?.message,
    pagination,
    list,
    handleAddMedia,
    handleCloseEditModal,
    handleCloseInlineModal,
    refresh,
    handleRowClick,
    setSearch,
    handleSetId,
    handleDuplicateRowClick,
    handleInlineFormRowClick,
    isMobileDevice,
  };
};

export default useMediaBuyListHook;
