import { PostGroupData } from "./groups.interface";
import { apiMiddleware } from "../api.middleware";
import { GroupModel } from "models";

export const getGroups = apiMiddleware<GroupModel[]>()({
  method: "get",
  endpoint: "/groups",
});

export const postGroup = apiMiddleware<GroupModel, PostGroupData>()({
  method: "post",
  endpoint: "/groups",
});

export const putGroup = apiMiddleware<GroupModel, PostGroupData>()({
  method: "put",
  endpoint: "/groups/:id",
});

export const deleteGroup = apiMiddleware<GroupModel>()({
  method: "delete",
  endpoint: "/groups/:id",
});
