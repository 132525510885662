import React, { FC } from "react";
import { Button, MessageField, Form, FormField } from "@epcnetwork/core-ui-kit";

import { InfoLine } from "components";
import {
  validationSchema,
  PLACEHOLDER_COMMENTS_FIELD,
  PLACEHOLDER_CLIENTS,
} from "./campaigns-form.constants";
import { appTimezone } from "constants/user.constants";
import { useCampaignsFormHook } from "./campaigns-form-hook";
import { CampaignsFormProps } from "./campaigns-form.types";

import styles from "./campaigns-form.module.css";
import closeImg from "assets/images/close-secrets.svg";

const CampaignsForm: FC<CampaignsFormProps> = ({
  editClientId,
  campaignId,
  refresh,
  handleCloseModal,
  isDuplication,
}: CampaignsFormProps) => {
  const {
    payload,
    error,
    title,
    buttonText,
    isDisabled,
    isDisabledFrom,
    isDisabledTo,
    formProps,
    clientsAutocomplete,
    handleSubmit,
    minDate,
    handleSetClientId,
  } = useCampaignsFormHook(editClientId, campaignId, refresh, handleCloseModal, isDuplication);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={closeImg} alt="" onClick={handleCloseModal} />
      </div>
      <div className={styles.body}>
        <div className={styles.name}>{title}</div>
        <MessageField message={error} className={styles.errorMsg} />
        <Form {...formProps} onSubmit={handleSubmit} validationSchema={[validationSchema]}>
          <FormField
            className={styles.input}
            name="clientId"
            type="select"
            label="Client"
            placeholder={PLACEHOLDER_CLIENTS}
            asyncOptions={clientsAutocomplete}
            disabled={isDisabled}
            disableClearing={isDisabled}
            disableArrow={isDisabled}
            required
            onFieldChange={handleSetClientId}
          />
          <FormField
            className={styles.input}
            name="name"
            type="text"
            label="Name"
            placeholder="Fill the name"
            required
          />
          <FormField
            className={styles.dateInput}
            type="date-input"
            min={minDate}
            names={["startDate", "endDate"]}
            labels={["Start Date", "End Date"]}
            isMulti
            required
            enableClockTime
            disabledFrom={isDisabledFrom}
            disabledTo={isDisabledTo}
            timezone={appTimezone}
          />
          <FormField
            className={styles.comments}
            name="comments"
            placeholder={PLACEHOLDER_COMMENTS_FIELD}
            label="Comments"
            type="textarea"
            rows={4}
          />
          {payload && !isDuplication && (
            <div className={styles.infoWrapper}>
              <InfoLine title="created at" body={payload.createdAt} />
              <InfoLine title="updated at" body={payload.updatedAt} />
            </div>
          )}
          <div className={styles.buttonWrapper}>
            <Button
              type="button"
              appearance="secondary"
              onClick={handleCloseModal}
              className={styles.cancelButton}
            >
              Cancel
            </Button>
            <Button type="submit">{buttonText}</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CampaignsForm;
