import React, { FC, useState } from "react";
import cn from "classnames";
import {
  Button,
  Input,
  MenuButton,
  useCall,
  Menu,
  TextEllipsis,
  notification,
} from "@epcnetwork/core-ui-kit";

import { GroupModel } from "models";
import { postGroup, putGroup, deleteGroup } from "api";
import EditableField from "../editable-field/editable-field";

import styles from "./sidebar.module.css";
import CheckMarkImg from "assets/images/circle-check-mark-green.svg";
import CloseCircleImg from "assets/images/close-in-circle-red.svg";
import deleteImg from "assets/images/delete-entity.svg";

type SidebarProps = {
  list: GroupModel[];
  selected: GroupModel | null;
  onSelect: (group: GroupModel | null) => void;
  handleItemChange: (values: GroupModel) => void;
  refresh: VoidFunction;
};

const Sidebar: FC<SidebarProps> = ({
  list,
  selected,
  onSelect,
  handleItemChange,
  refresh,
}: SidebarProps) => {
  const [addRow, setAddRow] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [editId, setEditId] = useState("");

  const { onCallSuccess, onCallError, submit } = useCall(postGroup);
  const {
    onCallSuccess: onDeleteSuccess,
    onCallError: onDeleteError,
    submit: onSubmitDelete,
  } = useCall(deleteGroup);
  const {
    onCallSuccess: onSuccessUpdateGroup,
    onCallError: onErrorUpdateGroup,
    submit: submitUpdateGroup,
  } = useCall(putGroup);

  onDeleteSuccess(() => {
    refresh();
    notification.success("Group deleted", "Successfully deleted group");
  });

  onDeleteError(() => {
    notification.error("Deleting error", "Cannot delete group");
  });

  const handleChangeName = (field: string) => {
    setGroupName(field);
  };

  const handleOpenRowGroup = () => {
    setAddRow(true);
    onSelect(null);
  };

  const handleCloseRowGroup = () => {
    setAddRow(false);
    onSelect(list[0]);
    setGroupName("");
    setEditId("");
  };

  const handleAddGroup = () => {
    submit({ data: { name: groupName } });
  };

  const handleUpdateGroup = () => {
    submitUpdateGroup({ params: { id: editId }, data: { name: groupName } });
    return false;
  };

  onCallSuccess((payload) => {
    handleItemChange(payload);
    setGroupName("");
    setAddRow(false);
    notification.success(`Group created`, `Successfully created group`);
  });

  onCallError((error) => {
    notification.error("Error", error.message);
  });

  onSuccessUpdateGroup((payload) => {
    handleItemChange(payload);
    setGroupName("");
    setEditId("");
    notification.success(`Group updated`, `Successfully updated group`);
  });

  onErrorUpdateGroup((error) => {
    notification.error("Error", error.message);
  });

  const handleEditItem = (id: string, name: string) => {
    setEditId(id);
    onSelect({ id, name });
    handleChangeName(name);
  };

  const handleDeleteItem = (id: string) => {
    notification.confirm("Delete group ?", "You will not be able to recover it", {
      onOk: () => {
        onSubmitDelete({ params: { id } });
      },
      okText: "Delete",
      customIcon: <img src={deleteImg} alt="" />,
    });
    return false;
  };

  const handleSelectGroup = (currentGroup: GroupModel) => {
    if (currentGroup.id !== editId) {
      setEditId("");
    }
    onSelect(currentGroup);
  };

  const checkForMargin = !addRow && list.length === 0;

  return (
    <div className={styles.sidebar}>
      <div className={cn(styles.buttons, { [styles.buttonsMargin]: checkForMargin })}>
        {addRow && (
          <div className={styles.fieldWrapper}>
            <Input type="text" name="group" className={styles.field} onChange={handleChangeName} />
            <div className={styles.icons}>
              <Button imageBtn appearance="text" onClick={handleCloseRowGroup}>
                <img src={CloseCircleImg} alt="" width={18} height={18} className={styles.icon} />
              </Button>
              <Button imageBtn appearance="text" onClick={handleAddGroup}>
                <img src={CheckMarkImg} alt="" width={16} height={16} className={styles.icon} />
              </Button>
            </div>
          </div>
        )}
        {list.map((group) => (
          <div className={styles.nameWrapper} key={group.id}>
            <div
              className={cn(styles.inputWrapper, { [styles.activeBtn]: selected?.id === group.id })}
              onClick={() => handleSelectGroup(group)}
            >
              {editId && editId === group.id ? (
                <EditableField
                  selected={selected?.id === group.id}
                  editId={editId}
                  value={groupName}
                  handleClose={handleCloseRowGroup}
                  handleChangeValue={(e) => handleChangeName(e.target.value)}
                  handleSubmit={handleUpdateGroup}
                />
              ) : (
                <div key={group.id} className={styles.roleBtn}>
                  <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
                    {group.name}
                  </TextEllipsis>
                </div>
              )}
            </div>

            <div
              className={cn(styles.menuWrapper, {
                [styles.activeBtn]: selected?.id === group.id,
              })}
            >
              {editId !== group.id && (
                <Menu>
                  <MenuButton onClick={() => handleEditItem(group.id, group.name)} icon="edit">
                    Edit
                  </MenuButton>
                  <MenuButton onClick={() => handleDeleteItem(group.id)} icon="delete">
                    Delete
                  </MenuButton>
                </Menu>
              )}
            </div>
          </div>
        ))}

        <Button
          appearance="text"
          btnSize="small"
          className={styles.createBtn}
          onClick={handleOpenRowGroup}
        >
          + Create new group
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
