import React, { FC, useState } from "react";
import {
  useFilters,
  Button,
  Table,
  Modal,
  useFetch,
  Search,
  Container,
  usePagination,
  List,
} from "@epcnetwork/core-ui-kit";

import { getMembers } from "api";
import { MemberModel } from "models";
import { usePayload } from "hooks";
import TableRow from "./table-row/table-row";
import UsersForm from "../form/users-form.page";
import ListFilters from "./list-filters";
import { TABLE_COLUMNS, initialSearchFilters, entityName } from "./users-list.constants";
import { QueryType, FiltersType } from "./users-list.types";
import UsersDetailsPage from "../details";

import styles from "./users-list.module.css";

const UsersListPage: FC = () => {
  const { searchValue, setSearch, queryString } = useFilters<FiltersType & QueryType>(
    initialSearchFilters,
  );
  const [editId, setEditId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const dependencies = [queryString];
  const apiResponse = useFetch(getMembers.setQueryParams(queryString), { dependencies });

  const pagination = usePagination(apiResponse.payload);
  const { payload, list, refresh, error, loading } = usePayload(apiResponse);

  const handleAdd = () => {
    setEditId(null);
    setIsModalOpen(true);
  };

  const handleView = (id: string) => {
    setEditId(id);
    setIsViewModalOpen(true);
  };

  const handleEdit = (id: string) => {
    setEditId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditId(null);
  };

  const handleCloseViewModal = () => {
    setIsViewModalOpen(false);
    setEditId(null);
  };

  const handleItemChange = (values: MemberModel) => {
    const isExistElement = list.some((el) => el.id === values.id);
    const filteredList = list.filter((listElem) => listElem.id !== values.id);
    const newData: List<MemberModel> = {
      ...payload,
      data: [values, ...filteredList],
      total: isExistElement ? Number(payload.total) : Number(payload.total) + 1,
    };
    apiResponse.actions.setData(newData);
    setIsModalOpen(false);
  };

  return (
    <Container>
      <div className={styles.headerWrapper}>
        <div className={styles.searchHeaderWrapper}>
          <div className={styles.searchFormContainer}>
            <Search
              searchValue={searchValue}
              setSearch={setSearch}
              className={styles.search}
              width="small"
            />
            <ListFilters />
          </div>
        </div>
        <>
          <Button appearance="primary" onClick={handleAdd} className={styles.addBtn}>
            + Add User
          </Button>
          <Button appearance="primary" onClick={handleAdd} className={styles.addSmallScreen}>
            + Add
          </Button>
        </>
      </div>
      <Table
        columns={TABLE_COLUMNS}
        list={list}
        entityName={entityName}
        error={error?.message}
        loading={loading}
        refresh={refresh}
        pagination={pagination}
        isTabTable
        row={(item) => (
          <TableRow key={item.id} item={item} handleEdit={handleEdit} handleView={handleView} />
        )}
      />
      <Modal isOpen={isModalOpen} setClose={handleCloseModal}>
        <UsersForm
          id={editId}
          handleItemChange={handleItemChange}
          handleCloseModal={handleCloseModal}
          refresh={refresh}
        />
      </Modal>
      {editId && (
        <Modal isOpen={isViewModalOpen} setClose={handleCloseViewModal}>
          <UsersDetailsPage id={editId} handleCloseModal={handleCloseViewModal} />
        </Modal>
      )}
    </Container>
  );
};

export default UsersListPage;
