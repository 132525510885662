import { MemberRoles } from "./roles-list.types";

export const TABLE_COLUMNS = [
  { label: "" },
  { label: "Read" },
  { label: "Read in details" },
  { label: "Create" },
  { label: "Edit" },
  { label: "Delete" },
];

export const tabStatesMap: Record<number, MemberRoles> = {
  0: "admin",
  1: "owner",
  2: "groupManager",
  3: "user",
  4: "trial",
};

export const TABS = {
  admin: "Admin",
  owner: "Owner",
  groupManager: "Group Manager",
  user: "User",
  trial: "Trial user",
};

export const initialFilters = {
  initialState: {
    section: "admin",
  },
};
