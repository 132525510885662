import { useState } from "react";
import { FormikHelpers } from "formik";
import { useDidMount, useSubmit, useQuery } from "@epcnetwork/core-ui-kit";

import { resetPassword } from "api";
import { Values } from "./send-email.types";

interface Output {
  onSubmit: (values: Values, helpers: FormikHelpers<Values>) => Promise<void>;
  errorMessage: string;
  isLinkExpired: boolean;
  isEmailSent: boolean;
  handleSent: (value: boolean) => void;
}

const useSendEmailHook = (): Output => {
  const { query, setQueryParams } = useQuery();

  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  useDidMount(() => {
    setIsLinkExpired(query.expired === "true");
    setQueryParams({});
  });

  const { onSubmit, onSubmitSuccess, error } = useSubmit(resetPassword);

  onSubmitSuccess(() => {
    handleSent(true);
  });

  const handleSent = (value: boolean) => {
    setIsEmailSent(value);
  };

  const errorMessage = error?.message || "";

  return {
    onSubmit,
    errorMessage,
    isLinkExpired,
    isEmailSent,
    handleSent,
  };
};

export default useSendEmailHook;
