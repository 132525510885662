import React, { memo } from "react";
import { useFormikContext } from "formik";
import { FormField, SelectOption } from "@epcnetwork/core-ui-kit";

import { SecretsRestApiTypesKeys } from "models";

import { InitialValues, RestApiInitialValues } from "../secrets-form.types";
import { restApiOptions, httpMethodOptions } from "../secrets-form.constants";
import RestApiDispatcher from "./rest-api-dispatcher";

const RestApi = memo((): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<InitialValues<RestApiInitialValues>>();

  const authorizationPlacement = values.apiTypeContent?.authorizationPlacement;

  const selectedOption = restApiOptions.find((option) => option.value === authorizationPlacement);

  const handlePlacementChange = ({ value }: SelectOption<SecretsRestApiTypesKeys>) => {
    if (authorizationPlacement === value) return;

    // EDGE CASE, EMPTY STRING IS INTENTIONALLY!
    setFieldValue("apiTypeContent.restApiContent", "");
  };

  return (
    <>
      <FormField
        type="text"
        name="apiTypeContent.targetUrl"
        label="Target Url"
        placeholder="Fill the target url"
        required
      />
      <FormField
        type="select"
        name="apiTypeContent.httpMethod"
        label="Http Method"
        placeholder="Choose Http Method"
        disableClearing
        options={httpMethodOptions}
        required
      />
      <FormField
        type="select"
        name="apiTypeContent.authorizationPlacement"
        label="AUTHORIZATION Placement"
        placeholder="Choose AUTHORIZATION Placement"
        disableClearing
        options={restApiOptions}
        initiallySelectedOptions={selectedOption}
        onFieldChange={handlePlacementChange}
        required
      />
      {authorizationPlacement && <RestApiDispatcher placementType={authorizationPlacement} />}
    </>
  );
});

export default RestApi;
