import { useState } from "react";
import { FormikHelpers } from "formik";
import {
  useAutocomplete,
  useFormValues,
  useSubmit,
  notification,
  NonNullableKeys,
  roundToMinutes,
  addHours,
  FormPropsType,
} from "@epcnetwork/core-ui-kit";

import { getCampaign, getClients, postCampaign, putCampaign } from "api";
import { CampaignModel } from "models";
import { getError, getDateWithTimezone } from "utils";
import { initialValues } from "./campaigns-form.constants";
import { InitialValues, ParamsType } from "./campaigns-form.types";

interface Output {
  error: string;
  title: string;
  buttonText: string;
  isDisabled: boolean;
  isDisabledFrom: boolean;
  isDisabledTo: boolean;
  formProps: FormPropsType<InitialValues>;
  payload: CampaignModel | null;
  clientsAutocomplete: ReturnType<typeof useAutocomplete>;
  minDate: Date;
  handleSubmit: (values: InitialValues, helpers: FormikHelpers<InitialValues>) => Promise<void>;
  handleSetClientId: (clientId: ParamsType) => void;
}

export const useCampaignsFormHook = (
  editClientId: string,
  campaignId: string,
  refresh: VoidFunction,
  handleCloseModal: VoidFunction,
  isDuplication?: boolean,
): Output => {
  const [error, setError] = useState("");
  const [clientId, setClientId] = useState(editClientId);

  const handleSetClientId = (clientId: ParamsType) => setClientId(clientId.id);

  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    postCampaign.setParams({ clientId }),
    putCampaign.setParams({ campaignId }),
    !!campaignId && !isDuplication,
  );

  const { payload, formProps, mapInitialValues } = useFormValues(
    initialValues,
    getCampaign.setParams({ campaignId }),
    !!campaignId,
  );

  mapInitialValues((payload) => {
    return {
      ...payload,
      clientId: payload?.client?.id || "",
      name: payload?.name || "",
      startDate: payload?.startDate || null,
      endDate: payload?.endDate || null,
      comments: payload?.comments || "",
    };
  });

  onSubmitSuccess(() => {
    handleCloseModal();
    const title = `Campaign ${isDuplication || !clientId ? "created" : "updated"}`;
    const message = `Successfully ${isDuplication || !clientId ? "created" : "updated"} campaign`;
    notification.success(title, message);
    refresh();
  });

  onSubmitError(({ originalError, message }) => {
    const validationErrorMessage = getError(originalError);
    setError(validationErrorMessage || message);
  });

  const clientsAutocomplete = useAutocomplete(getClients, "id", "name", { searchKey: "search" });

  const handleSubmit = onSubmitMapping((values: NonNullableKeys<InitialValues>) => {
    if (!campaignId) return values;

    const { clientId, name, startDate, endDate, comments } = values;
    return {
      clientId,
      name,
      startDate,
      endDate,
      comments,
    };
  });

  const minDate = payload?.startDate
    ? getDateWithTimezone(payload.startDate)
    : getDateWithTimezone(roundToMinutes(addHours(0), 60));

  const title = isDuplication
    ? "Duplicate"
    : editClientId
    ? `Edit: ${formProps.initialValues?.name || "Campaign"}`
    : `New Campaign`;
  const buttonText = isDuplication || editClientId ? "Save" : "Add";
  const isDisabled = !!editClientId && !isDuplication;
  const isDisabledFrom =
    (!!editClientId && payload?.status !== "pending" && !isDuplication) ||
    payload?.status === "completed";
  const isDisabledTo = payload?.status === "completed";

  return {
    error,
    title,
    payload,
    buttonText,
    isDisabled,
    isDisabledFrom,
    isDisabledTo,
    formProps,
    clientsAutocomplete,
    minDate,
    handleSubmit,
    handleSetClientId,
  };
};
