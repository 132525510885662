import { List } from "@epcnetwork/core-ui-kit";

import { apiMiddleware } from "../api.middleware";
import { UserModel, MemberModel } from "models";
import { UserFormData, UserInviteFormData } from "./users.interface";

export const getUsers = apiMiddleware<UserModel[]>()({
  method: "get",
  endpoint: `/users`,
});

export const getUser = apiMiddleware<UserModel>()({
  method: "get",
  endpoint: `/users/:userId`,
});

export const postUser = apiMiddleware<UserModel, UserFormData>()({
  method: "post",
  endpoint: `/users`,
});

export const putUser = apiMiddleware<UserModel, UserFormData>()({
  method: "put",
  endpoint: `/users/:userId`,
});

export const patchUser = apiMiddleware<UserModel, UserFormData>()({
  method: "patch",
  endpoint: `/users/:userId`,
});

export const deleteUser = apiMiddleware<UserModel>()({
  method: "delete",
  endpoint: `/users/:userId`,
});

export const reinviteUser = apiMiddleware<UserModel>()({
  method: "post",
  endpoint: `/users/:userId/reinvite`,
});

export const postMembers = apiMiddleware<MemberModel, UserFormData>()({
  method: "post",
  endpoint: `/members`,
});

export const putMember = apiMiddleware<MemberModel, UserFormData>()({
  method: "put",
  endpoint: `/members/:memberId`,
});

export const postMember = apiMiddleware<MemberModel, UserInviteFormData>()({
  method: "post",
  endpoint: `/members/:groupId`,
});

export const getMembers = apiMiddleware<List<MemberModel>>()({
  method: "get",
  endpoint: `/members`,
});

export const getMember = apiMiddleware<MemberModel>()({
  method: "get",
  endpoint: `/members/:memberId`,
});
