import React, { FC } from "react";
import { useFormikContext } from "formik";
import { Select, useAutocomplete, useDidMount, useWillUnmount } from "@epcnetwork/core-ui-kit";

import { getVendorProviderList } from "api";
import {
  BrontoType,
  VendorFormInitialValuesType,
} from "pages/external-vendors/form/vendors-form.types";
import { resetFormState } from "../vendors-form.utils";

const Bronto: FC = () => {
  const { values, setFormikState, setFieldValue, errors, touched } = useFormikContext<
    VendorFormInitialValuesType & BrontoType
  >();

  const providersAutocomplete = useAutocomplete(
    getVendorProviderList.setParams({ secretId: values.secretId }),
    "id",
    "name",
    {
      dependencies: [values.secretId],
    },
  );

  useDidMount(() => {
    setFieldValue("list", null);
  });

  useWillUnmount(() => setFormikState(resetFormState));

  const error = touched.list && errors.list ? String(errors.list) : "";

  return (
    <>
      <Select
        label="List"
        placeholder="Choose list"
        error={error}
        asyncOptions={providersAutocomplete}
        onChange={(option) => {
          const { value = "", label = "" } = option || {};
          if (!option) return setFieldValue("list", null);
          setFieldValue("list", { id: value, name: label });
        }}
        selectedOptionsKeys={values?.list?.id ? values?.list?.id : ""}
        required
      />
    </>
  );
};

export default Bronto;
