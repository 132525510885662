import { SecretAPIContentType } from "pages/secrets/form/secrets-form.types";

export enum SecretsAPITypes {
  restApi = "REST API",
  bronto = "BT (Bronto)",
  salesforce = "ET (Salesforce Exact Target)",
  getResponse = "GR (Getresponse)",
  infusionsoft = "IS (Infusion Soft)",
  iterable = "IT (Iterable)",
  egoi = "EG (E-goi)",
  ontraport = "OT (Ontraport)",
}

export type SecretsAPITypesKeys = keyof typeof SecretsAPITypes;

export type SecretProvider = {
  key: SecretsAPITypesKeys;
  value: typeof SecretsAPITypes[SecretsAPITypesKeys];
};

export enum SecretsRestApiTypes {
  bearer = "Bearer",
  basic = "Basic",
  header = "Header",
  query = "Query",
  body = "Body",
}

export type SecretsRestApiTypesKeys = keyof typeof SecretsRestApiTypes;

export enum RestApiBodyOptionTypes {
  json = "application/json",
  urlEncoded = "application/x-www-form-urlencoded",
}

export type RestApiBodyOptionTypesKeys = keyof typeof RestApiBodyOptionTypes;

export type SecretModel = {
  apiType: SecretsAPITypesKeys;
  createdAt: string;
  deleted: boolean;
  name: string;
  id: string;
  properties: SecretAPIContentType;
  updatedAt: string;
};
