import { object, string, AnyObjectSchema } from "yup";

import { Values } from "./organizations-form.types";

export const initialData: Values = {
  name: "",
  city: undefined,
  country: undefined,
  picture: undefined,
  owner: undefined,
};

export const validationSchema = (): AnyObjectSchema =>
  object().shape({
    name: string().required("This field is required"),
  });
