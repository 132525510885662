import React, { FC, memo, useMemo, useState, createContext, CSSProperties } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import { useSelector } from "react-redux";
import {
  NotAuthorized,
  useAutocomplete,
  useLocalStorage,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import { getActiveRoute } from "utils";
import { RootState } from "store";
import { getRegisteredOrganizations } from "api";
import { ME_ROLE } from "constants/auth.constants";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import { entityName, collapsedDesktop, sidebarWidth } from "../sidebar/sidebar.constants";
import { SUPER_ADMIN_OPTIONS } from "constants/auth.constants";
import { RouteConfig, routes } from "config/routes.config";

import styles from "./navigation.module.css";

interface Props {
  component: FC<unknown>;
  showNavigation: boolean;
  isAuthorized?: boolean;
}

type NavigationContextValues = {
  activeRoute: RouteConfig;
  organizationAutocomplete: ReturnType<typeof useAutocomplete>;
  refreshOrganizationsList: VoidFunction;
};

export const NavigationContext = createContext<NavigationContextValues | null>(null);

export const Navigation = memo(
  ({ component, showNavigation, isAuthorized = true, ...rest }: Props) => {
    const Component = component;
    const location = useLocation();

    const { user } = useSelector((state: RootState) => state.auth);

    const organizationAutocomplete = useAutocomplete(getRegisteredOrganizations, "id", "name");

    const isSuperAdmin = user?.type === ME_ROLE.super_admin && !user?.member;

    const currentRoutes = routes.filter((route) => {
      if (!isSuperAdmin) return !route.isAdminModule;

      return route.isAdminModule;
    });

    useDidUpdate(() => {
      if (user?.type !== ME_ROLE.super_admin) return null;

      organizationAutocomplete.actions.setData(
        organizationAutocomplete.fetchOptions.concat(SUPER_ADMIN_OPTIONS),
      );
    }, [organizationAutocomplete.loading]);

    const currentRoute = useMemo(
      () => getActiveRoute(location, currentRoutes),
      [location, currentRoutes],
    );

    const { value } = useLocalStorage(entityName);

    const [isMenuClosed, setIsMenuClosed] = useState<boolean>(value || false);

    const toggleSidebar = () => {
      setIsMenuClosed((prev) => !prev);
    };

    const handleRefreshOrganizationList = () => {
      organizationAutocomplete.actions.setData([]);
      organizationAutocomplete.refresh();
    };

    const context: NavigationContextValues = {
      activeRoute: currentRoute,
      organizationAutocomplete,
      refreshOrganizationsList: handleRefreshOrganizationList,
    };

    const width = value ? collapsedDesktop : sidebarWidth;
    const contentStyles = cn(styles.content, { [styles.closed]: value });

    const inlineStyles: CSSProperties = {
      maxWidth: `calc(100vw - ${width})`,
    };

    if (!isAuthorized) {
      return (
        <NotAuthorized title="Access Denied" subtitle="You're not authorized to view this page" />
      );
    }

    if (!showNavigation) {
      return <Component {...rest} />;
    }

    return (
      <NavigationContext.Provider value={context}>
        <div className={styles.container} data-testid="app">
          <Sidebar data-testid="sidebar" collapsed={isMenuClosed} />
          <div className={contentStyles} data-testid="content" style={inlineStyles}>
            <Navbar toggleSidebar={toggleSidebar} isSidebarOpen={isMenuClosed} />
            <Component {...rest} />
          </div>
        </div>
      </NavigationContext.Provider>
    );
  },
);

export default Navigation;
