import React, { FC } from "react";
import { useQuery } from "@epcnetwork/core-ui-kit";

import { CrumbSeparator, Breadcrumbs as CoreBreadcrumbs, Crumb } from "components";
import { CampaignModel } from "models";
import { CAMPAIGNS_LIST_PAGE, CLIENTS_LIST_PAGE } from "constants/routes.constants";

type Props = {
  name: string;
  campaign: CampaignModel;
};

const Breadcrumbs: FC<Props> = ({ name, campaign }: Props) => {
  const { stringify } = useQuery();
  const clientQuery = { clientId: [campaign?.client?.id] };
  const linkToCampaignList = `${CAMPAIGNS_LIST_PAGE.path}${stringify(clientQuery)}`;
  const campaignName = campaign?.client?.name || "Campaign Details";

  return (
    <CoreBreadcrumbs>
      <Crumb appearance="link" path={CLIENTS_LIST_PAGE.path} name={CLIENTS_LIST_PAGE.name} />
      <CrumbSeparator />
      <Crumb appearance="link" path={linkToCampaignList} name={campaignName} />
      {name && (
        <>
          <CrumbSeparator />
          <Crumb name={name} appearance="text" />
        </>
      )}
    </CoreBreadcrumbs>
  );
};

export default Breadcrumbs;
