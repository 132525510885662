import React, { FC } from "react";
import { Button, Form, FormButtons, FormField, MessageField } from "@epcnetwork/core-ui-kit";

import { Hint } from "components";
import useLoginHook from "./login.hook";
import { initialValues, validationSchema } from "./login.constants";

import logo from "assets/images/logo.svg";
import leafImg from "assets/images/login-leaf.svg";
import backgroundLeafs from "assets/images/leafs.svg";
import backgroundImages from "assets/images/leaf-images.png";
import styles from "./login.module.css";

const LoginPage: FC = () => {
  const { onSubmit, handleForgotPassword, errorMessage, hasPassChanged, hasAccCreated } =
    useLoginHook();

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img className={styles.icon} src={logo} alt="" />
        <div className={styles.logoText}>ClicksUA</div>
      </div>
      <div className={styles.content}>
        <div className={styles.loginForm}>
          <img className={styles.leafBackground} src={leafImg} alt="" />
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <div className={styles.title}>Welcome to ClicksUA</div>
            <div className={styles.subtitle}>Please use your credential to Log In</div>
            <MessageField message={errorMessage} align="left" className={styles.errorMessage} />
            <FormField
              type="text"
              name="email"
              label="Email"
              inputSize="medium"
              placeholder="Fill your email"
            />
            <FormField
              type="password"
              name="password"
              label="Password"
              inputSize="medium"
              placeholder="Fill your password"
            />
            {hasPassChanged && (
              <Hint type="success">
                Your password has been reset successfully, <br />
                you can use your new password to log in.
              </Hint>
            )}
            {hasAccCreated && (
              <Hint type="success">
                Your account has been successfully created. <br />
                You can log in.
              </Hint>
            )}
            <Button
              type={"button"}
              appearance={"text"}
              className={styles.forgotPassword}
              onClick={handleForgotPassword}
            >
              Forgot password
            </Button>
            <FormButtons align="right">
              <Button btnSize="medium" className={styles.btn} type="submit">
                Log in
              </Button>
            </FormButtons>
          </Form>
        </div>
        <div className={styles.info}>
          <div className={styles.option}>&#169;2021 ClicksUA</div>
        </div>
      </div>
      <div className={styles.sidebar}>
        <img src={backgroundLeafs} className={styles.leafs} alt="" />
        <img src={backgroundImages} className={styles.images} alt="" />
      </div>
    </div>
  );
};

export default LoginPage;
