import React, { FC } from "react";
import { TableRow, TableCell, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { MediaBuyModel } from "models";

import styles from "./table-row.module.css";

type Props = {
  item: MediaBuyModel;
};

const Row: FC<Props> = ({ item }: Props) => {
  const { name, id, campaign, isActive, client } = item;

  const activeStatus = isActive ? "Active" : "Inactive";

  return (
    <TableRow id={id}>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {name}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {campaign.name}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {client.name}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {activeStatus}
        </TextEllipsis>
      </TableCell>
    </TableRow>
  );
};

export default Row;
