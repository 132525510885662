/* eslint-disable */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { TOKEN_STORAGE_FIELD } from "constants/auth.constants";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { LOGIN_PAGE } from "constants/routes.constants";

interface Props {}

const AuthRoute = ({ ...rest }: Props) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const isValidRoute = localStorage.getItem(TOKEN_STORAGE_FIELD) && user;

  if (!isValidRoute) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_PAGE.path,
        }}
      />
    );
  }

  return <Route {...rest} />;
};

export default AuthRoute;
