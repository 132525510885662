import React, { FC, useCallback, useState } from "react";
import classnames from "classnames";
import {
  Filters,
  DateInput,
  useDevice,
  CollapseSelfControlled,
  Button,
} from "@epcnetwork/core-ui-kit";

import { appTimezone } from "constants/user.constants";
import { initialFilters, initialCollapseState, getQueryKeys } from "../secrets-list.constants";
import { CollapseState, CollapseStateKeys } from "../secrets-list.types";

import styles from "./list-filters.module.css";
import arrowDownImg from "assets/images/arrow-down.svg";

const ListFilters: FC = () => {
  const { isMobileDevice } = useDevice();
  const [isCollapseState, setIsCollapseState] = useState<CollapseState>(initialCollapseState);
  const queryKeys = getQueryKeys(isMobileDevice);
  const nonClearableKeys = ["search"].concat(queryKeys);

  const handleCollapseView = useCallback(
    (key: CollapseStateKeys) => {
      const stateCollapse: CollapseState = { ...isCollapseState };
      stateCollapse[key].isOpen = !stateCollapse[key].isOpen;
      setIsCollapseState(stateCollapse);
    },
    [isCollapseState],
  );

  const headerCollapse = (key: CollapseStateKeys) => {
    return (
      <Button
        className={styles.collapseWrapper}
        appearance="text"
        onClick={() => handleCollapseView(key)}
      >
        <div className={styles.leftPart}>
          <img
            src={arrowDownImg}
            alt=""
            className={classnames({ [styles.revertArrow]: isCollapseState[key].isOpen })}
          />
          <div className={styles.label}>{`By ${key}`}</div>
        </div>
      </Button>
    );
  };

  return (
    <Filters initialFilters={initialFilters} nonClearableKeys={nonClearableKeys}>
      {({ getDatePickerDate, setDatePickerDate }) => {
        const [startFrom, startTo] = getDatePickerDate("createdAt");
        const [endFrom, endTo] = getDatePickerDate("updatedAt");

        return (
          <div>
            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={headerCollapse("created")}
                initiallyOpened={isCollapseState["created"].isOpen}
                removeFromDOMOnClosed
                triggerClassName={styles.triggerElement}
              >
                <DateInput
                  dateFrom={startFrom}
                  dateTo={startTo}
                  onChange={setDatePickerDate("createdAt", true)}
                  isMulti
                  disableError
                  timezone={appTimezone}
                  className={styles.inputDate}
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>

            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={headerCollapse("updated")}
                initiallyOpened={isCollapseState["updated"].isOpen}
                removeFromDOMOnClosed
                triggerClassName={styles.triggerElement}
              >
                <DateInput
                  dateFrom={endFrom}
                  dateTo={endTo}
                  onChange={setDatePickerDate("updatedAt", true)}
                  isMulti
                  disableError
                  timezone={appTimezone}
                  className={styles.inputDate}
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>
          </div>
        );
      }}
    </Filters>
  );
};

export default ListFilters;
