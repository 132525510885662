import React, { FC, cloneElement, ReactElement, memo } from "react";
import cn from "classnames";

import { useAuthorization } from "hooks";
import { AuthorizationEntityName, AuthorizationActionType } from "models";

import styles from "./permit-component.module.css";

interface PermitComponentProps {
  authEntity: AuthorizationEntityName;
  authAction: AuthorizationActionType;
  disabled?: boolean;
  children: ReactElement;
}

const PermitComponent: FC<PermitComponentProps> = memo(
  ({ authEntity, authAction, disabled, children }: PermitComponentProps) => {
    const ability = useAuthorization();
    const isPermitted = ability.can(authAction, authEntity);
    const isDisabled = !isPermitted && disabled;

    if (!isPermitted && !disabled) return null;

    const renderComponent = !isDisabled
      ? children
      : cloneElement(children, {
          children: null,
        });

    return <div className={cn({ [styles.disabled]: isDisabled })}>{renderComponent}</div>;
  },
);

export default PermitComponent;
