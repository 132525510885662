import React, { FC, ReactNode } from "react";
import { TableRow, TableCell } from "@epcnetwork/core-ui-kit";

import { RolesModel } from "models";

import styles from "./table-row.module.css";
import CheckMarkGreen from "assets/images/check-mark-green.svg";
import XCrossRed from "assets/images/x-cross-red.svg";

type Props = {
  item: RolesModel;
};

const Row: FC<Props> = ({ item }: Props) => {
  const { name, id, read, create, list, update, delete: deleteKey } = item;

  if (!item) return null;

  const getIcon = (key: string): ReactNode => {
    if (key === "access") {
      return <img src={CheckMarkGreen} alt="" />;
    }

    if (key === "blockAccess") {
      return <img src={XCrossRed} alt="" />;
    }

    return <div>-</div>;
  };

  return (
    <TableRow id={id}>
      <TableCell className={styles.cell}>
        <div>{name}</div>
      </TableCell>
      <TableCell className={styles.cell}>
        <div>{getIcon(list)}</div>
      </TableCell>
      <TableCell className={styles.cell}>
        <div>
          <div>{getIcon(read)}</div>
        </div>
      </TableCell>
      <TableCell className={styles.cell}>
        <div>{getIcon(create)}</div>
      </TableCell>
      <TableCell className={styles.cell}>
        <div>{getIcon(update)}</div>
      </TableCell>
      <TableCell className={styles.deleteCell}>
        <div>{getIcon(deleteKey)}</div>
      </TableCell>
    </TableRow>
  );
};

export default Row;
