import { useState } from "react";
import { FormikHelpers } from "formik";
import {
  NonNullableKeys,
  useAutocomplete,
  useFormValues,
  useSubmit,
  notification,
  SelectOption,
  FormPropsType,
} from "@epcnetwork/core-ui-kit";

import { getExternalVendor, getSecretProviderList, getSecrets, postVendor, putVendor } from "api";
import { SecretsAPITypesKeys } from "models";
import { jsonValidation } from "utils";
import { arrayToObject, objectToArray, vendorModalInitialValues } from "./vendors-form.constants";
import { VendorFormType, VendorAPIType, VendorFormInitialValuesType } from "./vendors-form.types";

interface Output {
  formProps: FormPropsType<VendorFormInitialValuesType>;
  error: string;
  handleCancelClick: VoidFunction;
  onSubmit: (
    values: VendorFormInitialValuesType,
    helpers: FormikHelpers<VendorFormInitialValuesType>,
  ) => Promise<void>;
  providersAutocomplete: ReturnType<typeof useAutocomplete>;
  secretsAutocomplete: ReturnType<typeof useAutocomplete>;
  apiType: VendorAPIType | null;
  handleProviderSelection: (option: SelectOption<SecretsAPITypesKeys>) => void;
}

interface Input {
  id: string;
  onCloseModal: VoidFunction;
  refresh: VoidFunction;
}

const useVendorsFormHook = ({ id, onCloseModal, refresh }: Input): Output => {
  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    postVendor,
    putVendor.setParams({ vendorId: id }),
    !!id,
  );
  const { formProps, mapInitialValues } = useFormValues(
    vendorModalInitialValues,
    getExternalVendor.setParams({ externalVendorId: id }),
    !!id,
  );

  const [apiType, setApiType] = useState<VendorAPIType | null>(null);
  const providersAutocomplete = useAutocomplete(getSecretProviderList, "key", "value");

  const secretsAutocomplete = useAutocomplete(getSecrets, "id", "name", {
    searchKey: "search",
    dependencies: [apiType],
    additionalQuery: { apiType },
  });

  const [error, setError] = useState("");

  const handleProviderSelection = (option: SelectOption<SecretsAPITypesKeys>) => {
    if (!option) {
      setApiType(null);
      return {};
    } else {
      setApiType(option.value);
    }
  };

  const onSubmit = onSubmitMapping((values: NonNullableKeys<VendorFormType>) => {
    if (!id)
      return {
        ...values,
        secretId: values.secretId,
        list: values.list,
        apiType: apiType as VendorAPIType,
        contentType: values.contentType,
        queryParams: arrayToObject(values.queryParams),
        bodyParams: arrayToObject(values.bodyParams),
        soapBody: values.soapBody && jsonValidation(values.soapBody) && JSON.parse(values.soapBody),
      };

    return {
      list: values.list,
      secretId: values.secretId,
      apiType: apiType as VendorAPIType,
      name: values.name,
    };
  });

  onSubmitSuccess(() => {
    const title = id ? "Vendor updated" : "Vendor created";
    const message = id ? "Successfully updated vendor" : "Successfully created vendor";
    notification.success(title, message);
    onCloseModal();
    refresh();
  });

  onSubmitError((error) => {
    setError(error.message);
  });

  const handleCancelClick = () => onCloseModal();

  mapInitialValues((payload) => {
    setApiType(payload.apiType);

    return {
      ...payload,
      bodyParams: objectToArray(payload.bodyParams),
      queryParams: objectToArray(payload.queryParams),
      soapBody: payload.soapBody && JSON.stringify(payload.soapBody),
      secretId: payload.secretId,
      contentType: payload.contentType,
      list: payload.list,
    };
  });

  return {
    apiType,
    formProps,
    error,
    onSubmit,
    handleCancelClick,
    providersAutocomplete,
    secretsAutocomplete,
    handleProviderSelection,
  };
};

export default useVendorsFormHook;
