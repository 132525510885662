export enum ContentTypes {
  json = "application/json",
  form = "application/x-www-form-urlencoded",
}

export enum HttpMethods {
  get = "GET",
  put = "PUT",
  post = "POST",
}

export type HttpMethodsTypes = "GET" | "PUT" | "POST";
