import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { Container, Button, Modal, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import OrganizationForm from "../form/organizations-form.page";

import styles from "./organizations-details.module.css";
import imagePlaceholder from "assets/images/user.svg";
import editImg from "assets/images/pencil-white.svg";
import trashImg from "assets/images/delete.svg";

const OrganizationsDetailsPage: FC = () => {
  const { organization } = useSelector((state: RootState) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!organization) {
    return null;
  }

  const handleEdit = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Container className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.overview}>
          <div className={styles.title}>Overview</div>
          <div className={styles.detailsWrapper}>
            <img src={organization.image || imagePlaceholder} alt="" width={100} height={100} />
            <div className={styles.fields}>
              <TextEllipsis
                className={styles.name}
                tooltipTrigger="hover"
                tooltipPosition="bottom-left"
              >
                {organization.name}
              </TextEllipsis>
              <div className={styles.row}>
                <div className={styles.subtitle}>Owner:</div>
                <TextEllipsis
                  className={cn(styles.label, styles.owner)}
                  tooltipTrigger="hover"
                  tooltipPosition="bottom-left"
                >
                  {organization.owner || "not specified"}
                </TextEllipsis>
              </div>
              <div className={styles.row}>
                <div className={styles.subtitle}>Location:</div>
                <TextEllipsis
                  className={styles.label}
                  tooltipTrigger="hover"
                  tooltipPosition="bottom-left"
                >
                  {organization.city && organization.country
                    ? `${organization.city}, ${organization.country}`
                    : "not specified"}
                </TextEllipsis>
              </div>
            </div>
          </div>
          <div className={styles.btnWrapper}>
            <Button appearance="primary" onClick={handleEdit}>
              <img src={editImg} alt="" />
              Edit
            </Button>
          </div>
        </div>
        <div className={styles.deleteWrapper}>
          <Button appearance="text" className={styles.removeBtn} imageBtn>
            <img src={trashImg} alt="" />
            Delete organization
          </Button>
        </div>
      </div>

      <Modal isOpen={isModalOpen} setClose={handleCloseModal} contentClassName={styles.modal}>
        <OrganizationForm handleCloseModal={handleCloseModal} id={organization.id} />
      </Modal>
    </Container>
  );
};

export default OrganizationsDetailsPage;
