import { AbilityBuilder, Ability } from "@casl/ability";

import { USERS_PERMISSION, UserRoles } from "constants/roles.constants";
import { UserModel, AuthorizationActionType, AuthorizationEntityName } from "models";

export const createAuthorizationConfig = (user: UserModel | null): Ability => {
  const { can, cannot, rules } = new AbilityBuilder(Ability);

  const canUserWithPermissions = (
    action: AuthorizationActionType,
    entityName: AuthorizationEntityName,
  ) => {
    const userRole: UserRoles = user?.type || UserRoles.user;
    const userPermission = USERS_PERMISSION[userRole]?.[entityName];

    const isPermitted =
      userPermission && !!userPermission.find((permission: string) => permission === action);
    const isUnlimitedAccess = userRole === 100;

    if (isUnlimitedAccess || isPermitted) {
      can(action, entityName);
    } else {
      cannot(action, entityName);
    }
  };

  // Clients
  canUserWithPermissions(AuthorizationActionType.read, AuthorizationEntityName.clients);
  canUserWithPermissions(AuthorizationActionType.create, AuthorizationEntityName.clients);
  canUserWithPermissions(AuthorizationActionType.update, AuthorizationEntityName.clients);
  canUserWithPermissions(AuthorizationActionType.delete, AuthorizationEntityName.clients);
  canUserWithPermissions(AuthorizationActionType.readList, AuthorizationEntityName.clients);

  // Campaigns
  canUserWithPermissions(AuthorizationActionType.read, AuthorizationEntityName.campaigns);
  canUserWithPermissions(AuthorizationActionType.create, AuthorizationEntityName.campaigns);
  canUserWithPermissions(AuthorizationActionType.update, AuthorizationEntityName.campaigns);
  canUserWithPermissions(AuthorizationActionType.delete, AuthorizationEntityName.campaigns);
  canUserWithPermissions(AuthorizationActionType.readList, AuthorizationEntityName.campaigns);

  // Secrets
  canUserWithPermissions(AuthorizationActionType.read, AuthorizationEntityName.secrets);
  canUserWithPermissions(AuthorizationActionType.create, AuthorizationEntityName.secrets);
  canUserWithPermissions(AuthorizationActionType.update, AuthorizationEntityName.secrets);
  canUserWithPermissions(AuthorizationActionType.delete, AuthorizationEntityName.secrets);
  canUserWithPermissions(AuthorizationActionType.readList, AuthorizationEntityName.secrets);

  // Vendors
  canUserWithPermissions(AuthorizationActionType.read, AuthorizationEntityName.vendors);
  canUserWithPermissions(AuthorizationActionType.create, AuthorizationEntityName.vendors);
  canUserWithPermissions(AuthorizationActionType.update, AuthorizationEntityName.vendors);
  canUserWithPermissions(AuthorizationActionType.delete, AuthorizationEntityName.vendors);
  canUserWithPermissions(AuthorizationActionType.readList, AuthorizationEntityName.vendors);

  // Sources
  canUserWithPermissions(AuthorizationActionType.read, AuthorizationEntityName.sources);
  canUserWithPermissions(AuthorizationActionType.create, AuthorizationEntityName.sources);
  canUserWithPermissions(AuthorizationActionType.update, AuthorizationEntityName.sources);
  canUserWithPermissions(AuthorizationActionType.delete, AuthorizationEntityName.sources);
  canUserWithPermissions(AuthorizationActionType.readList, AuthorizationEntityName.sources);

  // Admin Settings
  canUserWithPermissions(AuthorizationActionType.read, AuthorizationEntityName.adminSettings);
  canUserWithPermissions(AuthorizationActionType.create, AuthorizationEntityName.adminSettings);
  canUserWithPermissions(AuthorizationActionType.update, AuthorizationEntityName.adminSettings);
  canUserWithPermissions(AuthorizationActionType.delete, AuthorizationEntityName.adminSettings);
  canUserWithPermissions(AuthorizationActionType.readList, AuthorizationEntityName.adminSettings);

  // Users
  canUserWithPermissions(AuthorizationActionType.read, AuthorizationEntityName.users);
  canUserWithPermissions(AuthorizationActionType.create, AuthorizationEntityName.users);
  canUserWithPermissions(AuthorizationActionType.update, AuthorizationEntityName.users);
  canUserWithPermissions(AuthorizationActionType.delete, AuthorizationEntityName.users);
  canUserWithPermissions(AuthorizationActionType.readList, AuthorizationEntityName.users);

  // Roles
  canUserWithPermissions(AuthorizationActionType.read, AuthorizationEntityName.roles);
  canUserWithPermissions(AuthorizationActionType.create, AuthorizationEntityName.roles);
  canUserWithPermissions(AuthorizationActionType.update, AuthorizationEntityName.roles);
  canUserWithPermissions(AuthorizationActionType.delete, AuthorizationEntityName.roles);
  canUserWithPermissions(AuthorizationActionType.readList, AuthorizationEntityName.roles);

  // Structure
  canUserWithPermissions(AuthorizationActionType.read, AuthorizationEntityName.structure);
  canUserWithPermissions(AuthorizationActionType.create, AuthorizationEntityName.structure);
  canUserWithPermissions(AuthorizationActionType.update, AuthorizationEntityName.structure);
  canUserWithPermissions(AuthorizationActionType.delete, AuthorizationEntityName.structure);
  canUserWithPermissions(AuthorizationActionType.readList, AuthorizationEntityName.structure);

  // Topbar Switchers
  canUserWithPermissions(
    AuthorizationActionType.read,
    AuthorizationEntityName.organizationSwitcher,
  );
  canUserWithPermissions(AuthorizationActionType.read, AuthorizationEntityName.groupSwitcher);

  return new Ability(rules);
};
