import React, { FC, useState } from "react";
import {
  useSubmit,
  useDidUpdate,
  useFormValues,
  Form,
  FormButtons,
  Button,
} from "@epcnetwork/core-ui-kit";

import { getClients, postClient } from "api";
import { ClientModel } from "models";
import { ClientsListProps } from "./clients.types";
import { initialValues } from "./clients.constants";
import FormTableRow from "./form-table-row/form-table-row";

import styles from "./clients.module.css";

const ClientsList: FC<ClientsListProps> = ({ groupId }: ClientsListProps) => {
  const [clientList, setClientList] = useState<ClientModel[]>([]);

  const { formProps, mapInitialValues } = useFormValues(
    initialValues,
    getClients.setQueryParams(`?groupId=${groupId}`),
    !!groupId,
  );

  mapInitialValues((payload) => {
    setClientList(payload.data);

    return payload;
  });

  const { onSubmit } = useSubmit(postClient);

  useDidUpdate(() => {
    if (!groupId) return null;
  }, [groupId]);

  return (
    <div className={styles.container}>
      <Form enableReinitialize validationSchema={null} onSubmit={onSubmit} {...formProps}>
        <div>
          {clientList.map((item: ClientModel) => (
            <FormTableRow key={item.id} item={item} />
          ))}
        </div>
        <FormButtons className={styles.formButtons} align="left" disabled>
          <Button type="submit">Save Changes</Button>
        </FormButtons>
      </Form>
    </div>
  );
};

export default ClientsList;
