import React, { FC, useState } from "react";
import { Container, Tabs } from "@epcnetwork/core-ui-kit";

import OrganizationsDetailsPage from "pages/settings/organizations/details/organizations-details.page";
import GroupsListPage from "pages/settings/groups/list/groups-list.page";

const StructureListPage: FC = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleChangeTab = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const tabs: { name: string; component: JSX.Element }[] = [
    {
      name: "Organization",
      component: <OrganizationsDetailsPage />,
    },
    {
      name: "Groups",
      component: <GroupsListPage />,
    },
  ];

  const tabsComponents = tabs.map((tab) => ({
    tab: { name: `${tab.name}` },
    tabComponent: tab.component,
  }));
  return (
    <Container>
      <div>
        <Tabs
          tabs={tabsComponents}
          activeTab={activeTab}
          onTabClick={handleChangeTab}
          maxDesktopWidth={700}
        />
      </div>
    </Container>
  );
};

export default StructureListPage;
