export const LOGIN_PAGE = {
  path: "/login",
  name: "Login",
  exact: false,
  auth: false,
  showNavigation: false,
};
export const LOGOUT_PAGE = {
  path: "/logout",
  name: "Logout",
  exact: false,
  auth: false,
  showNavigation: false,
};
export const FORGOT_PASSWORD_PAGE = {
  path: "/forgot-password",
  name: "Forgot Password",
  exact: true,
  auth: false,
  showNavigation: false,
};
export const RESET_PASSWORD_PAGE = {
  path: "/reset-password",
  name: "Reset Password",
  exact: true,
  auth: false,
  showNavigation: false,
};
export const REGISTRATION_PAGE = {
  path: "/registration",
  name: "Registration",
  exact: true,
  auth: false,
  showNavigation: false,
};

export const DASHBOARD_PAGE = {
  path: "/dashboard",
  name: "Dashboard",
  exact: false,
  auth: true,
  showNavigation: true,
};
export const USERS_LIST_PAGE = {
  path: "/users",
  name: "Users",
  exact: true,
  auth: true,
  showNavigation: true,
};
export const ROLES_LIST_PAGE = {
  path: "/roles",
  name: "Roles",
  exact: true,
  auth: true,
  showNavigation: true,
};
export const STRUCTURE_LIST_PAGE = {
  path: "/structure",
  name: "Structure",
  exact: true,
  auth: true,
  showNavigation: true,
};
export const MY_ACCOUNT_PAGE = {
  path: "/account/me",
  name: "My account",
  exact: false,
  auth: true,
  showNavigation: true,
};
export const SECRETS_PAGE = {
  path: "/secrets",
  name: "Secrets",
  exact: true,
  auth: true,
  showNavigation: true,
};
export const EXTERNAL_VENDORS_PAGE = {
  path: "/external-vendors",
  name: "External Vendors API",
  exact: true,
  auth: true,
  showNavigation: true,
};
export const CLIENTS_LIST_PAGE = {
  path: "/clients",
  name: "Clients",
  exact: true,
  auth: true,
  showNavigation: true,
};

export const CLIENTS_CAMPAIGNS_LIST = {
  path: "/clients/:id/campaigns",
  name: "Client Name",
  exact: false,
  auth: true,
  showNavigation: true,
};

export const CAMPAIGNS_LIST_PAGE = {
  path: "/campaigns",
  name: "Campaigns",
  exact: true,
  auth: true,
  showNavigation: true,
};

export const CAMPAIGNS_DETAILS_PAGE = {
  path: "/campaigns/:id",
  name: "Campaigns",
  exact: false,
  auth: true,
  showNavigation: true,
};

export const SOURCES_LIST_PAGE = {
  path: "/sources",
  name: "Sources",
  exact: true,
  auth: true,
  showNavigation: true,
};

export const SOURCES_DETAILS_PAGE = {
  path: "/sources/:id",
  name: "Sources",
  exact: false,
  auth: true,
  showNavigation: true,
};

export const ORGANIZATION_LIST_PAGE = {
  path: "/organizations",
  name: "Organization list",
  exact: true,
  auth: true,
  showNavigation: true,
};

export const DEFAULT_SUPER_ADMIN_PAGE = { ...ORGANIZATION_LIST_PAGE, path: "/", exact: true };
export const DEFAULT_PAGE = { ...CLIENTS_LIST_PAGE, path: "/", exact: true };
export const NOT_FOUND_PAGE = {
  path: "*",
  name: "Not Found",
  exact: false,
  auth: true,
  showNavigation: true,
};
