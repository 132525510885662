import React, { FC, memo } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

import { CampaignsTypesKeys } from "models";

import styles from "./campaign-chart.module.css";

interface ChartData {
  name: CampaignsTypesKeys;
  interactionsCount: number;
}

interface Props {
  chartData: ChartData[];
}

const CampaignChart: FC<Props> = ({ chartData }: Props) => {
  return (
    <ResponsiveContainer className={styles.container}>
      <PieChart>
        <Pie
          data={chartData}
          innerRadius={40}
          outerRadius={55}
          paddingAngle={5}
          dataKey="interactionsCount"
          cornerRadius="10"
        >
          {chartData.map(({ name }) => (
            <Cell key={`cell-${name}`} className={styles[name]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default memo(CampaignChart);
