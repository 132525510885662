import { appEnvironment } from "config/environment.config";
import { ApiBuilder } from "@epcnetwork/core-ui-kit";
import { LoginResponse } from "api/auth/auth.interface";

import store from "store";

export const apiRefreshEndpoint = "/auth/token";

export const refreshTokenMiddleware = new ApiBuilder({ baseUrl: appEnvironment.apiUrl })
  .setTokenGetter(() => {
    const state = store.getState();
    return state.auth.token;
  })
  .build();

export const postRefreshToken = refreshTokenMiddleware<
  LoginResponse,
  Omit<LoginResponse, "token">
>()({
  endpoint: apiRefreshEndpoint,
  method: "post",
});
