import React, { FC } from "react";
import { useFormikContext } from "formik";
import { FormField, useDidMount } from "@epcnetwork/core-ui-kit";

import { InitialValues, OntraportType } from "../secrets-form.types";

const Ontraport: FC = () => {
  const { values, setFieldValue } = useFormikContext<InitialValues<OntraportType>>();

  useDidMount(() => {
    const ontraportValues = values.apiTypeContent;
    !ontraportValues?.apiKey && setFieldValue("apiTypeContent.apiKey", "");
    !ontraportValues?.appId && setFieldValue("apiTypeContent.appId", "");
  });

  return (
    <>
      <FormField type="text" name="apiTypeContent.apiKey" label="API KEY" placeholder="" required />
      <FormField type="text" name="apiTypeContent.appId" label="APP ID" placeholder="" required />
    </>
  );
};

export default Ontraport;
