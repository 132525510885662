import React, { FC, memo, useRef } from "react";
import cn from "classnames";
import {
  uniqBy,
  addClassesToElement,
  removeClassesFromElement,
  useWindowEvent,
} from "@epcnetwork/core-ui-kit";

import { GanttGroupType, GanttItemLegendType } from "../gantt-timeline.types";

import styles from "./gantt-legend.module.css";

export interface GanttLegendProps {
  data: GanttGroupType[];
}

const GanttLegend: FC<GanttLegendProps> = memo(({ data }: GanttLegendProps) => {
  const legendRef = useRef<HTMLDivElement>(null);

  useWindowEvent(
    "scroll",
    () => {
      if (!legendRef.current) return removeClassesFromElement(legendRef.current, styles.scrolled);

      const { offsetTop, clientHeight, parentElement } = legendRef.current;

      const isScrolled = parentElement && parentElement?.scrollHeight > offsetTop + clientHeight;

      if (isScrolled) {
        addClassesToElement(legendRef.current, styles.scrolled);
      } else {
        removeClassesFromElement(legendRef.current, styles.scrolled);
      }
    },
    { passive: true },
  );

  const allItems = data.flatMap((group) => group.items);
  const allLegends = allItems.map((item) => item.legend).filter(Boolean) as GanttItemLegendType[];

  const namesUniq = uniqBy(allLegends, "name");
  const colorUniqLegends = uniqBy(allLegends, "color");

  const hasDefaultColor = colorUniqLegends.length !== namesUniq.length;

  if (!colorUniqLegends.length) return null;

  return (
    <div className={styles.legend} ref={legendRef}>
      {colorUniqLegends.map(({ name, color }) => (
        <div key={color} className={styles.legendItemWrapper}>
          <div className={styles.colorBlock} style={{ backgroundColor: color }} />
          <span>{name}</span>
        </div>
      ))}
      {hasDefaultColor && (
        <div className={styles.legendItemWrapper}>
          <div className={cn(styles.colorBlock, styles.defaultLegendColor)} />
          <span>not defined</span>
        </div>
      )}
    </div>
  );
});

export default GanttLegend;
