import { List } from "@epcnetwork/core-ui-kit";

import { apiMiddleware } from "../api.middleware";
import { SecretModel, SecretProvider } from "models";
import { PostSecretData } from "./secrets.interface";

export const getSecrets = apiMiddleware<List<SecretModel>>()({
  method: "get",
  endpoint: "/secret",
});

export const getSecret = apiMiddleware<SecretModel>()({
  method: "get",
  endpoint: `/secret/:secretId`,
});

export const deleteSecret = apiMiddleware()({
  method: "delete",
  endpoint: `/secret/:secretId`,
});

export const getSecretProviderList = apiMiddleware<SecretProvider[]>()({
  method: "get",
  endpoint: "/secret/types/list",
});

export const postSecret = apiMiddleware<SecretModel, PostSecretData>()({
  method: "post",
  endpoint: "/secret",
});

export const putSecret = apiMiddleware<SecretModel, PostSecretData>()({
  method: "put",
  endpoint: `/secret/:secretId`,
});
