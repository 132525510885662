import React, { FC } from "react";
import { useFormikContext } from "formik";
import { FormField, useDidMount } from "@epcnetwork/core-ui-kit";

import { InitialValues, IterableType } from "../secrets-form.types";

const Iterable: FC = () => {
  const { values, setFieldValue } = useFormikContext<InitialValues<IterableType>>();

  useDidMount(() => {
    const iterableValues = values.apiTypeContent;
    !iterableValues?.apiKey && setFieldValue("apiTypeContent.apiKey", "");
  });
  return (
    <>
      <FormField type="text" name="apiTypeContent.apiKey" label="API KEY" placeholder="" required />
    </>
  );
};

export default Iterable;
