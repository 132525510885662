import { Link } from "react-router-dom";
import React, { FC, ReactNode } from "react";
import cn from "classnames";

import styles from "../breadcrumbs.module.css";

type LinkProps = {
  name: string;
  path: string;
  className?: string;
  classNameLink?: string;
  children?: ReactNode;
};

const LinkCrumb: FC<LinkProps> = ({
  name,
  path,
  className,
  classNameLink,
  children,
}: LinkProps) => {
  const linkCrumbStyles = cn(styles.li, className);
  const linkStyles = cn(styles.link, classNameLink);

  return (
    <li className={linkCrumbStyles}>
      <Link className={linkStyles} to={path}>
        {name.toLowerCase()}
      </Link>
      {children}
    </li>
  );
};

export default LinkCrumb;
