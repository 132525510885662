import React, { FC } from "react";
import { object, SchemaOf, string } from "yup";
import { useFormikContext } from "formik";
import { Button, Label, useDidMount, useWillUnmount } from "@epcnetwork/core-ui-kit";

import { UrlParamType } from "pages/external-vendors/form/vendors-form.types";
import UrlParameterInput from "./url-parameter-input";
import { RestApiType } from "../../vendors-form.types";

import styles from "../api-type.module.css";

export const paramsSchema: SchemaOf<UrlParamType> = object().shape({
  key: string().required("Key is required"),
  value: string().required("Value is required"),
});

type Props = {
  btnName: string;
  prefix: "queryParams" | "bodyParams";
  noLabel?: boolean;
};

export const createInitialUrlParameter = (): ConcatArray<UrlParamType> => [{ key: "", value: "" }];

const UrlParameter: FC<Props> = ({ btnName, prefix, noLabel }: Props) => {
  const { values, setFieldValue } = useFormikContext<RestApiType>();

  const params = values?.[prefix] || [];

  const paramsArray: UrlParamType[] = params ?? [];

  const handleDeleteParameter = (index: number) => () =>
    setFieldValue(
      prefix,
      paramsArray.filter((_, i) => i !== index),
    );

  const handleAddUrlParameter = () =>
    setFieldValue(prefix, paramsArray.concat(createInitialUrlParameter()));

  useDidMount(() => {
    setFieldValue(prefix, createInitialUrlParameter());
  });

  useWillUnmount(() => {
    setFieldValue(prefix, null);
  });

  return (
    <>
      {!noLabel && <Label text="URL PARAMETER" isRequired floating={false} />}
      {paramsArray.map((_, index) => {
        return (
          <UrlParameterInput
            key={index}
            index={index}
            prefix={prefix}
            canDelete={paramsArray.length > 1}
            handleDelete={handleDeleteParameter(index)}
          />
        );
      })}
      <Button
        type="button"
        appearance="secondary"
        onClick={handleAddUrlParameter}
        className={styles.addUrlBtn}
      >
        {btnName}
      </Button>
    </>
  );
};

export default UrlParameter;
