import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormikHelpers } from "formik";
import { useDidMount, useSubmit, useQuery, useCall } from "@epcnetwork/core-ui-kit";

import { login, getMyProfile } from "api";
import { setToken, setUser, RootState } from "store";
import { TOKEN_STORAGE_FIELD, REFRESH_TOKEN_STORAGE_FIELD } from "constants/auth.constants";
import { DEFAULT_PAGE, FORGOT_PASSWORD_PAGE } from "constants/routes.constants";
import { Values } from "./login.types";

interface Output {
  onSubmit: (values: Values, helpers: FormikHelpers<Values>) => Promise<void>;
  handleForgotPassword: VoidFunction;
  errorMessage: string;
  hasPassChanged: boolean;
  hasAccCreated: boolean;
}

const useLoginHook = (): Output => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const token = localStorage.getItem(TOKEN_STORAGE_FIELD);

  const history = useHistory();
  const { query, setQueryParams } = useQuery();

  const [hasPassChanged, setHasPassChanged] = useState(false);
  const [hasAccCreated, setHasAccCreated] = useState(false);

  useDidMount(() => {
    if (token && user) {
      history.push(DEFAULT_PAGE.path);
    }
    setHasPassChanged(query.changed === "true");
    setHasAccCreated(query.created === "true");
    setQueryParams({});
  });

  const { submit, onCallSuccess } = useCall(getMyProfile);

  const { onSubmit, onSubmitSuccess, error } = useSubmit(login);

  onSubmitSuccess((payload) => {
    dispatch(setToken(payload.token));
    localStorage.setItem(TOKEN_STORAGE_FIELD, payload.token);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_FIELD, payload.refreshToken);
    submit();
  });

  onCallSuccess((payload) => {
    dispatch(setUser(payload));
    history.push(DEFAULT_PAGE.path);
  });

  const handleForgotPassword = () => history.push(FORGOT_PASSWORD_PAGE.path);

  const errorMessage = error?.message || "";

  return {
    onSubmit,
    handleForgotPassword,
    errorMessage,
    hasPassChanged,
    hasAccCreated,
  };
};

export default useLoginHook;
