import React, { FC, useState, useCallback } from "react";
import classnames from "classnames";
import {
  Filters,
  Select,
  DateInput,
  CollapseSelfControlled,
  Button,
} from "@epcnetwork/core-ui-kit";

import { CampaignsTypes, CampaignsTypesKeys } from "models";
import { appTimezone } from "constants/user.constants";
import { initialCollapseState, initialFilters } from "../media-buy-list.constants";
import { CollapseState } from "../media-buy-list.types";

import styles from "./list-filters.module.css";
import arrowDownImg from "assets/images/arrow-down.svg";

const ListFilters: FC = () => {
  const types: Array<{ value: CampaignsTypesKeys; label: string }> = Object.entries(
    CampaignsTypes,
  ).map(([key, value]) => ({
    value: key as CampaignsTypesKeys,
    label: value.toUpperCase(),
  }));

  const [isCollapseState, setIsCollapseState] = useState<CollapseState>(initialCollapseState);

  const handleCollapseView = useCallback(
    (key: string) => {
      const stateCollapse: CollapseState = { ...isCollapseState };
      stateCollapse[key].isOpen = !stateCollapse[key].isOpen;
      setIsCollapseState(stateCollapse);
    },
    [isCollapseState],
  );

  const headerCollapse = (key: string) => {
    let label = key;

    if (key === "createdAt") {
      label = "created date";
    }
    if (key === "updatedAt") {
      label = "updated date";
    }
    if (key === "sourceId") {
      label === "source";
    }

    return (
      <Button
        className={styles.collapseWrapper}
        appearance="text"
        onClick={() => handleCollapseView(key)}
      >
        <div className={styles.leftPart}>
          <img
            src={arrowDownImg}
            alt=""
            className={classnames({ [styles.revertArrow]: isCollapseState[key].isOpen })}
          />
          <div className={styles.label}>{`By ${label}`}</div>
        </div>
      </Button>
    );
  };

  return (
    <Filters initialFilters={initialFilters}>
      {({ getDatePickerDate, setDatePickerDate, getSelect, setSelect }) => {
        const [startFrom, startTo] = getDatePickerDate("createdAt");
        const [endFrom, endTo] = getDatePickerDate("updatedAt");

        return (
          <div>
            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={headerCollapse("type")}
                initiallyOpened={isCollapseState["type"].isOpen}
                removeFromDOMOnClosed
                triggerClassName={styles.triggerElement}
              >
                <Select
                  selectedOptionsKeys={getSelect("type", true)}
                  onChange={setSelect("type", true)}
                  isMulti
                  options={types}
                  placeholder="Select type"
                  disableError
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>

            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={headerCollapse("createdAt")}
                initiallyOpened={isCollapseState["createdAt"].isOpen}
                removeFromDOMOnClosed
                triggerClassName={styles.triggerElement}
              >
                <DateInput
                  className={styles.inputDate}
                  dateFrom={startFrom}
                  dateTo={startTo}
                  disableError
                  onChange={setDatePickerDate("createdAt", true)}
                  isMulti
                  timezone={appTimezone}
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>

            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={headerCollapse("updatedAt")}
                initiallyOpened={isCollapseState["updatedAt"].isOpen}
                removeFromDOMOnClosed
                triggerClassName={styles.triggerElement}
              >
                <DateInput
                  className={styles.inputDate}
                  dateFrom={endFrom}
                  dateTo={endTo}
                  disableError
                  onChange={setDatePickerDate("updatedAt", true)}
                  isMulti
                  timezone={appTimezone}
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>
          </div>
        );
      }}
    </Filters>
  );
};

export default ListFilters;
