import { HttpMethods, ContentTypes } from "constants/forms.constants";
import { SecretModel, SecretsAPITypesKeys, SecretsAPITypes } from "./secrets.models";

export type ExternalVendorsProvider = {
  key: SecretsAPITypesKeys;
  value: typeof SecretsAPITypes[SecretsAPITypesKeys];
};

export enum ExternalVendorsMethodTypes {
  get = "GET",
  put = "PUT",
  post = "POST",
}

export type ExternalVendorsMethodTypesKeys = keyof typeof ExternalVendorsMethodTypes;

export type ExternalVendorsModel = {
  name: string;
  apiType: SecretsAPITypesKeys;
  secretId: string;
  secret: SecretModel;
  url?: string;
  httpMethod?: HttpMethods;
  contentType?: ContentTypes;
  list?: { id: string; name: string };
  createdAt: string;
  id: string;
  status?: "success" | "failed" | "not started";
  updatedAt: string;
  soapBody?: Record<string, string>;
  bodyParams?: Record<string, string>;
  queryParams?: Record<string, string>;
};

export enum ExternalVendorStatusTypes {
  success = "Vendor successfully validated",
  failed = "Failed to validate Vendor",
  notStarted = "Vendor haven’t been tested yet",
}

export type ExternalVendorsSubscriptionList = {
  customerKey: string;
  id: string;
  name: string;
};
