import React, { FC } from "react";
import { FormField, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { ClientModel } from "models";

import styles from "./form-table-row.module.css";

type FormTableRow = {
  item: ClientModel;
};

const FormTableRow: FC<FormTableRow> = ({ item }: FormTableRow) => {
  return (
    <div className={styles.row}>
      <FormField type="checkbox" name={item.id} className={styles.checkbox} disableError />
      <TextEllipsis
        tooltipTrigger="focus"
        className={styles.ellipsis}
        tooltipPosition="bottom-left"
      >
        <div>{item.name}</div>
      </TextEllipsis>
    </div>
  );
};

export default FormTableRow;
