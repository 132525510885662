import React, { FC } from "react";
import { useLocation } from "react-router";
import { Breadcrumbs as CoreBreadcrumbs, Crumb } from "components";

import { CLIENTS_LIST_PAGE } from "constants/routes.constants";

const Breadcrumbs: FC = () => {
  const location = useLocation();
  const { pathname } = location;

  if (pathname !== CLIENTS_LIST_PAGE.path) return null;
  return (
    <CoreBreadcrumbs>
      <Crumb appearance="text" name={CLIENTS_LIST_PAGE.name} />
    </CoreBreadcrumbs>
  );
};

export default Breadcrumbs;
