import React, { FC, memo, useContext, useRef } from "react";
import cn from "classnames";
import {
  useDidMount,
  TextEllipsis,
  lerp,
  normalize,
  applyCSSToElement,
  addClassesToElement,
  removeClassesFromElement,
} from "@epcnetwork/core-ui-kit";

import { GANTT_ITEM_MIN_WIDTH, GanttTimelineContext } from "../gantt-timeline.constants";

import globalStyles from "assets/styles/global.module.css";
import styles from "../gantt-timeline.module.css";

export interface GanttItemProps {
  title: string;
  description: string;
  visibleFrom: number;
  visibleTo: number;
  startDate: number;
  endDate: number;
  color?: string;
}

const GanttItem: FC<GanttItemProps> = memo(
  ({ title, description, visibleFrom, visibleTo, startDate, endDate, color }: GanttItemProps) => {
    const { timePeriodsWidth } = useContext(GanttTimelineContext);

    const itemWrapRef = useRef<HTMLDivElement>(null);
    const itemRef = useRef<HTMLDivElement>(null);

    const handleCalculations = () => {
      const wrapper = itemWrapRef.current;
      if (!wrapper) return;

      const { width } = wrapper.getBoundingClientRect();

      const normalizedStart = normalize(startDate, visibleFrom, visibleTo);
      const normalizedEnd = normalize(endDate, visibleFrom, visibleTo);
      const diff = normalizedEnd - normalizedStart;

      const wrapperWidth = Math.max(width, timePeriodsWidth);

      const widthToSet = Math.max(lerp(0, wrapperWidth, diff), GANTT_ITEM_MIN_WIDTH);

      if (widthToSet > 100) {
        removeClassesFromElement(itemRef.current, styles.hideContent);
      } else {
        addClassesToElement(itemRef.current, styles.hideContent);
      }

      const left = lerp(0, wrapperWidth, normalizedStart);

      applyCSSToElement(itemRef.current, {
        left: left + "px",
        minWidth: widthToSet + "px",
        maxWidth: widthToSet + "px",
      });
    };

    handleCalculations();

    // Repeat on mount, because of DOM refs
    useDidMount(() => {
      removeClassesFromElement(itemRef.current, globalStyles.deleteElement);
      handleCalculations();
    });

    return (
      <div className={styles.itemWrap} ref={itemWrapRef}>
        <div
          className={cn(styles.item, globalStyles.deleteElement)}
          ref={itemRef}
          style={{ backgroundColor: color }}
        >
          <div className={styles.itemTitle}>
            <TextEllipsis tooltipTrigger="hover" tooltipPosition="top-left">
              {title}
            </TextEllipsis>
          </div>
          <div className={styles.itemTitle}>
            <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
              {description}
            </TextEllipsis>
          </div>
        </div>
      </div>
    );
  },
);

export default GanttItem;
