import React, { FC, useRef } from "react";
import { FormikProps } from "formik";
import {
  Button,
  MessageField,
  Form,
  FormButtons,
  FormField,
  NotFound,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import useRegistrationHook from "./registration.hook";
import { initialValues, validationSchema } from "./registration.constants";

import logo from "assets/images/logo.svg";
import styles from "./registration.module.css";

const Registration: FC = () => {
  const { onSubmit, errorMessage, isLinkExpired, userEmail } = useRegistrationHook();

  const formRef = useRef<FormikProps<typeof initialValues>>(null);

  useDidUpdate(() => {
    formRef.current?.setFieldValue("email", userEmail);
  }, [userEmail]);

  return (
    <>
      {isLinkExpired ? (
        <div className={styles.notFound}>
          <NotFound
            size="big"
            title="The registration link has expired"
            subtitle="Please contact the administrator"
            noButton
          />
          ;
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.logo}>
            <img className={styles.icon} src={logo} />
            <div className={styles.logoText}>ClicksUA</div>
            <div className={styles.leafBackground} />
          </div>
          <div className={styles.loginForm}>
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              innerRef={formRef}
            >
              <div className={styles.title}>Registration</div>
              <MessageField message={errorMessage} align="left" />
              <FormField
                type="text"
                name="email"
                label="Email"
                placeholder="Fill your email"
                required
                disabled
              />
              <FormField
                type="text"
                name="name"
                label="First Name"
                placeholder="Fill your first name"
                required
              />
              <FormField
                type="text"
                name="lastname"
                label="Last Name"
                placeholder="Fill your last name"
                required
              />
              <FormField
                type="generate-password"
                name="password"
                label="Password"
                placeholder="Fill your password"
                required
              />
              <FormButtons align="right">
                <Button className={styles.btn} type="submit">
                  Send
                </Button>
              </FormButtons>
            </Form>
          </div>
          <div className={styles.info}>
            <div className={styles.option}>&#169;2021 ClicksUA</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Registration;
