import React, { FC } from "react";

import { CrumbSeparator, Breadcrumbs as CoreBreadcrumbs, Crumb } from "components";

import { SOURCES_LIST_PAGE } from "constants/routes.constants";

type BreadcrumbsProps = {
  name?: string;
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ name }: BreadcrumbsProps) => {
  return (
    <CoreBreadcrumbs>
      <Crumb appearance="link" path={SOURCES_LIST_PAGE.path} name={SOURCES_LIST_PAGE.name} />
      {name && (
        <>
          <CrumbSeparator />
          <Crumb appearance="text" name={name} />
        </>
      )}
    </CoreBreadcrumbs>
  );
};

export default Breadcrumbs;
