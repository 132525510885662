import React, { FC } from "react";
import { Form, FormField, Button, MessageField } from "@epcnetwork/core-ui-kit";

import SecretsAPIDispatcher from "./api-type-components/secrets-api-dispatcher";
import { validationSchema } from "./secrets-form.constants";
import useSecretsFormHook from "./secrets-form.hook";

import styles from "./secrets-form.module.css";
import closeImg from "assets/images/close-secrets.svg";

type Props = {
  onCloseModal: () => void;
  id: string;
  refresh: () => void;
};

const SecretsForm: FC<Props> = ({ onCloseModal, id = "", refresh }: Props) => {
  const { formProps, providersAutocomplete, error, onSubmit, clearErrorMessage } =
    useSecretsFormHook(onCloseModal, id, refresh);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={closeImg} alt="" onClick={onCloseModal} />
      </div>
      <div className={styles.body}>
        <div className={styles.name}>
          {id ? `Edit: ${formProps.initialValues?.secretName || "Secret"}` : `New Secret`}
        </div>
        <MessageField message={error} className={styles.errorMsg} />
        <Form {...formProps} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ values }) => {
            const { apiType } = values;
            return (
              <>
                <FormField
                  type="text"
                  name="secretName"
                  label="Name"
                  placeholder="Fill the name"
                  required
                />
                <FormField
                  name="apiType"
                  type="select"
                  label="Choose provider"
                  placeholder="Choose provider"
                  asyncOptions={providersAutocomplete}
                  disabled={!!id}
                  disableClearing
                  required
                  onChange={clearErrorMessage}
                />
                {apiType && <SecretsAPIDispatcher apiType={apiType} />}
                <div className={styles.buttonWrapper}>
                  <Button
                    className={styles.cancelButton}
                    type="button"
                    appearance="secondary"
                    onClick={onCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button type="submit">{id ? "Save" : "Add"}</Button>
                </div>
              </>
            );
          }}
        </Form>
      </div>
    </div>
  );
};

export default SecretsForm;
