import React, { FC, ReactNode } from "react";
import cn from "classnames";

import styles from "./hint.module.css";

type HintProps = {
  children: ReactNode;
  type?: "success" | "warning" | "error";
  className?: string;
};

export const Hint: FC<HintProps> = ({ children, type = "warning", className }: HintProps) => {
  const typeStyles = cn(styles[type], styles.hintWrapper, className);

  return (
    <div className={typeStyles}>
      <div className={styles.borderWrapper} />
      <span className={styles.hint}>{children}</span>
    </div>
  );
};

export default Hint;
