import { date, object, string } from "yup";

import { InitialValues } from "./campaigns-form.types";

export const initialValues: InitialValues = {
  clientId: "",
  name: "",
  startDate: null,
  endDate: null,
  comments: "",
};

export const validationSchema = object().shape({
  clientId: string()
    .min(1, "This field is required")
    .typeError("This field is required")
    .required("This field is required"),
  name: string().min(3, "Name must have minimum 3 characters").required("This field is required"),
  startDate: date().required("This field is required").nullable(),
  endDate: date().required("These fields are required").nullable(),
});

export const PLACEHOLDER_COMMENTS_FIELD =
  "Feel free to add any information related to the Campaign" as const;

export const PLACEHOLDER_CLIENTS = "Choose client from the list" as const;
