import React, { FC } from "react";
import { Container, Search, Table, Tabs, Button, Modal } from "@epcnetwork/core-ui-kit";

import ListFilters from "./list-filters";
import useCampaignsListHook from "./campaigns-list.hook";
import { TABLE_COLUMNS, entityName } from "./campaigns-list.constants";
import TableRow from "./table-row";
import CampaignsForm from "../form";
import Breadcrumbs from "./breadcrumbs/breadcrumbs";
import GanttChart from "./gantt-chart/gantt-chart";

import styles from "./campaigns-list.module.css";
import chartImg from "assets/images/bar-chart.svg";

const CampaignsListPage: FC = () => {
  const {
    error,
    loading,
    refresh,
    searchValue,
    setSearch,
    handleAddCampaign,
    handleEdit,
    tabs,
    activeTab,
    handleChangeTab,
    isCampaignPage,
    isOpenCreateEditModal,
    handleCloseAddEditModal,
    editId,
    campaignId,
    pagination,
    handleDuplicateClick,
    isDuplication,
    isInGanttChartView,
    handleTriggerChart,
    isMobileDevice,
  } = useCampaignsListHook();

  const tabsComponents = tabs.map((tab) => ({
    tab: { name: `${tab.name} (${tab.count})` },
    tabComponent: (
      <Table
        className={styles.table}
        entityName={entityName}
        columns={TABLE_COLUMNS(isMobileDevice)}
        list={tab.list}
        loading={loading}
        error={error}
        refresh={refresh}
        pagination={pagination}
        showIndicators={true}
        row={(item) => (
          <TableRow
            key={item.id}
            item={item}
            handleEdit={handleEdit}
            refresh={refresh}
            handleDuplicateClick={handleDuplicateClick}
          />
        )}
        isTabTable
      />
    ),
  }));

  return (
    <>
      <Breadcrumbs />
      <Container contentClassName={styles.containerWrap}>
        <div className={styles.headerWrapper}>
          <div className={styles.searchHeaderWrapper}>
            <div className={styles.searchFormContainer}>
              <Search
                className={styles.search}
                searchValue={searchValue}
                setSearch={setSearch}
                width="small"
              />
              {!isMobileDevice && (
                <Button
                  className={styles.chartButton}
                  appearance="text"
                  btnSize="small"
                  onClick={handleTriggerChart}
                >
                  <img src={chartImg} alt="" />
                </Button>
              )}
              {!isInGanttChartView && <ListFilters shouldShowFilter={isCampaignPage} />}
            </div>
          </div>
          {!isInGanttChartView && (
            <>
              <Button appearance="primary" onClick={handleAddCampaign} className={styles.addBtn}>
                + Add Campaign
              </Button>
              <Button
                appearance="primary"
                onClick={handleAddCampaign}
                className={styles.addSmallScreen}
              >
                + Add
              </Button>
            </>
          )}
        </div>
        {isInGanttChartView && <GanttChart />}
        {!isInGanttChartView && (
          <>
            <div className={styles.tabsWrapper}>
              <Tabs tabs={tabsComponents} activeTab={activeTab} onTabClick={handleChangeTab} />
            </div>
            <Modal
              isOpen={isOpenCreateEditModal}
              setClose={handleCloseAddEditModal}
              contentClassName={styles.modal}
            >
              <CampaignsForm
                handleCloseModal={handleCloseAddEditModal}
                editClientId={editId}
                campaignId={campaignId}
                isDuplication={isDuplication}
                refresh={refresh}
              />
            </Modal>
          </>
        )}
      </Container>
    </>
  );
};

export default CampaignsListPage;
