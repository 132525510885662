import { AnyObject } from "yup/lib/types";
import {
  AnyObjectSchema,
  AnySchema,
  array,
  number,
  object,
  string,
  TestContext,
  ValidationError,
} from "yup";

// Validations
export const optionalValidation =
  (condition: boolean, message: string) =>
  (_field: string, passSchema: AnyObject): AnyObjectSchema =>
    condition ? passSchema : passSchema.required(message);

export const jsonValidation = (value: string | undefined): boolean => {
  value = typeof value !== "string" ? JSON.stringify(value) : value;

  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  if (typeof value === "object" && value !== null) {
    return true;
  }

  return false;
};

export const fileValidation = (requiredMessage = "This field is required"): AnySchema => {
  return array()
    .of(object().typeError(requiredMessage).required(requiredMessage))
    .min(1, requiredMessage)
    .typeError(requiredMessage)
    .required(requiredMessage);
};

export const selectValidation = (requiredMessage = "This field is required"): AnySchema => {
  return object({
    label: array(string().min(1, requiredMessage).required(requiredMessage)),
    value: array(string().min(1, requiredMessage).required(requiredMessage)),
  })
    .typeError(requiredMessage)
    .required(requiredMessage);
};

export const selectNumberValidation = (requiredMessage = "This field is required"): AnyObject => {
  return object({
    label: string().min(1, requiredMessage).required(requiredMessage),
    value: number().min(1, requiredMessage).required(requiredMessage),
  })
    .typeError(requiredMessage)
    .required(requiredMessage);
};

export const selectMultipleValidation = (requiredMessage = "This field is required"): AnyObject => {
  return array()
    .of(
      object().shape({
        label: string().min(1, requiredMessage).required(requiredMessage),
        value: string().min(1, requiredMessage).required(requiredMessage),
      }),
    )
    .typeError(requiredMessage)
    .min(1, requiredMessage)
    .required(requiredMessage);
};

export function mergeSchemas(...schemas: AnySchema[]): AnyObject {
  const [first, ...rest] = schemas;

  return rest.reduce(
    (mergedSchemas: AnySchema, schema: AnySchema) => mergedSchemas.concat(schema),
    first,
  );
}

export const passwordValidation = (
  value: string | undefined,
  testContext: TestContext,
): ValidationError | boolean => {
  const password = value || "";

  const minLength = 8;
  const isRequiredLength = password.length >= minLength;
  if (!isRequiredLength) {
    return testContext.createError({ message: "Password needs to include at least 8 characters" });
  }
  const hasLowerCase = password.match(/[a-z]+/);
  const hasUpperCase = password.match(/[A-Z]+/);
  if (!hasLowerCase || !hasUpperCase) {
    return testContext.createError({
      message:
        "Password needs to include both upper and lowercase alphabetic characters (e.g. A-Z, a-z)",
    });
  }
  const hasNumber = password.match(/[0-9]+/);
  if (!hasNumber) {
    return testContext.createError({
      message: "Password needs to include at least one numerical character (e.g. 0-9)",
    });
  }
  const hasSpecialCharacter = password.match(/[_\W]+/);
  if (!hasSpecialCharacter) {
    return testContext.createError({
      message: "Password needs to include at least one special character (e.g. ~!@#$%^&*()_-+=)",
    });
  }
  return true;
};
