export const TOKEN_STORAGE_FIELD = "token";
export const REFRESH_TOKEN_STORAGE_FIELD = "refresh_token";

export const ME_ROLE = {
  super_admin: 100,
  user: 50,
};

export const MEMBER_ROLE = {
  super_admin_module: 1000, //super-admin module
  owner: 100,
  admin: 50,
  groupManager: 10,
  user: 5,
  trial: 2,
};

export const SUPER_ADMIN_OPTIONS = {
  value: "-1",
  label: "Super Admin Module",
  id: "-1",
  name: "Super Admin Module",
};
