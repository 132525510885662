import React, { FC } from "react";
import { useFormikContext } from "formik";
import { FormField, useDidMount } from "@epcnetwork/core-ui-kit";

import { GetResponseType, InitialValues } from "../secrets-form.types";

const GetResponse: FC = () => {
  const { values, setFieldValue } = useFormikContext<InitialValues<GetResponseType>>();

  useDidMount(() => {
    const getResponseValues = values.apiTypeContent;
    !getResponseValues?.apiKey && setFieldValue("apiTypeContent.apiKey", "");
  });

  return (
    <>
      <FormField type="text" name="apiTypeContent.apiKey" label="API KEY" placeholder="" required />
    </>
  );
};

export default GetResponse;
