import React, { FC, Fragment } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import {
  notification,
  formatDate,
  useDebounce,
  useCall,
  Button,
  TextEllipsis,
} from "@epcnetwork/core-ui-kit";

import { deleteCampaign, putCampaignStatus } from "api";
import { CampaignModel, CampaignsTypes, CampaignsTypesKeys, CampaignStatsModel } from "models";
import { linkTo, getDateWithTimezone } from "utils";
import CampaignChart from "../campaign-chart";
import { CAMPAIGNS_DETAILS_PAGE, CAMPAIGNS_LIST_PAGE } from "constants/routes.constants";

import styles from "./info-tabs.module.css";
import BracketArrowEndImg from "assets/images/bracket-arrow-end-grey.svg";
import BracketArrowStartImg from "assets/images/bracket-arrow-start-grey.svg";
import pencilImg from "assets/images/pencil-grey.svg";
import deleteImg from "assets/images/delete.svg";
import emptyChartImg from "assets/images/ellipse.svg";
import pauseImg from "assets/images/pause.svg";
import resumeImg from "assets/images/resume.svg";

type Props = {
  campaign: CampaignModel;
  handleEdit: (clientId: string, campaignId: string) => void;
  campaignStats: CampaignStatsModel[];
  setData: (data: CampaignModel) => void;
};

const InfoTabs: FC<Props> = ({ campaign, handleEdit, campaignStats, setData }: Props) => {
  const { client, name, id, allMediaBuysCount, status, startDate, endDate } = campaign;
  const history = useHistory();

  const { submit, onCallSuccess, onCallError } = useCall(deleteCampaign);

  const { submit: campaignStatusSubmit, onCallError: campaignStatusError } = useCall(
    putCampaignStatus.setParams({ campaignId: id }),
  );
  const { debounce } = useDebounce();

  const handleLinkTo = () => {
    return history.push(CAMPAIGNS_LIST_PAGE.path);
  };

  onCallSuccess(() => {
    notification.success("Campaign deleted", "Successfully deleted Campaign");
    handleLinkTo();
  });

  onCallError(() => {
    notification.error("Deleting error", "Cannot delete campaign");
  });

  const handleDeleteCampaign = () => {
    const message = allMediaBuysCount
      ? `Deleting Campaign "${name}" you automatically delete connected:`
      : "You will not be able to recover it";
    const link = linkTo(CAMPAIGNS_DETAILS_PAGE.path, id);
    const entities = allMediaBuysCount ? [{ text: `See all Media Buys`, link }] : [];

    notification.confirm("Delete Campaign ?", message, {
      onOk: () => submit({ params: { campaignId: id } }),
      okText: "Delete",
      icon: "delete",
      entities,
    });
  };

  const types: Array<{ name: CampaignsTypesKeys; value: number }> = Object.entries(
    CampaignsTypes,
  ).map(([key]) => {
    const currentStat = campaignStats?.find((stat) => stat.name === key);
    return {
      name: key as CampaignsTypesKeys,
      value: Number(currentStat?.interactionsCount) || 0,
    };
  });

  const handleEditCampaign = () => handleEdit(client.id, id);

  const handleChangeStatus = (status: "active" | "paused") => {
    const updatedCampaign = { ...campaign, status };
    setData(updatedCampaign);
    debounce(() => {
      campaignStatusSubmit({ data: { status } });
    });
  };

  campaignStatusError(() => {
    const oldCampaign = { ...campaign };
    setData(oldCampaign);
    notification.error(
      "Resume error",
      "Can not resume campaign with end date which is earlier than today",
    );
  });

  const isEmptyValues = campaignStats?.every(
    ({ interactionsCount }) => Number(interactionsCount) === 0,
  );

  const totalClicksCount = campaignStats?.reduce((acc, current) => {
    return acc + Number(current.interactionsCount);
  }, 0);

  const sortedTypes = types.sort((a, b) => b.value - a.value);
  const isDisableEdit = status === "completed";

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.row}>
          <div className={styles.title}>Name</div>
          <div className={styles.labelWrap}>
            <TextEllipsis
              tooltipTrigger="hover"
              tooltipPosition="bottom-left"
              lines={2}
              className={styles.label}
            >
              {name || "-"}
            </TextEllipsis>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>Owner</div>
          <div className={styles.labelWrap}>
            <TextEllipsis
              tooltipTrigger="hover"
              tooltipPosition="bottom-left"
              lines={2}
              className={styles.label}
            >
              {client?.name || "-"}
            </TextEllipsis>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>Media Buys</div>
          <div className={styles.labelWrap}>
            <div className={styles.label}>{allMediaBuysCount || 0}</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>Type</div>
          <div className={styles.typeContainer}>
            {campaignStats?.length ? (
              campaignStats.map(({ name }) => (
                <div key={name} className={classnames(styles[name?.toLowerCase()])}>
                  {name}
                </div>
              ))
            ) : (
              <div className={styles.emptyTypes}>-</div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.itemChart}>
        <div className={styles.chart}>
          {isEmptyValues || !campaignStats?.length ? (
            <div className={styles.emptyChart}>
              <img src={emptyChartImg} alt="" />
            </div>
          ) : (
            <CampaignChart chartData={campaignStats} />
          )}
          <div className={styles.totalSumWrapper}>
            <div className={styles.totalCount}>{totalClicksCount || 0}</div>
            <div className={styles.totalClick}>clicks</div>
          </div>
        </div>
        <div className={styles.statistic}>
          {sortedTypes.map(({ name, value }) => (
            <Fragment key={name}>
              <div key={name} className={styles.stubWrapper}>
                <div
                  className={classnames(styles[`${name.toLowerCase()}Stub`], styles.typeStub, {
                    [styles.emptyStub]: isEmptyValues,
                  })}
                />
                <div className={styles.typeValue}>{name}</div>
              </div>
              <div className={styles.counter}>{value} clicks</div>
            </Fragment>
          ))}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.row}>
          <div className={styles.titleRight}>Status</div>
          <div className={styles.rightLabel}>
            {status || "-"}
            {status === "active" ? (
              <Button
                btnSize="small"
                appearance="text"
                onClick={() => handleChangeStatus("paused")}
              >
                <img src={pauseImg} alt="" className={styles.statusIcon} />
                <span className={styles.status}>Pause</span>
              </Button>
            ) : status === "paused" ? (
              <Button
                btnSize="small"
                appearance="text"
                onClick={() => handleChangeStatus("active")}
              >
                <img src={resumeImg} className={styles.statusIcon} alt="" />
                <span className={styles.status}>Resume</span>
              </Button>
            ) : null}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.titleRight}>Start</div>
          <div className={styles.rightLabel}>
            <img src={BracketArrowStartImg} alt="" className={styles.icon} />
            {formatDate(getDateWithTimezone(startDate), "MMM dd, yyyy")}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.titleRight}>End</div>
          <div className={styles.rightLabel}>
            <img src={BracketArrowEndImg} alt="" className={styles.icon} />
            {formatDate(getDateWithTimezone(endDate), "MMM dd, yyyy")}
          </div>
        </div>
        <div className={styles.iconsWrapper}>
          <Button
            btnSize="small"
            appearance="text"
            className={styles.actionButton}
            onClick={handleEditCampaign}
            disabled={isDisableEdit}
          >
            <img src={pencilImg} alt="" className={styles.actionIcon} />
          </Button>
          <Button
            btnSize="small"
            appearance="text"
            className={styles.actionButton}
            onClick={handleDeleteCampaign}
          >
            <img src={deleteImg} alt="" className={styles.actionIcon} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InfoTabs;
