import React, { FC, useState } from "react";
import { useFetch, useDidUpdate, Loader, Tabs } from "@epcnetwork/core-ui-kit";

import { getGroups } from "api";
import { useShowContent, usePayload } from "hooks";
import { NoContent } from "components";
import { GroupModel } from "models";
import Sidebar from "./sidebar/sidebar";
import MembersList from "./members/members";
import InviteForm from "../form/invite-form.page";
import ClientsList from "./clients/clients";

import styles from "./groups-list.module.css";

const GroupsListPage: FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedGroup, setGroup] = useState<GroupModel | null>(null);

  const groupsResponse = useFetch(getGroups);
  const { showLoader } = useShowContent(groupsResponse);
  const { list, refresh } = usePayload(groupsResponse);

  useDidUpdate(() => {
    if (!selectedGroup) {
      setGroup(list[0]);
    }
  }, [list]);

  const handleSelectGroup = (group: GroupModel | null) => {
    setGroup(group);
  };

  const handleItemChange = (values: GroupModel) => {
    const filteredList = list.filter((listElem) => listElem.id !== values.id);
    const newData: GroupModel[] = [values, ...filteredList];
    groupsResponse.actions.setData(newData);
  };

  const handleChangeTab = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const tabs: { name: string; component: JSX.Element }[] = [
    {
      name: "Basic information",
      component: selectedGroup?.id ? (
        <MembersList groupId={selectedGroup.id} handleChangeTab={handleChangeTab} />
      ) : (
        <NoContent />
      ),
    },
    {
      name: "Invite User",
      component: <InviteForm id={selectedGroup?.id} handleChangeTab={handleChangeTab} />,
    },
    {
      name: "Client List",
      component: selectedGroup?.id ? <ClientsList groupId={selectedGroup.id} /> : <NoContent />,
    },
  ];

  const tabsComponents = tabs.map((tab) => ({
    tab: { name: `${tab.name}` },
    tabComponent: tab.component,
  }));

  if (showLoader) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <>
        <div className={styles.sidebar}>
          <Sidebar
            selected={selectedGroup}
            list={list}
            refresh={refresh}
            onSelect={handleSelectGroup}
            handleItemChange={handleItemChange}
          />
        </div>
        <div className={styles.content}>
          {!list.length || !selectedGroup ? (
            <NoContent />
          ) : (
            <Tabs
              tabs={tabsComponents}
              activeTab={activeTab}
              onTabClick={handleChangeTab}
              tabsClassName={styles.tabs}
              tabClassName={styles.tab}
              selectedTabClassName={styles.selectedTab}
              showMore={false}
              maxDesktopWidth={700}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default GroupsListPage;
