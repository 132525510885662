import React, { FC } from "react";
import { useFormikContext } from "formik";

import { RestApiType } from "pages/external-vendors/form/vendors-form.types";
import ApiPost from "./components/api-post";
import ApiPut from "./components/api.put";
import { HttpMethods } from "constants/forms.constants";

const RestApiDispatcher: FC = () => {
  const { values } = useFormikContext<RestApiType>();

  const api = values.httpMethod;

  switch (api) {
    case String(HttpMethods.post):
      return <ApiPost />;
    case String(HttpMethods.put):
      return <ApiPut />;
    default:
      return null;
  }
};

export default RestApiDispatcher;
