import React, { FC, memo, useRef } from "react";
import {
  useWindowEvent,
  addClassesToElement,
  removeClassesFromElement,
  getMonthName,
  getYear,
  getDayType,
} from "@epcnetwork/core-ui-kit";

import { SupportedViews } from "../gantt-timeline.types";

import styles from "../gantt-timeline.module.css";

export interface GanttCapProps {
  visibleFrom: number;
  visibleTo: number;
  viewType: SupportedViews;
}

const GanttCap: FC<GanttCapProps> = memo(({ visibleFrom, visibleTo, viewType }: GanttCapProps) => {
  const capRef = useRef<HTMLDivElement>(null);

  useWindowEvent(
    "scroll",
    () => {
      const isScrolled = Math.sign(window.scrollY - (capRef.current?.offsetTop || 0)) === 1;

      if (isScrolled) {
        addClassesToElement(capRef.current, styles.scrolled);
      } else {
        removeClassesFromElement(capRef.current, styles.scrolled);
      }
    },

    { passive: true },
  );

  const weekStartDayType = getDayType(visibleFrom);
  const weekEndDayType = getDayType(visibleTo);

  const capContent: Record<SupportedViews, JSX.Element> = {
    days: (
      <>
        <div>{getMonthName(visibleFrom)}</div>
        &nbsp;
        <div>{getYear(visibleFrom)}</div>
      </>
    ),
    weekdays: (
      <>
        <div>
          {weekStartDayType.month} {weekStartDayType.dayOfMonth}
        </div>
        &nbsp; - &nbsp;
        <div>
          {weekEndDayType.month} {weekEndDayType.dayOfMonth}
        </div>
      </>
    ),
    months: (
      <>
        <div>{getYear(visibleFrom)}</div>
      </>
    ),
    years: (
      <>
        <div>{getYear(visibleFrom)}</div>
        &nbsp; - &nbsp;
        <div>{getYear(visibleTo)}</div>
      </>
    ),
  };

  return (
    <div className={styles.cap} ref={capRef}>
      <div />
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {capContent[viewType]}
      </div>
      <div />
    </div>
  );
});

export default GanttCap;
