import React, { FC, useState } from "react";
import {
  TableRow,
  TableCell,
  Button,
  Switch,
  notification,
  Menu,
  MenuButton,
  TextEllipsis,
  useCall,
  useDebounce,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import { MediaBuyModel } from "models";
import { deleteMediaBuy, putMediaBuy } from "api";
import { appEnvironment } from "config/environment.config";

import styles from "./table-row.module.css";
import copyImg from "assets/images/copy.svg";
import circlePlusImg from "assets/images/circle-plus-blue.svg";

type Props = {
  item: MediaBuyModel;
  handleSetId: (id: string, isToggle: boolean) => void;
  handleDuplicateRowClick: (id: string, isToggle: boolean) => void;
  handleInlineFormRowClick: (id: string, isInlineVendor: boolean) => void;
  refresh: VoidFunction;
  refreshCampaignPage: VoidFunction;
  className?: string;
};

const trafficUrlPrefix = appEnvironment.apiUrl.replace("/v1", "");

const Row: FC<Props> = ({
  item,
  handleSetId,
  handleDuplicateRowClick,
  handleInlineFormRowClick,
  refresh,
  refreshCampaignPage,
  className = "",
}: Props) => {
  const {
    name,
    type,
    interactionsCount,
    sourceId,
    source,
    redirectUrl,
    trafficUrl,
    id,
    externalVendors,
  } = item;
  const [isToggle, setIsToggle] = useState(item.isActive);
  const { debounce } = useDebounce();
  const classNameForType = styles[type?.toLowerCase()];
  const mediaBuyCall = useCall(putMediaBuy.setParams({ mediaBuyId: id }));
  const { submit, onCallSuccess, onCallError } = useCall(
    deleteMediaBuy.setParams({ mediaBuyId: id }),
  );

  useDidUpdate(() => {
    setIsToggle(item.isActive);
  }, [item.isActive]);

  onCallSuccess(() => {
    notification.success("Media buy deleted", "Successfully deleted Media buy");
    refresh();
    refreshCampaignPage();
  });

  onCallError(() => {
    notification.error("Deleting error", "Cannot delete Media buy");
  });

  mediaBuyCall.onCallError((error) => {
    notification.error("Cannot change status", error.message);
  });

  const handleToggleRow = (_value: string, isChecked: boolean) => {
    setIsToggle(isChecked);
    debounce(() => {
      mediaBuyCall.submit({ data: { isActive: isChecked } });
    });
  };

  const handleEditRowClick = () => handleSetId(id, isToggle);

  const handleDeleteRowClick = () => {
    notification.confirm("Delete entries ?", "You will not be able to recover it", {
      onOk: () => submit(),
      icon: "delete",
      okText: "Delete",
    });
  };

  const handleCopyClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    notification.success("Link copied to clipboard", "Successfully copied");
  };

  const handleAddSource = () => {
    handleInlineFormRowClick(id, false);
  };

  const handleAddVendor = () => {
    handleInlineFormRowClick(id, true);
  };

  const handleDuplicateClick = () => handleDuplicateRowClick(id, isToggle);

  const renderEVRows = () => {
    return externalVendors.map(({ name }, index) =>
      index + 1 === externalVendors.length ? name : `${name}, `,
    );
  };

  const mediaIsBroken =
    (item.externalVendors.length === 1 && item.externalVendors[0] === null) ||
    !item.externalVendors.length;

  return (
    <TableRow id={id}>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {name}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cell}>
        <Button
          btnSize="small"
          appearance="text"
          className={styles.copyClipboard}
          onClick={() => {
            handleCopyClipboard(redirectUrl);
          }}
        >
          <img src={copyImg} alt="" className={styles.actionIcon} />
        </Button>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {redirectUrl}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cell}>
        <Button
          btnSize="small"
          appearance="text"
          className={styles.copyClipboard}
          onClick={() => {
            handleCopyClipboard(trafficUrlPrefix + trafficUrl);
          }}
        >
          <img src={copyImg} alt="" className={styles.actionIcon} />
        </Button>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {trafficUrl}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cell}>
        {externalVendors?.length ? (
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {renderEVRows()}
          </TextEllipsis>
        ) : (
          <div className={className}>
            <Button
              type="button"
              appearance="text"
              className={styles.sourceBtn}
              onClick={handleAddVendor}
            >
              <img src={circlePlusImg} alt="" />
              Add External Vendor
            </Button>
          </div>
        )}
      </TableCell>
      <TableCell className={styles.cell}>
        {source ? (
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
            <div className={className}>{source.name || sourceId} </div>
          </TextEllipsis>
        ) : (
          <div className={className}>
            <Button
              type="button"
              appearance="text"
              className={styles.sourceBtn}
              onClick={handleAddSource}
            >
              <img src={circlePlusImg} alt="" />
              Add Source
            </Button>
          </div>
        )}
      </TableCell>
      <TableCell>
        <div className={classNameForType}>{type}</div>
      </TableCell>
      <TableCell>
        <div className={styles.clicksCount}>{interactionsCount}</div>
      </TableCell>
      <TableCell>
        <Switch
          value="enabled"
          checked={isToggle}
          disabled={mediaIsBroken}
          onChange={handleToggleRow}
          appearance="primary"
          inputSize="medium"
          className={styles.switch}
        />
      </TableCell>
      <TableCell className={styles.hideOnMobile}>
        <Menu>
          <MenuButton onClick={handleEditRowClick} className={styles.editButton} icon="edit">
            Edit
          </MenuButton>
          <MenuButton onClick={handleDuplicateClick} className={styles.copyButton} icon="duplicate">
            Duplicate
          </MenuButton>
          <MenuButton onClick={handleDeleteRowClick} className={styles.deleteButton} icon="delete">
            Delete
          </MenuButton>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default Row;
