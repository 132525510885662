import { useState, ReactNode } from "react";
import {
  useCall,
  usePagination,
  useFilters,
  notification,
  useDevice,
  useFetch,
  getPaginatedList,
} from "@epcnetwork/core-ui-kit";

import { deleteVendor, getExternalVendors } from "api";
import { ExternalVendorsModel } from "models";
import { filtersOptions, getQueryKeys } from "./external-vendors-list.constants";

interface Output {
  handleCloseViewModal: VoidFunction;
  handleCloseModal: VoidFunction;
  handleViewItem: (id: string) => void;
  handleRemoveItem: (id: string, customIcon: ReactNode) => void;
  handleEdit: (id: string) => void;
  handleAdd: VoidFunction;
  editId: string;
  isMainModalOpen: boolean;
  viewId: string;
  isViewModalOpen: boolean;
  loading: boolean;
  isMobileDevice: boolean;
  refresh: VoidFunction;
  setSearch: (value: string) => void;
  list: ExternalVendorsModel[];
  searchValue: string;
  error: string | undefined;
  pagination: ReturnType<typeof usePagination>;
}

const useExternalVendorsListHook = (): Output => {
  const { isMobileDevice } = useDevice();
  const { queryString, setSearch, searchValue } = useFilters({
    searchKey: filtersOptions.searchKey,
    nonClearableKeys: getQueryKeys(isMobileDevice),
  });
  const { payload, loading, refresh, error, actions } = useFetch(
    getExternalVendors.setQueryParams(queryString),
    { dependencies: [queryString] },
  );

  const { onCallSuccess, onCallError, submit } = useCall(deleteVendor);
  const list = getPaginatedList(payload?.data);
  const pagination = usePagination(payload);

  onCallSuccess(() => {
    refresh();
    notification.success("Vendor deleted", "Successfully deleted a Vendor");
  });

  onCallError((error) => {
    refresh();
    notification.error("Deleting error", `Vendor was ${error.message}`);
  });

  const [editId, setEditId] = useState<string>("");
  const [isMainModalOpen, setIsMainModalOpen] = useState<boolean>(false);
  const [viewId, setViewId] = useState<string>("");
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);

  const setFilteredList = (list: ExternalVendorsModel[]) => {
    if (payload) {
      const { setData } = actions;
      setData({ ...payload, data: list });
    }
  };

  const handleAdd = () => {
    setEditId("");
    setIsMainModalOpen(true);
  };

  const handleEdit = (id: string) => {
    setEditId(id);
    setIsMainModalOpen(true);
  };

  const handleRemoveItem = (id: string, customIcon: ReactNode) => {
    notification.confirm("Deleting Vendor ?", "You will not be able to recover it", {
      onOk: () => {
        submit({ params: { vendorId: id } });
        setFilteredList(list.filter((el) => el.id !== id));
      },
      okText: "Delete",
      customIcon,
    });
  };

  const handleViewItem = (id: string) => {
    setViewId(id);
    setIsViewModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsMainModalOpen(false);
    setEditId("");
  };

  const handleCloseViewModal = () => {
    setIsViewModalOpen(false);
    setViewId("");
  };

  return {
    handleCloseViewModal,
    handleCloseModal,
    handleViewItem,
    handleRemoveItem,
    handleEdit,
    handleAdd,
    editId,
    isMainModalOpen,
    viewId,
    isViewModalOpen,
    error: error?.message,
    loading,
    refresh,
    setSearch,
    searchValue,
    pagination,
    list,
    isMobileDevice,
  };
};

export default useExternalVendorsListHook;
