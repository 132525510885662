import React, { FC, useState } from "react";

import { LinksType } from "./menu";
import Link from "./link";

import styles from "../sidebar.module.css";

type MenuLinkTypes = {
  triggerLink: LinksType;
  links: LinksType[];
};

const MenuLink: FC<MenuLinkTypes> = ({ triggerLink, links }: MenuLinkTypes) => {
  const [isParentActive, setIsParentActive] = useState(false);
  const handleActiveLink = (status: boolean) => status && setIsParentActive(true);

  return (
    <div>
      <Link {...triggerLink} initialActive={isParentActive} />
      <div className={styles.menuLinks}>
        {links.map((link) => (
          <Link {...link} key={link.text} handleActive={handleActiveLink} />
        ))}
      </div>
    </div>
  );
};

export default MenuLink;
