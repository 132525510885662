import { MediaBuyModel } from "models";
import { MediaBuyData } from "./media-buys.interface";
import { apiMiddleware } from "../api.middleware";

export const getMediaBuy = apiMiddleware<MediaBuyModel>()({
  method: "get",
  endpoint: `/media-buy/:mediaBuyId`,
});

export const postMediaBuy = apiMiddleware<MediaBuyData, Omit<MediaBuyData, "status">>()({
  method: "post",
  endpoint: `/campaign/:campaignId/media-buys`,
});

export const putMediaBuy = apiMiddleware<MediaBuyData, Partial<MediaBuyData>>()({
  method: "put",
  endpoint: `/media-buy/:mediaBuyId`,
});

export const deleteMediaBuy = apiMiddleware<MediaBuyModel>()({
  method: "delete",
  endpoint: `/media-buy/:mediaBuyId`,
});
