import React, { FC } from "react";
import CodeMirror from "react-codemirror";
import { useFormikContext } from "formik";
import { FormField } from "@epcnetwork/core-ui-kit";

import UrlParameter from "./url-parameter";
import { ContentTypes } from "constants/forms.constants";
import { RestApiType } from "pages/external-vendors/form/vendors-form.types";
import { getJsonValueFromArray, options, structurePreview } from "./json-editor";

import styles from "../api-type.module.css";

const contentTypeOptions = Object.values(ContentTypes).map((key) => ({ label: key, value: key }));

const ApiPost: FC = () => {
  const { values } = useFormikContext<RestApiType>();
  const json = structurePreview(getJsonValueFromArray(values.bodyParams));
  return (
    <div className={styles.queryWrapper}>
      <FormField
        type="select"
        name="contentType"
        label="CONTENT TYPE"
        placeholder="Choose content type"
        options={contentTypeOptions}
        disableClearing
        required
      />
      <UrlParameter btnName="Add POST Body Parameter" prefix="bodyParams" noLabel />
      <div className={styles.jsonEditorWrap}>
        <CodeMirror
          key={json}
          className={styles.jsonEditor}
          value={json}
          options={{ ...options, readOnly: true }}
        />
      </div>
    </div>
  );
};

export default ApiPost;
