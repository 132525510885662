import { List } from "@epcnetwork/core-ui-kit";

import { MediaBuyModel, SourceModel } from "models";
import { apiMiddleware } from "../api.middleware";
import { PostSourceData } from "./sources.interface";

export const getSources = apiMiddleware<List<SourceModel>>()({
  method: "get",
  endpoint: "/source",
});

export const getSource = apiMiddleware<SourceModel>()({
  method: "get",
  endpoint: `/source/:sourceId`,
});

export const getMediaBuysBySourceId = apiMiddleware<List<MediaBuyModel>>()({
  method: "get",
  endpoint: "/source/:id/media-buys",
});

export const deleteSource = apiMiddleware()({
  method: "delete",
  endpoint: `/source/:sourceId`,
});

export const postSource = apiMiddleware<SourceModel, PostSourceData>()({
  method: "post",
  endpoint: "/source",
});

export const putSource = apiMiddleware<SourceModel, PostSourceData>()({
  method: "put",
  endpoint: `/source/:sourceId`,
});
