import { useState } from "react";
import {
  usePagination,
  useFilters,
  useFetch,
  useDevice,
  getPaginatedList,
} from "@epcnetwork/core-ui-kit";

import { getClients } from "api";
import { ClientModel } from "models";
import { filtersOptions } from "./clients-list.constants";

interface Output {
  searchValue: string;
  editId: string;
  loading: boolean;
  isFormModalOpen: boolean;
  isMobileDevice: boolean;
  error: string;
  list: ClientModel[] | null;
  pagination: ReturnType<typeof usePagination>;
  handleAdd: VoidFunction;
  handleCloseModal: VoidFunction;
  handleCloseFormModal: VoidFunction;
  refresh: VoidFunction;
  setSearch: (str: string) => void;
  handleEdit: (id: string) => void;
}

const useClientsListHook = (): Output => {
  const { queryString, searchValue, setSearch } = useFilters(filtersOptions);

  const { payload, loading, error, refresh } = useFetch(getClients.setQueryParams(queryString), {
    dependencies: [queryString],
  });

  const list = getPaginatedList(payload?.data);
  const pagination = usePagination(payload);
  const { isMobileDevice } = useDevice();

  const [editId, setEditId] = useState<string | null>(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);

  const handleAdd = () => {
    setEditId(null);
    setIsFormModalOpen(true);
  };

  const handleEdit = (id: string) => {
    setEditId(id);
    setIsFormModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditId(null);
  };

  const handleCloseFormModal = () => {
    setIsFormModalOpen(false);
  };

  return {
    searchValue,
    editId: editId || "",
    loading,
    isFormModalOpen,
    error: error?.message || "",
    list,
    pagination,
    handleAdd,
    handleCloseModal,
    handleCloseFormModal,
    refresh,
    setSearch,
    handleEdit,
    isMobileDevice,
  };
};

export default useClientsListHook;
