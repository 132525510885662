import React, { FC, useState } from "react";
import { format } from "date-fns";
import {
  TableRow,
  TableCell,
  notification,
  MenuButton,
  Menu,
  Button,
  TextEllipsis,
  useCall,
} from "@epcnetwork/core-ui-kit";

import { MemberModel } from "models";
import { deleteUser, reinviteUser } from "api";
import { getDateWithTimezone } from "utils";

import styles from "./table-row.module.css";
import clockImg from "assets/images/clock-grey.svg";
import calendarImg from "assets/images/calendar-grey.svg";
import retryGreyImg from "assets/images/retry-circle-grey.svg";
import deleteImg from "assets/images/delete-entity.svg";

type Props = {
  item: MemberModel;
  handleEdit: (id: string) => void;
  handleView: (id: string) => void;
};

const Row: FC<Props> = ({ item, handleEdit, handleView }: Props) => {
  const [isDeleted, setDeleted] = useState(false);
  const { submit, onCallSuccess, onCallError } = useCall(deleteUser);

  const { id, user, group, createdAt, roleName } = item;

  const date = getDateWithTimezone(createdAt);
  const isPendingStatus = user?.status === "pending";

  onCallSuccess(() => {
    notification.success("User deleted", "Successfully deleted user");
  });
  onCallError(() => {
    setDeleted(false);
    notification.error("Deleting error", "Cannot delete user");
  });

  const {
    submit: submitReinvite,
    onCallSuccess: onReinviteSuccess,
    onCallError: onReinviteError,
  } = useCall(reinviteUser);

  const handleResendInvite = (id: string) => submitReinvite({ params: { userId: id } });

  onReinviteSuccess(() => {
    notification.success("Invitation resent", "Invitation resent successfully");
  });
  onReinviteError(() => {
    notification.error("Invitation resent error", "Cannot resent invitation");
  });

  const handleRemoveItem = (id: string) => {
    notification.confirm("Delete user ?", "You will not be able to recover it", {
      onOk: () => {
        setDeleted(true);
        submit({ params: { userId: id } });
      },
      okText: "Delete",
      customIcon: <img src={deleteImg} alt="" />,
    });
  };

  if (isDeleted) {
    return null;
  }

  return (
    <TableRow id={id}>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {user?.email || ""}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cell}>{roleName || ""}</TableCell>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {group?.name || "-"}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        {!isPendingStatus ? (
          <div className={styles.date}>
            <img src={calendarImg} alt="" />
            {format(date, "MMM dd, yyyy")}
            <img src={clockImg} alt="" />
            {format(date, "hh:mm")}
          </div>
        ) : (
          <Button appearance="text" className={styles.btn}>
            <img src={clockImg} alt="" />
            Pending
          </Button>
        )}
      </TableCell>
      <TableCell>
        <Menu>
          <MenuButton onClick={() => handleView(id)} icon="view">
            View
          </MenuButton>
          <MenuButton onClick={() => handleEdit(id)} icon="edit" disabled={isPendingStatus}>
            Edit
          </MenuButton>
          {isPendingStatus && (
            <MenuButton onClick={() => handleResendInvite(id)}>
              <img src={retryGreyImg} alt="" />
              Resend invite
            </MenuButton>
          )}
          <MenuButton appearance="delete" onClick={() => handleRemoveItem(user.id)} icon="delete">
            Delete
          </MenuButton>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default Row;
