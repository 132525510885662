import React, { FC } from "react";
import { useFormikContext } from "formik";
import { FormField, useDidMount } from "@epcnetwork/core-ui-kit";

import { InitialValues, SalesforceType } from "../secrets-form.types";

const Salesforce: FC = () => {
  const { values, setFieldValue } = useFormikContext<InitialValues>();

  const salesforceValues = values.apiTypeContent as SalesforceType | null;

  useDidMount(() => {
    const { clientId, clientSecret, origin, soapOrigin, authOrigin } = salesforceValues || {};

    !clientId && setFieldValue("apiTypeContent.clientId", "");
    !clientSecret && setFieldValue("apiTypeContent.clientSecret", "");
    !origin && setFieldValue("apiTypeContent.origin", "");
    !soapOrigin && setFieldValue("apiTypeContent.soapOrigin", "");
    !authOrigin && setFieldValue("apiTypeContent.authOrigin", "");
  });
  return (
    <>
      <FormField
        type="text"
        name="apiTypeContent.clientId"
        label="CLIENT ID"
        placeholder=""
        required
      />
      <FormField
        type="text"
        name="apiTypeContent.clientSecret"
        label="CLIENT Secret"
        placeholder=""
        required
      />
      <FormField
        type="text"
        name="apiTypeContent.origin"
        label="REST BASE URL"
        placeholder=""
        required
      />
      <FormField
        type="text"
        name="apiTypeContent.soapOrigin"
        label="SOAP BASE URL"
        placeholder=""
        required
      />
      <FormField
        type="text"
        name="apiTypeContent.authOrigin"
        label="AUTHENTICATION BASE URL"
        placeholder=""
        required
      />
    </>
  );
};

export default Salesforce;
