/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState, useRef } from "react";
import { Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCall, Loader } from "@epcnetwork/core-ui-kit";

import { getMyProfile } from "api";
import { RootState, setUser, setUserOrganization } from "store";
import { ME_ROLE } from "constants/auth.constants";
import { LOGOUT_PAGE } from "constants/routes.constants";
import { routes, Route } from "config/routes.config";

const App: FC = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const currentToken = useRef(token);

  const { submit, onCallSuccess, onCallError } = useCall(getMyProfile);

  const isSuperAdminModule = user?.type === ME_ROLE.super_admin && !user?.member;

  useEffect(() => {
    if (currentToken.current && !user) {
      setIsLoading(true);
      submit();
    } else {
      setIsLoading(false);
    }
  }, [currentToken, submit, user]);

  onCallSuccess(async (payload) => {
    dispatch(setUser(payload));
    if (payload.member) {
      dispatch(setUserOrganization(payload.member?.organization));
    }
    setIsLoading(false);
  });

  onCallError(() => {
    setIsLoading(false);
  });

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          inset: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader type="puff-loader" size={100} />;
      </div>
    );
  }

  const routesToRender = routes.filter((route) => {
    if (!isSuperAdminModule) return !route.isAdminModule;

    return route.isAdminModule || route.path === LOGOUT_PAGE.path;
  });

  return (
    <Switch>
      {routesToRender.map((route) => (
        <Route key={route.path} {...route} />
      ))}
    </Switch>
  );
};

export default App;
