import { useState, KeyboardEvent, ClipboardEvent } from "react";
import { FormikHelpers } from "formik";
import { notification, useFormValues, useSubmit } from "@epcnetwork/core-ui-kit";

import { getClient, postClient, putClient } from "api";
import { FormPropsType, PostClientData } from "./clients-form.types";
import { clientsFormInitialValues } from "./clients-form.constants";
import { ClientModel } from "models";

interface Output {
  payload: ClientModel | null;
  error: string;
  formTitle: string;
  buttonText: string;
  formProps: FormPropsType;
  onSubmit: (values: PostClientData, helpers: FormikHelpers<PostClientData>) => Promise<void>;
  handleChangePhone: (event: KeyboardEvent<HTMLInputElement>) => void;
  handleCopyPastePhone: (event: ClipboardEvent<HTMLInputElement>) => void;
}

const useClientsFormHook = (
  id: string,
  onCloseModal: VoidFunction,
  refresh: VoidFunction,
): Output => {
  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(
    postClient,
    putClient.setParams({ clientId: id }),
    !!id,
  );
  const { payload, formProps, mapInitialValues } = useFormValues(
    clientsFormInitialValues,
    getClient.setParams({ clientId: id }),
    !!id,
  );

  const [title, setTitle] = useState("");
  const [error, setError] = useState("");

  const buttonText = title ? "Save" : "Add";
  const formTitle = id ? `Edit: ${title}` : "New client";
  const notificationTitle = id ? "updated" : "created";

  mapInitialValues((payload) => {
    const { name } = payload;
    setTitle(name);
    return {
      name: payload.name,
      email: payload.email,
      contactPerson: payload.contactPerson,
      phone: payload.phone,
      comments: payload.comments,
      updatedAt: payload.updatedAt,
      createdAt: payload.createdAt,
    };
  });

  onSubmitSuccess(() => {
    onCloseModal();
    setError("");
    notification.success(
      `Client ${notificationTitle}`,
      `Successfully ${notificationTitle} a Client`,
    );
    refresh();
  });

  onSubmitError((error) => {
    setError(error.message);
  });

  const handleChangePhone = (event: KeyboardEvent<HTMLInputElement>) => {
    if (/[A-Za-z]/g.test(event.key)) {
      event.preventDefault();
      return false;
    }
  };

  const handleCopyPastePhone = (event: ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData("Text");

    if (/[A-Za-z]/g.test(pastedData)) {
      event.preventDefault();
      return false;
    }
  };

  return {
    payload,
    error,
    onSubmit,
    formTitle,
    formProps,
    buttonText,
    handleChangePhone,
    handleCopyPastePhone,
  };
};

export default useClientsFormHook;
