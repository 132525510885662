import React, { FC, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import cn from "classnames";
import { useLocalStorage } from "@epcnetwork/core-ui-kit";

import { entityName } from "../sidebar.constants";
import { CLIENTS_LIST_PAGE } from "constants/routes.constants";
import { isActiveRoute } from "utils";

import styles from "../sidebar.module.css";

interface Props {
  src?: string;
  text: string;
  to: string;
  exact?: boolean;
  initialActive?: boolean;
  handleActive?: (value: boolean) => void;
}

const Link: FC<Props> = ({ src, text, to, exact, initialActive, handleActive }: Props) => {
  const location = useLocation();
  const { value } = useLocalStorage(entityName);
  const isActive = isActiveRoute(to, location, exact);

  const getActiveStyle = () => {
    if (initialActive) return styles.activeLink;

    if (to === CLIENTS_LIST_PAGE.path && location.pathname === "") {
      return styles.activeLink;
    }

    return isActive ? styles.activeLink : "";
  };

  useEffect(() => {
    !!handleActive && handleActive(isActive);
  }, [location]);

  const activeStyle = getActiveStyle();
  const linkStyles = cn(styles.link, activeStyle, { [styles.collapsed]: value });

  return (
    <RouterLink to={to} className={linkStyles}>
      {src && <img src={src} alt="" />}
      <span className={styles.linkText}>{text}</span>
    </RouterLink>
  );
};

export default Link;
