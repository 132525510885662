import { ClientModel } from "./client.models";

export enum CampaignsTypes {
  CTO = "CTO",
  CPC = "CPC",
  COREG = "COREG",
}

export type CampaignsTypesKeys = keyof typeof CampaignsTypes;

export type CampaignStatusTypes = "pending" | "active" | "paused" | "completed" | "all";

export type CampaignCountModel = {
  allCampaignCount: number;
  completedCampaignCount: number;
  activeCampaignCount: number;
  pendingCampaignCount: number;
  pausedCampaignCount: number;
};

export type CampaignModel = {
  id: string;
  createdAt: string;
  deleted: boolean;
  interactionsCount: number;
  mediaBuys: string[];
  name: string;
  updatedAt: string;
  client: ClientModel;
  status: CampaignStatusTypes;
  startDate: string;
  endDate: string;
  comments: string;
  type: CampaignsTypesKeys[] | null;
  allMediaBuysCount: number;
  brokenMediaBuysCount: number;
  activeMediaBuysCount: number;
  inactiveMediaBuysCount: number;
};

export type CampaignData = {
  name: string;
  startDate: string;
  endDate: string;
  comments: string;
  clientId: string;
};

export type CampaignStatusData = {
  status: "active" | "paused";
};

export type CampaignStatsModel = {
  name: CampaignsTypesKeys;
  interactionsCount: number;
};

export type CampaignGanttModel = {
  clientId: string;
  name: string;
  startDate: string;
  endDate: string;
  status: CampaignStatusTypes;
  allMediaBuysCount: number;
  totalInteractions: number;
  coregInteractions: number;
  cpcInteractions: number;
  ctoInteractions: number;
};
