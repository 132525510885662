import React, { FC, ReactNode, createContext, useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store";
import { createAuthorizationConfig } from "config/authorization.config";

type AuthorizationGuardProps = {
  children: ReactNode;
};

const initialAbility = createAuthorizationConfig(null);

export const AbilityContext = createContext(initialAbility);

const AuthorizationGuard: FC<AuthorizationGuardProps> = ({ children }: AuthorizationGuardProps) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const ability = useMemo(() => {
    return createAuthorizationConfig(user);
  }, [user]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export default AuthorizationGuard;
