import React, { FC, memo } from "react";
import { useFormikContext } from "formik";
import { FormField } from "@epcnetwork/core-ui-kit";

import { SecretsRestApiTypesKeys } from "models";
import { BodyType, InitialValues, RestApiInitialValues } from "../secrets-form.types";
import { contentTypeOptions } from "../secrets-form.constants";

import styles from "../secrets-form.module.css";
import lockImg from "assets/images/lock.svg";
import userImg from "assets/images/user-placeholder.svg";

type Props = {
  placementType: SecretsRestApiTypesKeys;
};

const RestApiDispatcher: FC<Props> = memo(({ placementType = "basic" }: Props) => {
  const { values } = useFormikContext<InitialValues<RestApiInitialValues>>();
  const restApiContent = values.apiTypeContent?.restApiContent;

  const jsonBody = (restApiContent as BodyType)?.jsonBody;

  const selectedOption = contentTypeOptions.find((option) => option.value === jsonBody);

  switch (placementType) {
    case "bearer":
      return (
        <FormField
          type="text"
          name="apiTypeContent.restApiContent.bearerToken"
          label="TOKEN"
          required
          icon={lockImg}
        />
      );
    case "basic":
      return (
        <>
          <div className={styles.hint}>Please provide user name or password or both of them</div>
          <FormField
            type="text"
            name="apiTypeContent.restApiContent.userName"
            label="USER NAME"
            required
            icon={userImg}
          />
          <FormField
            type="text"
            name="apiTypeContent.restApiContent.password"
            label="PASSWORD"
            required
            icon={lockImg}
          />
        </>
      );
    case "header":
      return (
        <>
          <FormField
            type="text"
            name="apiTypeContent.restApiContent.headerKey"
            label="KEY"
            required
          />
          <FormField
            type="text"
            name="apiTypeContent.restApiContent.headerValue"
            label="VALUE"
            required
          />
        </>
      );
    case "query":
      return (
        <>
          <FormField
            type="text"
            name="apiTypeContent.restApiContent.queryKey"
            label="KEY"
            required
          />
          <FormField
            type="text"
            name="apiTypeContent.restApiContent.queryValue"
            label="VALUE"
            required
          />
        </>
      );
    case "body":
      return (
        <>
          <FormField
            type="text"
            name="apiTypeContent.restApiContent.bodyKey"
            label="KEY"
            required
          />
          <FormField
            type="text"
            name="apiTypeContent.restApiContent.bodyValue"
            label="VALUE"
            required
          />
          <FormField
            type="select"
            name="apiTypeContent.restApiContent.jsonBody"
            label="CONTENT TYPE"
            options={contentTypeOptions}
            initiallySelectedOptions={selectedOption}
            disableClearing
            required
          />
        </>
      );

    default:
      return null;
  }
});

export default RestApiDispatcher;
