import React, { FC, ReactNode } from "react";
import {
  TableRow,
  TableCell,
  MenuButton,
  Menu,
  formatDate,
  TextEllipsis,
} from "@epcnetwork/core-ui-kit";

import { getDateWithTimezone } from "utils";
import { ExternalVendorsModel, SecretsAPITypes } from "models";

import styles from "./table-row.module.css";
import deleteImg from "assets/images/delete-entity.svg";

type Props = {
  item: ExternalVendorsModel;
  handleEdit: (id: string) => void;
  handleRemoveItem: (id: string, customIcon: ReactNode) => void;
  handleViewItem: (id: string) => void;
};

const Row: FC<Props> = ({ item, handleEdit, handleRemoveItem, handleViewItem }: Props) => {
  const { id, name, secret, apiType, httpMethod, url, createdAt } = item;

  return (
    <TableRow id={id}>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {name}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {secret?.name}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {SecretsAPITypes[apiType]}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {httpMethod}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {url}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.date}>
        {formatDate(getDateWithTimezone(createdAt), "MMM dd, yyyy")}
      </TableCell>
      <TableCell className={styles.hideOnMobile}>
        <Menu>
          <MenuButton onClick={() => handleViewItem(id)} className={styles.makeSmaller} icon="view">
            View
          </MenuButton>
          <MenuButton onClick={() => handleEdit(id)} icon="edit">
            Edit
          </MenuButton>
          <MenuButton
            appearance="delete"
            onClick={() => handleRemoveItem(id, <img src={deleteImg} alt="" />)}
            icon="delete"
          >
            Delete
          </MenuButton>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default Row;
