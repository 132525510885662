import React, { FC } from "react";
import { Button, Container, Modal, Search, Table } from "@epcnetwork/core-ui-kit";

import TableRow from "./table-row/table-row";
import ListFilters from "./list-filters/list-filters";
import useSourcesListHook from "./sources-list.hook";
import SourcesForm from "../form/sources-form.page";
import { entityName, tableColumns } from "./sources-list.constants";
import Breadcrumbs from "./breadcrumbs/breadcrumbs";

import styles from "./sources-list.module.css";

const SourcesListPage: FC = () => {
  const {
    editId,
    searchValue,
    loading,
    modalFormIsOpen,
    error,
    pagination,
    list,
    setSearch,
    handleEdit,
    handleDeleteClick,
    onCloseModal,
    addSourceHandle,
    refresh,
    isMobileDevice,
  } = useSourcesListHook();

  return (
    <>
      <Breadcrumbs />
      <Container>
        <div className={styles.searchHeaderWrapper}>
          <div className={styles.searchContainer}>
            <Search
              className={styles.searchField}
              searchValue={searchValue}
              setSearch={setSearch}
            />
            <ListFilters />
          </div>
          <Button appearance="primary" onClick={addSourceHandle} className={styles.addBtn}>
            + Add Source
          </Button>
          <Button appearance="primary" onClick={addSourceHandle} className={styles.addSmallScreen}>
            + Add
          </Button>
        </div>
        <Table
          entityName={entityName}
          className={styles.table}
          columns={tableColumns(isMobileDevice)}
          list={list}
          loading={loading}
          error={error}
          refresh={refresh}
          pagination={pagination}
          row={(item) => (
            <TableRow
              key={item.name}
              item={item}
              handleEdit={handleEdit}
              handleDelete={handleDeleteClick}
            />
          )}
        />
        <Modal isOpen={modalFormIsOpen} setClose={onCloseModal} contentClassName={styles.modal}>
          <SourcesForm id={editId} onCloseModal={onCloseModal} refresh={refresh} />
        </Modal>
      </Container>
    </>
  );
};

export default SourcesListPage;
