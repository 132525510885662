export enum AuthorizationActionType {
  create = "create",
  read = "read",
  update = "update",
  delete = "delete",
  readList = "read-list",
}

export enum AuthorizationEntityName {
  clients = "Clients",
  campaigns = "Campaigns",
  secrets = "Secrets",
  vendors = "Vendors",
  sources = "Sources",
  adminSettings = "AdminSettings",
  users = "Users",
  roles = "Roles",
  structure = "Structure",
  organizationSwitcher = "organizationSwitcher",
  groupSwitcher = "groupSwitcher",
}
