import React, { FC } from "react";
import classnames from "classnames";
import {
  TableRow,
  TableCell,
  notification,
  Menu,
  MenuButton,
  useCall,
  formatDate,
  TextEllipsis,
} from "@epcnetwork/core-ui-kit";

import { CampaignModel, CampaignStatusTypes } from "models";
import { CAMPAIGNS_DETAILS_PAGE } from "constants/routes.constants";
import { linkTo, getDateWithTimezone } from "utils";
import { deleteCampaign } from "api";
import { ImageLink } from "components";

import styles from "./table-row.module.css";
import BracketArrowStartImg from "assets/images/bracket-arrow-start-grey.svg";
import BracketArrowEndImg from "assets/images/bracket-arrow-end-grey.svg";

type Props = {
  item: CampaignModel;
  refresh: VoidFunction;
  handleEdit: (clientId: string, campaignId: string) => void;
  handleDuplicateClick: (clientId: string, campaignId: string) => void;
  className?: string;
};

const colors: {
  [Key in CampaignStatusTypes]: "primary" | "success" | "warning" | "tertiary" | "default";
} = {
  pending: "tertiary",
  active: "success",
  paused: "warning",
  completed: "primary",
  all: "default",
};

const Row: FC<Props> = ({
  item,
  className = "",
  handleEdit,
  refresh,
  handleDuplicateClick,
}: Props) => {
  const {
    name,
    interactionsCount,
    id,
    type,
    client,
    allMediaBuysCount,
    startDate,
    endDate,
    brokenMediaBuysCount,
    activeMediaBuysCount,
    inactiveMediaBuysCount,
    status,
  } = item;

  const { submit, onCallSuccess, onCallError } = useCall(deleteCampaign);
  onCallSuccess(() => {
    notification.success("Campaign deleted", "Successfully deleted Campaign");
    refresh();
  });
  onCallError((error) => {
    notification.error("Deleting error", error.message);
    refresh();
  });

  const handleDeleteRowClick = () => {
    const message = allMediaBuysCount
      ? `Deleting Campaign "${name}" you automatically delete connected:`
      : "You will not be able to recover it";
    const link = linkTo(CAMPAIGNS_DETAILS_PAGE.path, id);
    const entities = allMediaBuysCount ? [{ text: `See all Media Buys`, link }] : [];

    notification.confirm("Delete Campaign ?", message, {
      onOk: () => submit({ params: { campaignId: id } }),
      okText: "Delete",
      icon: "delete",
      entities,
    });
  };

  const handleTransformDate = (date: string) => {
    return formatDate(getDateWithTimezone(date), "MMM dd, yyyy");
  };
  const handleDuplicateRowClick = () => {
    handleDuplicateClick(client.id, id);
  };
  const handleEditRowClick = () => {
    handleEdit(client.id, id);
  };

  const isDisableEdit = status === "completed";
  const linkToCampaignDetails = linkTo(CAMPAIGNS_DETAILS_PAGE.path, id);

  if (!item) return null;

  const color = colors[status];

  return (
    <TableRow id={id} statusIndicatorColor={color}>
      <TableCell className={styles.name}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {name}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.client}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {client.name}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <div className={styles.wrapper} data-testid="allMediaBuys">
          <ImageLink to={linkToCampaignDetails}>{allMediaBuysCount}</ImageLink>
        </div>
      </TableCell>
      <TableCell>
        <div>{brokenMediaBuysCount}</div>
      </TableCell>
      <TableCell>
        <div>{activeMediaBuysCount}</div>
      </TableCell>
      <TableCell>
        <div>{inactiveMediaBuysCount}</div>
      </TableCell>
      <TableCell>
        <div className={className}>{interactionsCount}</div>
      </TableCell>
      <TableCell>
        {type?.length ? (
          type.map((typeName) => (
            <div key={typeName} className={classnames(styles[typeName?.toLowerCase()])}>
              {typeName}
            </div>
          ))
        ) : (
          <div className={styles.typeStub}>-</div>
        )}
      </TableCell>
      <TableCell className={styles.dateCell}>
        <div className={styles.dateWrapper}>
          <img src={BracketArrowStartImg} alt="" className={styles.icon} />
          <div>{handleTransformDate(startDate)}</div>
        </div>
      </TableCell>
      <TableCell className={styles.dateCell}>
        <div className={styles.dateWrapper}>
          <img src={BracketArrowEndImg} alt="" className={styles.icon} />
          <div>{handleTransformDate(endDate)}</div>
        </div>
      </TableCell>
      <TableCell className={styles.hideOnMobile}>
        <Menu>
          <MenuButton
            onClick={handleEditRowClick}
            className={styles.editButton}
            icon="edit"
            disabled={isDisableEdit}
          >
            Edit
          </MenuButton>
          <MenuButton
            onClick={handleDuplicateRowClick}
            className={styles.copyButton}
            icon="duplicate"
          >
            Duplicate
          </MenuButton>
          <MenuButton onClick={handleDeleteRowClick} className={styles.deleteButton} icon="delete">
            Delete
          </MenuButton>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default Row;
