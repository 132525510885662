import React, { FC, memo, useRef, useState } from "react";
import { FormikProps } from "formik";
import {
  Button,
  Form,
  FormButtons,
  FormField,
  MessageField,
  notification,
  useAutocomplete,
  useCall,
  useDebounce,
  useFormValues,
} from "@epcnetwork/core-ui-kit";

import { getExternalVendors, getMediaBuy, getSources, putMediaBuy } from "api";
import { ExternalVendorsModel } from "models";
import {
  initialValuesSourceForm,
  PLACEHOLDER_SOURCE,
  PLACEHOLDER_VENDOR,
  validationSourceSchema,
  validationVendorSchema,
} from "pages/media-buy/form/media-buy-form.constants";

import styles from "./inline-form.module.css";
import closeImg from "assets/images/close-secrets.svg";

interface Props {
  currentId: string;
  isInlineVendor: boolean;
  handleCloseInlineModal: VoidFunction;
  refresh: VoidFunction;
}

const InlineForm: FC<Props> = ({
  handleCloseInlineModal,
  currentId,
  refresh,
  isInlineVendor,
}: Props) => {
  const formRef = useRef<FormikProps<typeof initialValuesSourceForm>>(null);

  const [error, setError] = useState("");
  const [currentValue, setCurrentValue] = useState<string | string[]>("");

  const { formProps } = useFormValues(
    initialValuesSourceForm,
    getMediaBuy.setParams({ mediaBuyId: currentId }),
    !!currentId,
  );

  const { debounce } = useDebounce();
  const mediaBuyCall = useCall(putMediaBuy.setParams({ mediaBuyId: currentId }));
  const sourcesAutocomplete = useAutocomplete(getSources, "id", "name", {
    searchKey: "search",
  });

  const externalVendorsAutocomplete = useAutocomplete(getExternalVendors, "id", "name", {
    searchKey: "search",
  });

  mediaBuyCall.onCallSuccess(() => {
    const message = isInlineVendor ? "The vendor was added" : "The source was added";
    handleCloseInlineModal();
    notification.success(`Media buy updated`, message);
    refresh();
  });

  const handleChangeSource = (option: ExternalVendorsModel) => {
    if (!option) {
      setCurrentValue("");
      return;
    }
    setCurrentValue(option.id);
  };

  const handleChangeVendor = (options: ExternalVendorsModel[]) => {
    if (!options) {
      setCurrentValue("");
      return;
    }

    if (isInlineVendor) {
      const ids = options.map((option: ExternalVendorsModel) => option.id);
      setCurrentValue(ids);
      return;
    }
  };

  mediaBuyCall.onCallError((error) => {
    setError(error.message);
  });

  const handleSubmit = () => {
    debounce(() => {
      mediaBuyCall.submit({
        data: {
          [isInlineVendor ? "externalVendors" : "sourceId"]: currentValue,
        },
      });
    });
  };

  const placeholder = isInlineVendor ? PLACEHOLDER_VENDOR : PLACEHOLDER_SOURCE;
  const label = isInlineVendor ? "External Vendor" : "Source";
  const name = isInlineVendor ? "externalVendors" : "sourceId";
  const schema = isInlineVendor ? validationVendorSchema : validationSourceSchema;
  const dataAutocomplete = isInlineVendor ? externalVendorsAutocomplete : sourcesAutocomplete;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={closeImg} alt="" onClick={handleCloseInlineModal} />
      </div>
      <div className={styles.body}>
        <div className={styles.name}>{isInlineVendor ? "Add External Vendor" : "Add Source"}</div>
        <MessageField message={error} />
        <Form {...formProps} onSubmit={handleSubmit} validationSchema={[schema]} innerRef={formRef}>
          <FormField
            className={styles.input}
            name={name}
            type="select"
            isMulti={isInlineVendor}
            label={label}
            placeholder={placeholder}
            asyncOptions={dataAutocomplete}
            onChange={isInlineVendor ? handleChangeVendor : handleChangeSource}
            isSearchable
            isTextEditable
            required
          />

          <FormButtons>
            <Button
              type="button"
              appearance="secondary"
              onClick={handleCloseInlineModal}
              className={styles.cancelButton}
            >
              Cancel
            </Button>
            <Button type="submit">Add</Button>
          </FormButtons>
        </Form>
      </div>
    </div>
  );
};
export default memo(InlineForm);
