import React, { FC } from "react";
import { Container, Search, Modal, Table, Button } from "@epcnetwork/core-ui-kit";

import { entityName, tableColumns } from "./secrets-list.constants";
import TableRow from "./table-row/table-row";
import SecretsForm from "../form/secrets-form";
import ListFilters from "./list-filters/list-filters";
import useSecretsListHook from "./secrets-list.hook";
import Breadcrumbs from "./breadcrumbs/breadcrumbs";

import styles from "./secrets.module.css";

const SecretsListPage: FC = () => {
  const {
    loading,
    error,
    refresh,
    modalFormIsOpen,
    editId,
    onCloseModal,
    addSecretHandle,
    handleEdit,
    handleDeleteClick,
    pagination,
    setSearch,
    searchValue,
    list,
    isMobileDevice,
  } = useSecretsListHook();

  return (
    <>
      <Breadcrumbs />
      <Container contentClassName={styles.containerWrap}>
        <div className={styles.searchHeaderWrapper}>
          <div className={styles.searchFormContainer}>
            <Search
              className={styles.searchField}
              searchValue={searchValue}
              setSearch={setSearch}
            />
            <ListFilters />
          </div>
          <Button appearance="primary" onClick={addSecretHandle} className={styles.addBtn}>
            + Add Secret
          </Button>
          <Button appearance="primary" onClick={addSecretHandle} className={styles.addSmallScreen}>
            + Add
          </Button>
        </div>
        <Table
          entityName={entityName}
          className={styles.table}
          columns={tableColumns(isMobileDevice)}
          list={list}
          loading={loading}
          error={error}
          refresh={refresh}
          pagination={pagination}
          row={(item) => (
            <TableRow
              key={item.name}
              item={item}
              handleEdit={handleEdit}
              handleDelete={handleDeleteClick}
            />
          )}
        />
        <Modal isOpen={modalFormIsOpen} setClose={onCloseModal} contentClassName={styles.modal}>
          <SecretsForm onCloseModal={onCloseModal} id={editId} refresh={refresh} />
        </Modal>
      </Container>
    </>
  );
};

export default SecretsListPage;
