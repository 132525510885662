import React, { FC } from "react";
import { useFormikContext } from "formik";
import { FormField, useDidMount } from "@epcnetwork/core-ui-kit";

import { InfusionSoftType, InitialValues } from "../secrets-form.types";

const InfusionSoft: FC = () => {
  const { values, setFieldValue } = useFormikContext<InitialValues<InfusionSoftType>>();

  useDidMount(() => {
    const infusionSoftValues = values.apiTypeContent;
    const { accessToken, refreshToken, clientId, authBase } = infusionSoftValues || {};

    !accessToken && setFieldValue("apiTypeContent.accessToken", "");
    !refreshToken && setFieldValue("apiTypeContent.refreshToken", "");
    !clientId && setFieldValue("apiTypeContent.clientId", "");
    !authBase && setFieldValue("apiTypeContent.authBase", "");
  });
  return (
    <>
      <FormField
        type="text"
        name="apiTypeContent.accessToken"
        label="ACCESS TOKEN"
        placeholder=""
        required
      />
      <FormField
        type="text"
        name="apiTypeContent.refreshToken"
        label="REFRESH TOKEN"
        placeholder=""
        required
      />
      <FormField
        type="text"
        name="apiTypeContent.clientId"
        label="CLIENT ID"
        placeholder=""
        required
      />
      <FormField
        type="text"
        name="apiTypeContent.authBase"
        label="CLIENT SECRET"
        placeholder=""
        required
      />
    </>
  );
};

export default InfusionSoft;
