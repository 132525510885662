import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@epcnetwork/core-ui-kit";

import { LOGIN_PAGE } from "constants/routes.constants";

import logo from "assets/images/logo.svg";
import styles from "../send-email/send-email.module.css";

const ForgotPasswordHeader: FC = () => {
  const history = useHistory();
  const moveToLogin = () => history.push(LOGIN_PAGE.path);

  return (
    <div className={styles.logo}>
      <img className={styles.icon} src={logo} />
      <div className={styles.logoText}>ClicksUA</div>
      <div className={styles.topBar}>
        <span className={styles.topBarText}>Remembered your credentials?</span>
        <Button appearance="text" className={styles.link} onClick={moveToLogin}>
          Log In
        </Button>
      </div>
      <div className={styles.leafBackground} />
    </div>
  );
};

export default ForgotPasswordHeader;
