import React, { FC } from "react";
import { Button, Form, FormField, MessageField } from "@epcnetwork/core-ui-kit";

import VendorsAPIDispatcher from "./api-type-components/vendors-api-dispatcher";
import useVendorsFormHook from "./vendors-form.hook";
import { validationSchema } from "./vendors-form.constants";

import styles from "./vendors-form.module.css";
import closeImg from "assets/images/close-secrets.svg";

type Props = {
  id: string;
  refresh: VoidFunction;
  onCloseModal: VoidFunction;
};

const VendorsForm: FC<Props> = ({ onCloseModal, id = "", refresh }: Props) => {
  const {
    apiType,
    formProps,
    error,
    onSubmit,
    handleCancelClick,
    providersAutocomplete,
    secretsAutocomplete,
    handleProviderSelection,
  } = useVendorsFormHook({ onCloseModal, id, refresh });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={closeImg} alt="" onClick={onCloseModal} />
      </div>
      <div className={styles.body}>
        <div className={styles.name}>
          {id
            ? `Edit: ${formProps.initialValues?.name || "External Vendor API"}`
            : `New External Vendor API`}
        </div>
        <MessageField message={error} className={styles.errorMsgAbsolute} />
        <Form {...formProps} onSubmit={onSubmit} validationSchema={[validationSchema(apiType)]}>
          <>
            <FormField
              type="text"
              name="name"
              label="VENDOR API Name"
              placeholder="Fill the name"
              required
            />
            <FormField
              name="apiType"
              type="select"
              label="Choose provider"
              placeholder="Choose provider"
              asyncOptions={providersAutocomplete}
              onFieldChange={handleProviderSelection}
              disabled={!!id}
              disableClearing
              disableArrow
              required
            />
            {apiType && (
              <FormField
                name="secretId"
                type="select"
                label="Based on SECRET"
                placeholder="Choose secret"
                required
                asyncOptions={secretsAutocomplete}
              />
            )}
            {apiType && <VendorsAPIDispatcher apiType={apiType} />}
            <div className={styles.buttonWrapper}>
              <Button
                type="button"
                appearance="secondary"
                onClick={handleCancelClick}
                className={styles.cancelButton}
              >
                Cancel
              </Button>
              <Button type="submit">{id ? "Save" : "Add"}</Button>
            </div>
          </>
        </Form>
      </div>
    </div>
  );
};

export default VendorsForm;
