import React, { FC } from "react";
import { Container, Search, Table, Button, Modal } from "@epcnetwork/core-ui-kit";

import { AuthorizationEntityName, AuthorizationActionType } from "models";
import { PermitComponent } from "components";
import TableRow from "./table-row/table-row";
import { TABLE_COLUMNS, entityName } from "./clients-list.constants";
import useClientsListHook from "./clients-list.hook";
import ClientsForm from "../form/clients-form";
import BreadCrumbs from "./breadcrumbs/breadcrumbs";

import styles from "./clients-list.module.css";

const ClientsList: FC = () => {
  const {
    searchValue,
    editId,
    loading,
    isFormModalOpen,
    error,
    list,
    pagination,
    handleAdd,
    handleCloseFormModal,
    refresh,
    setSearch,
    handleEdit,
    isMobileDevice,
  } = useClientsListHook();

  return (
    <>
      <BreadCrumbs />
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.tableHeader}>
            <Search searchValue={searchValue} setSearch={setSearch} className={styles.search} />
            <div className={styles.spacer} />
            <PermitComponent
              authAction={AuthorizationActionType.create}
              authEntity={AuthorizationEntityName.clients}
            >
              <Button onClick={handleAdd} className={styles.addBtn}>
                + Add <span className={styles.addBtnTitleLong}>Client</span>
              </Button>
            </PermitComponent>
          </div>
          <Table
            className={styles.table}
            entityName={entityName}
            columns={TABLE_COLUMNS(isMobileDevice)}
            list={list}
            error={error}
            loading={loading}
            refresh={refresh}
            pagination={pagination}
            row={(item) => (
              <TableRow key={item.id} item={item} handleEdit={handleEdit} refresh={refresh} />
            )}
          />
          <Modal
            contentClassName={styles.mainModal}
            isOpen={isFormModalOpen}
            setClose={handleCloseFormModal}
          >
            <ClientsForm onCloseModal={handleCloseFormModal} id={editId} refresh={refresh} />
          </Modal>
        </div>
      </Container>
    </>
  );
};

export default ClientsList;
