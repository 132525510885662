import { ApiErrorResponse, ApiResponse, ApiResponseList } from "@epcnetwork/core-ui-kit";

import { ServerErrorTypes } from "api/api.middleware";

type Props = {
  payload: ApiResponse<unknown> | null;
  loading: boolean;
  error: ApiErrorResponse<ServerErrorTypes> | null;
  refresh: VoidFunction;
};

type PayloadReturnType = <T extends Props>({
  payload,
  error,
  refresh,
}: T) => {
  payload: NonNullable<T["payload"]>;
  loading: boolean;
  error: ApiErrorResponse<ServerErrorTypes> | null;
  errorMessage: string;
  list: NonNullable<T["payload"]> extends Array<unknown>
    ? NonNullable<T["payload"]>
    : ApiResponseList<NonNullable<T["payload"]>>;
  refresh: VoidFunction;
};

const usePayload: PayloadReturnType = ({ payload, error, refresh, loading }: Props) => {
  function getList() {
    if (Array.isArray(payload)) {
      return payload;
    }

    return payload?.data || [];
  }

  const list = getList();

  return {
    payload,
    error,
    errorMessage: error?.message || "",
    list,
    loading,
    refresh,
  };
};

export default usePayload;
