import React, { FC, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  Container,
  getPaginatedList,
  Modal,
  notification,
  Search,
  Table,
  useCall,
  useDevice,
  useFetch,
  useFilters,
  usePagination,
} from "@epcnetwork/core-ui-kit";

import { ParamsType } from "./source-details.types";
import { SOURCES_LIST_PAGE } from "constants/routes.constants";
import { deleteSource, getMediaBuysBySourceId, getSource } from "api";
import { entityName, filtersOptions, tableColumns } from "./source-details.constants";
import Row from "./table-row/table-row";
import SourcesForm from "../form/sources-form.page";
import Info from "./components/info";
import ListFilters from "./list-filters/list-filters";
import Breadcrumbs from "./breadcrumbs/breadcrumbs";

import styles from "./sources-details.module.css";

const SourcesDetailsPage: FC = () => {
  const history = useHistory();
  const { id = "" } = useParams<ParamsType>();
  const { setSearch, searchValue, queryString } = useFilters(filtersOptions);

  const {
    payload: payloadMedia,
    loading: loadingMediaBuy,
    error,
    refresh: refreshMediaBuy,
  } = useFetch(getMediaBuysBySourceId.setParams({ id }).setQueryParams(queryString), {
    dependencies: [queryString],
  });
  const { payload, loading, refresh } = useFetch(getSource.setParams({ sourceId: id }));

  const { onCallSuccess, onCallError, submit } = useCall(deleteSource);

  const { isMobileDevice } = useDevice();

  const pagination = usePagination(payloadMedia);
  const list = getPaginatedList(payloadMedia?.data);

  const [sourceEditId, setSourceEditId] = useState("");
  const [mediaBuyEditId, setMediaBuyEditId] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editSourceModalIsOpen, setEditSourceModalIsOpen] = useState(false);

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSourceEditId("");
  };

  const handleCloseEditSourceModal = () => {
    setEditSourceModalIsOpen(false);
    setMediaBuyEditId("");
  };

  const handleEdit = (sourceId: string) => {
    setModalIsOpen(true);
    setSourceEditId(sourceId);
  };

  const goToSources = () => {
    history.push(SOURCES_LIST_PAGE.path);
  };

  onCallSuccess(() => {
    notification.success("Source deleted", "Successfully deleted a source");
    goToSources();
  });

  onCallError((error) => {
    refresh();
    notification.error("Deleting error", `Source was ${error.message}`);
  });

  const handleDelete = (id: string) => {
    notification.confirm("Deleting a Source?", "Are you sure?", {
      onOk: () => {
        submit({ params: { sourceId: id } });
      },
      icon: "delete",
    });
  };

  const fullRefresh = () => {
    refresh();
    refreshMediaBuy();
  };

  return (
    <>
      <Breadcrumbs name={payload?.name} />
      <Container>
        {!loading && payload && (
          <Info item={payload} handleEdit={handleEdit} handleDelete={handleDelete} />
        )}
        <div className={styles.searchHeaderWrapper}>
          <Search className={styles.searchField} searchValue={searchValue} setSearch={setSearch} />
          <ListFilters />
        </div>
        <Table
          entityName={entityName}
          className={styles.table}
          columns={tableColumns(isMobileDevice)}
          list={list}
          loading={loadingMediaBuy}
          error={error?.message}
          refresh={refreshMediaBuy}
          pagination={pagination}
          multiSelect
          row={(item) => <Row key={item.name} item={item} />}
        />
        <Modal isOpen={modalIsOpen} setClose={handleCloseModal} contentClassName={styles.modal}>
          <SourcesForm
            id={sourceEditId}
            onCloseModal={handleCloseModal}
            refresh={refresh}
            refreshMediaBuy={refreshMediaBuy}
          />
        </Modal>
        <Modal
          isOpen={editSourceModalIsOpen}
          setClose={handleCloseEditSourceModal}
          contentClassName={styles.modal}
        >
          <SourcesForm
            id={mediaBuyEditId}
            onCloseModal={handleCloseEditSourceModal}
            refresh={fullRefresh}
          />
        </Modal>
      </Container>
    </>
  );
};

export default SourcesDetailsPage;
