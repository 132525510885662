import { object, string } from "yup";
import { ClientsFormInitialValuesType } from "./clients-form.types";

export const clientsFormInitialValues: ClientsFormInitialValuesType = {
  name: "",
  email: "",
  contactPerson: "",
  phone: "",
  comments: "",
};

export const clientsFormValidationSchema = object().shape({
  name: string().trim().required("This field is required"),
  email: string().trim().email("Email is not valid").required("This field is required"),
  contactPerson: string()
    .trim()
    .matches(/(\w.+\s).+/, "Enter both, first name and last name")
    .required("This field is required"),
  phone: string()
    .matches(new RegExp("^((?![a-zA-Z]).)*$"), "Phone number is not valid")
    .min(8, "Phone number should have minimum 8 characters")
    .max(15, "Phone number should have maximum 15 characters")
    .nullable(),
  comments: string().nullable(),
});
