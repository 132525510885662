import React, { FC } from "react";
import cn from "classnames";

import { CollapseState, CollapseStateKeys } from "../../source-details.types";

import styles from "../list-filters.module.css";
import arrowDownImg from "assets/images/arrow-down.svg";

type LabelProps = {
  labelName: CollapseStateKeys;
  isCollapseState: CollapseState;
  handleCollapseView: (key: string) => void;
};

const Label: FC<LabelProps> = ({ labelName, isCollapseState, handleCollapseView }: LabelProps) => {
  let label = "";
  switch (labelName) {
    case "createdAt":
      label = "created date";
      break;
    case "updatedAt":
      label = "updated date";
      break;
    case "clientId":
      label = "client";
      break;
    case "campaignId":
      label = "Campaign";
      break;
    default:
      label = labelName;
  }

  return (
    <div className={styles.collapseWrapper}>
      <div className={styles.leftPart}>
        <img
          src={arrowDownImg}
          alt=""
          onClick={() => handleCollapseView(labelName)}
          className={cn({ [styles.revertArrow]: isCollapseState[labelName].isOpen })}
        />
        <div className={styles.label}>{`By ${label}`}</div>
      </div>
    </div>
  );
};

export default Label;
