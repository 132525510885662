import React, { FC } from "react";
import { TextEllipsis, Button } from "@epcnetwork/core-ui-kit";

import { SourceModel } from "models";

import styles from "./info.module.css";
import pencilImg from "assets/images/pencil-grey.svg";
import deleteImg from "assets/images/delete.svg";

type InfoProps = {
  item: SourceModel;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
};

const Info: FC<InfoProps> = ({ item, handleEdit, handleDelete }: InfoProps) => {
  const { activeMediaBuysCount, allMediaBuysCount, inactiveMediaBuysCount, name, id } = item;

  const handleEditSource = () => handleEdit(id);
  const handleDeleteSource = () => handleDelete(id);

  return (
    <div className={styles.item}>
      <div className={styles.row}>
        <div className={styles.titleRight}>SOURCE NAME</div>
        <div className={styles.rightLabel}>
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
            {name}
          </TextEllipsis>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.titleRight}>ALL MEDIA BUYS</div>
        <div className={styles.rightLabel}>
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
            {allMediaBuysCount}
          </TextEllipsis>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.titleRight}>ACTIVE MEDIA BUYS</div>
        <div className={styles.rightLabel}>
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
            {activeMediaBuysCount}
          </TextEllipsis>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.titleRight}>INACTIVE MEDIA BUYS</div>
        <div className={styles.rightLabel}>
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
            {inactiveMediaBuysCount}
          </TextEllipsis>
        </div>
      </div>
      <div className={styles.iconsWrapper}>
        <Button
          btnSize="small"
          appearance="text"
          className={styles.actionButton}
          onClick={handleEditSource}
        >
          <img src={pencilImg} alt="" className={styles.actionIcon} />
        </Button>
        <Button
          btnSize="small"
          appearance="text"
          className={styles.actionButton}
          onClick={handleDeleteSource}
        >
          <img src={deleteImg} alt="" className={styles.actionIcon} />
        </Button>
      </div>
    </div>
  );
};
export default Info;
