import {
  CollapseState,
  FiltersType,
  SearchFilterType,
  StatusTypes,
  StatusTypesKeys,
  TableColumnType,
} from "./source-details.types";
import { SelectOption } from "@epcnetwork/core-ui-kit";

export const entityName = "sources-media-buys-table";

export const tableColumns = (isMobile: boolean): TableColumnType[] => [
  { label: "MEDIA BUY", queryKey: "mediaBuy", required: true },
  { label: "CAMPAIGN", queryKey: "campaign" },
  { label: "CLIENT", queryKey: "client", selected: !isMobile },
  { label: "MEDIA BUYS STATUS", queryKey: "isActive" },
];

export const filtersOptions: SearchFilterType = { searchKey: "search" };

export const statusOptions = Object.entries(StatusTypes).map<
  SelectOption<keyof typeof StatusTypes>
>(([key, value]) => ({
  value: key as StatusTypesKeys,
  label: value,
}));

export const initialFilters: FiltersType = {
  campaignId: [],
  clientId: [],
  status: [],
  createdAt: null,
  updatedAt: null,
};

export const initialCollapseState: CollapseState = {
  campaignId: {
    isOpen: true,
  },
  clientId: {
    isOpen: true,
  },
  status: {
    isOpen: true,
  },
  createdAt: {
    isOpen: true,
  },
  updatedAt: {
    isOpen: true,
  },
};
