import React, { FC, useState, useCallback } from "react";
import {
  Filters,
  Select,
  DateInput,
  useAutocomplete,
  CollapseSelfControlled,
} from "@epcnetwork/core-ui-kit";

import { getCampaigns, getClients } from "api";
import { initialCollapseState, initialFilters, statusOptions } from "../source-details.constants";
import { appTimezone } from "constants/user.constants";
import { CollapseState } from "../source-details.types";
import Label from "./components/label";

import styles from "./list-filters.module.css";

const ListFilters: FC = () => {
  const useCampaignsAutocomplete = useAutocomplete(getCampaigns, "id", "name");
  const useClientsAutocomplete = useAutocomplete(getClients, "id", "name");

  const [isCollapseState, setIsCollapseState] = useState<CollapseState>(initialCollapseState);

  const handleCollapseView = useCallback(
    (key: string) => {
      const stateCollapse: CollapseState = { ...isCollapseState };
      stateCollapse[key].isOpen = !stateCollapse[key].isOpen;
      setIsCollapseState(stateCollapse);
    },
    [isCollapseState],
  );

  return (
    <Filters initialFilters={initialFilters}>
      {({ getDatePickerDate, setDatePickerDate, getSelect, setSelect }) => {
        const [startFrom, startTo] = getDatePickerDate("createdAt");
        const [endFrom, endTo] = getDatePickerDate("updatedAt");

        return (
          <div>
            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={
                  <Label
                    labelName="clientId"
                    handleCollapseView={handleCollapseView}
                    isCollapseState={isCollapseState}
                  />
                }
                initiallyOpened={isCollapseState["clientId"].isOpen}
                hideDuration={150}
                openDuration={150}
              >
                <Select
                  selectedOptionsKeys={getSelect("clientId", true)}
                  onChange={setSelect("clientId", true)}
                  isMulti
                  disableError
                  asyncOptions={useClientsAutocomplete}
                  placeholder="Select client"
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>

            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={
                  <Label
                    labelName="campaignId"
                    handleCollapseView={handleCollapseView}
                    isCollapseState={isCollapseState}
                  />
                }
                initiallyOpened={isCollapseState["campaignId"].isOpen}
                hideDuration={150}
                openDuration={150}
              >
                <Select
                  selectedOptionsKeys={getSelect("campaignId", true)}
                  onChange={setSelect("campaignId", true)}
                  isMulti
                  disableError
                  asyncOptions={useCampaignsAutocomplete}
                  placeholder="Select campaign"
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>

            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={
                  <Label
                    labelName="status"
                    handleCollapseView={handleCollapseView}
                    isCollapseState={isCollapseState}
                  />
                }
                initiallyOpened={isCollapseState["status"].isOpen}
                hideDuration={150}
                openDuration={150}
              >
                <Select
                  selectedOptionsKeys={getSelect("status", true)}
                  onChange={setSelect("status", true)}
                  isMulti
                  disableError
                  options={statusOptions}
                  placeholder="Select status"
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>

            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={
                  <Label
                    labelName="createdAt"
                    handleCollapseView={handleCollapseView}
                    isCollapseState={isCollapseState}
                  />
                }
                initiallyOpened={isCollapseState["createdAt"].isOpen}
                hideDuration={150}
                openDuration={150}
              >
                <DateInput
                  className={styles.inputDate}
                  dateFrom={startFrom}
                  dateTo={startTo}
                  onChange={setDatePickerDate("createdAt", true)}
                  isMulti
                  disableError
                  timezone={appTimezone}
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>

            <Filters.ContentItem>
              <CollapseSelfControlled
                triggerElement={
                  <Label
                    labelName="updatedAt"
                    handleCollapseView={handleCollapseView}
                    isCollapseState={isCollapseState}
                  />
                }
                initiallyOpened={isCollapseState["updatedAt"].isOpen}
                hideDuration={150}
                openDuration={150}
              >
                <DateInput
                  className={styles.inputDate}
                  dateFrom={endFrom}
                  dateTo={endTo}
                  onChange={setDatePickerDate("updatedAt", true)}
                  isMulti
                  disableError
                  timezone={appTimezone}
                />
              </CollapseSelfControlled>
            </Filters.ContentItem>
          </div>
        );
      }}
    </Filters>
  );
};

export default ListFilters;
