import { TableColumnsType } from "types";

export const TABLE_COLUMNS = (isMobile: boolean): TableColumnsType[] => [
  { label: "ID", queryKey: "", selected: !isMobile },
  { label: "Name", queryKey: "name", required: true },
  { label: "Email", queryKey: "email", selected: !isMobile },
  { label: "All campaigns", queryKey: "campaignsAll", required: true },
  { label: "Active", queryKey: "campaignsActive", selected: !isMobile },
  { label: "Pending", queryKey: "campaignsPending", selected: !isMobile },
  { label: "" },
];

export const filtersOptions = { searchKey: "search" };

export const entityName = "clients-table";
