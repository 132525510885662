import { TableColumnsType } from "types";
import { CollapseState, FiltersType, SearchFilterType } from "./sources-list.types";

export const filtersOptions: SearchFilterType = { searchKey: "search" };

export const tableColumns = (isMobile: boolean): TableColumnsType[] => [
  { label: "Source", queryKey: "source", required: true },
  { label: "ALL MEDIA BUYS", queryKey: "allMediaBuysCount", required: true },
  { label: "ACTIVE MEDIA BUYS", queryKey: "activeMediaBuysCount", selected: !isMobile },
  { label: "INACTIVE MEDIA BUYS", queryKey: "inactiveMediaBuysCount", selected: !isMobile },
  { label: "Creating date", queryKey: "createdAt", selected: !isMobile },
  { label: "", queryKey: "" },
];

export const getQueryKeys = (isMobile: boolean): string[] =>
  tableColumns(isMobile).map(({ queryKey }) => `order[${queryKey}]`);

export const entityName = "sources-table";

export const initialFilters: FiltersType = {
  createdAt: null,
  updatedAt: null,
};

export const initialCollapseState: CollapseState = {
  created: {
    isOpen: true,
  },
  updated: {
    isOpen: true,
  },
};
