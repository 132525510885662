import React, { FC, memo } from "react";
import { Route as RouterRoute } from "react-router-dom";

import { AuthRoute, Navigation } from "components";
import { AuthorizationEntityName, AuthorizationActionType } from "models";
import { useAuthorization } from "hooks";

import {
  LOGIN_PAGE,
  LOGOUT_PAGE,
  FORGOT_PASSWORD_PAGE,
  RESET_PASSWORD_PAGE,
  REGISTRATION_PAGE,
  NOT_FOUND_PAGE,
  SECRETS_PAGE,
  EXTERNAL_VENDORS_PAGE,
  CLIENTS_LIST_PAGE,
  CAMPAIGNS_LIST_PAGE,
  CAMPAIGNS_DETAILS_PAGE,
  SOURCES_LIST_PAGE,
  CLIENTS_CAMPAIGNS_LIST,
  SOURCES_DETAILS_PAGE,
  USERS_LIST_PAGE,
  ROLES_LIST_PAGE,
  STRUCTURE_LIST_PAGE,
  DEFAULT_SUPER_ADMIN_PAGE,
  ORGANIZATION_LIST_PAGE,
} from "constants/routes.constants";

import NotFound from "pages/404";
import Login from "pages/auth/login";
import Logout from "pages/auth/logout";
import SecretsPage from "pages/secrets/list/secrets-list.page";
import CampaignsListPage from "pages/campaigns/list";
import CampaignsDetailsPage from "pages/campaigns/details";
import SourcesListPage from "pages/sources/list/sources-list-page";
import ExternalVendorsListPage from "pages/external-vendors/list/external-vendors-list.page";
import ClientsPage from "pages/clients/list";
import OrganizationsListPage from "pages/settings/organizations/list";
import { DEFAULT_PAGE } from "constants/routes.constants";
import SourcesDetailsPage from "pages/sources/details/sources-details.page";
import { ForgotPassword, ResetPassword } from "pages/auth/forgot-password";
import Registration from "pages/auth/registration";
import UsersListPage from "pages/settings/users/list/users-list.page";
import RolesListPage from "pages/settings/roles/list/roles-list.page";
import StructureListPage from "pages/settings/structure/structure-list.page";

export interface RouteConfig {
  path: string;
  component: FC<unknown>;
  name: string;
  exact: boolean;
  auth: boolean;
  showNavigation: boolean;
  authEntity?: AuthorizationEntityName;
  authAction?: AuthorizationActionType;
  isAdminModule?: boolean;
}

export const Route: FC<RouteConfig> = memo(
  ({ component, showNavigation, authEntity, ...route }: RouteConfig) => {
    const ability = useAuthorization();

    const RouteComponent = route.auth ? AuthRoute : RouterRoute;
    const isAuthorized =
      route.auth && authEntity ? ability.can(AuthorizationActionType.read, authEntity) : true;

    return (
      <RouteComponent
        {...route}
        render={(props: unknown) => (
          <Navigation
            component={component}
            showNavigation={showNavigation}
            isAuthorized={isAuthorized}
            {...props}
          />
        )}
      />
    );
  },
);

export const routes: RouteConfig[] = [
  {
    ...LOGIN_PAGE,
    component: Login,
  },
  {
    ...LOGOUT_PAGE,
    component: Logout,
  },
  {
    ...FORGOT_PASSWORD_PAGE,
    component: ForgotPassword,
  },
  {
    ...RESET_PASSWORD_PAGE,
    component: ResetPassword,
  },
  {
    ...REGISTRATION_PAGE,
    component: Registration,
  },
  {
    ...DEFAULT_PAGE,
    component: ClientsPage,
    authEntity: AuthorizationEntityName.clients,
  },
  {
    ...SECRETS_PAGE,
    component: SecretsPage,
    authEntity: AuthorizationEntityName.secrets,
  },
  {
    ...CAMPAIGNS_LIST_PAGE,
    component: CampaignsListPage,
    authEntity: AuthorizationEntityName.campaigns,
  },
  {
    ...CAMPAIGNS_DETAILS_PAGE,
    component: CampaignsDetailsPage,
    authEntity: AuthorizationEntityName.campaigns,
  },
  {
    ...SOURCES_LIST_PAGE,
    component: SourcesListPage,
    authEntity: AuthorizationEntityName.sources,
  },
  {
    ...SOURCES_DETAILS_PAGE,
    component: SourcesDetailsPage,
    authEntity: AuthorizationEntityName.sources,
  },
  {
    ...CLIENTS_LIST_PAGE,
    component: ClientsPage,
    authEntity: AuthorizationEntityName.clients,
  },
  {
    ...USERS_LIST_PAGE,
    component: UsersListPage,
    authEntity: AuthorizationEntityName.users,
  },
  {
    ...ROLES_LIST_PAGE,
    component: RolesListPage,
    authEntity: AuthorizationEntityName.roles,
  },
  {
    ...STRUCTURE_LIST_PAGE,
    component: StructureListPage,
    authEntity: AuthorizationEntityName.structure,
  },
  {
    ...CLIENTS_CAMPAIGNS_LIST,
    component: CampaignsListPage,
    authEntity: AuthorizationEntityName.campaigns,
  },
  {
    ...EXTERNAL_VENDORS_PAGE,
    component: ExternalVendorsListPage,
    authEntity: AuthorizationEntityName.vendors,
  },
  {
    ...ORGANIZATION_LIST_PAGE,
    component: OrganizationsListPage,
    isAdminModule: true,
  },

  {
    ...DEFAULT_SUPER_ADMIN_PAGE,
    component: OrganizationsListPage,
    isAdminModule: true,
  },

  {
    ...NOT_FOUND_PAGE,
    component: NotFound,
  },
];
