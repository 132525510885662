import { object, string, array } from "yup";

import { CampaignsTypes } from "models";
import { InitialValues, InitialValuesSourceForm } from "./media-buy-form.types";

export const initialValues: InitialValues = {
  name: "",
  redirectUrl: "",
  externalVendors: [],
  type: null,
  sourceId: "",
  siteId: "",
  postbackUrl: "",
};

export const initialValuesSourceForm: InitialValuesSourceForm = {
  sourceId: "",
  externalVendors: [],
};

export const validationSchema = object().shape({
  name: string().min(3, "Name must have minimum 3 characters").required("This field is required"),
  redirectUrl: string()
    .url("Redirect url is not valid")
    .min(2, "Redirect url must have minimum 2 characters")
    .required("This field is required"),
  externalVendors: array(string().required("External Vendor is required"))
    .min(1, "External Vendor is required")
    .typeError("External Vendor is required")
    .required("External Vendor is required"),
  type: string().required("Type is required").typeError("Type is required"),
  sourceId: string()
    .required("SourceId is required")
    .typeError("SourceId is required")
    .required("SourceId is required"),
  postbackUrl: string().url("Postback url is not valid"),
});

export const validationSourceSchema = object().shape({
  sourceId: string()
    .required("SourceId is required")
    .typeError("SourceId is required")
    .required("SourceId is required"),
});

export const validationVendorSchema = object().shape({
  externalVendors: array(string().required("External Vendor is required"))
    .min(1, "External Vendor is required")
    .typeError("External Vendor is required")
    .required("External Vendor is required"),
});

export const PLACEHOLDER_VENDOR = "Choose External Vendor from the list";
export const PLACEHOLDER_SOURCE = "Choose source from the list";

export const radioOptions = [
  {
    label: CampaignsTypes.CTO,
    value: CampaignsTypes.CTO,
  },
  {
    label: CampaignsTypes.CPC,
    value: CampaignsTypes.CPC,
  },
  {
    label: CampaignsTypes.COREG,
    value: CampaignsTypes.COREG,
  },
];
