export const initialSearchFilters = {
  searchKey: "search",
};

export const entityName = "organizations-table";

export const TABLE_COLUMNS = [
  { label: "Id", queryKey: "id" },
  { label: "Organization name", queryKey: "name" },
  { label: "Creation Date", queryKey: "createdAt" },
  { label: "Link", queryKey: "" },
  { label: "" },
];
