import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  usePagination,
  useFilters,
  useFetch,
  useQuery,
  useDevice,
  getPaginatedList,
  findKeyByValue,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import { getCampaigns } from "api";
import { QueryType, FiltersQueryType, Tabs, ParamsType } from "./campaigns-list.types";
import { initialSearchFilters, TABS, getQueryKeys, tabStatesMap } from "./campaigns-list.constants";

interface Output {
  searchValue: string;
  editId: string;
  campaignId: string;
  activeTab: number;
  loading: boolean;
  isDuplication: boolean;
  isInGanttChartView: boolean;
  isCampaignPage: boolean;
  isOpenCreateEditModal: boolean;
  isMobileDevice: boolean;
  error: string | undefined;
  tabs: Tabs;
  pagination: ReturnType<typeof usePagination>;
  handleAddCampaign: VoidFunction;
  handleCloseAddEditModal: VoidFunction;
  refresh: VoidFunction;
  setSearch: (value: string) => void;
  handleDuplicateClick: (clientId: string, campaignId: string) => void;
  handleEdit: (clientId: string, campaignId: string) => void;
  handleChangeTab: (tabIndex: number) => void;
  handleTriggerChart: VoidFunction;
}

const useCampaignsListHook = (): Output => {
  const { id = "" } = useParams<ParamsType>();
  const { isMobileDevice } = useDevice();

  const { queryString, searchValue, setSearch, handleClearFilters, updateQueryParams, query } =
    useFilters({
      searchKey: initialSearchFilters.searchKey,
      nonClearableKeys: getQueryKeys(isMobileDevice),
      initialState: initialSearchFilters.initialState,
    });

  const { setQueryParam } = useQuery<QueryType & FiltersQueryType>();

  const { payload, loading, actions, error, refresh } = useFetch(
    getCampaigns.setQueryParams(queryString),
    {
      dependencies: [queryString],
    },
  );

  const list = getPaginatedList(payload?.data);

  const pagination = usePagination(payload);

  const isCampaignPage = !id;
  const isCampaignByClient = queryString.includes("clientId");

  const checkActiveTab = Number(findKeyByValue(tabStatesMap, query.status))
    ? Number(findKeyByValue(tabStatesMap, query.status))
    : 0;

  const [isOpenCreateEditModal, setIsOpenCreateEditModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [activeTab, setActiveTab] = useState<number>(checkActiveTab);
  const [isDuplication, setIsDuplication] = useState(false);
  const [isInGanttChartView, setIsInGanttChartView] = useState(false);

  useDidUpdate(() => {
    setActiveTab(checkActiveTab);
  }, [checkActiveTab]);

  const tabs: Tabs = [
    {
      name: TABS.All,
      list,
      status: "",
      count: payload?.allCampaignCount || 0,
    },
    {
      name: TABS.Active,
      list,
      status: "active",
      count: payload?.activeCampaignCount || 0,
    },
    {
      name: TABS.Paused,
      list,
      status: "paused",
      count: payload?.pausedCampaignCount || 0,
    },
    {
      name: TABS.Pending,
      list,
      status: "pending",
      count: payload?.pendingCampaignCount || 0,
    },
    {
      name: TABS.Completed,
      list,
      status: "completed",
      count: payload?.completedCampaignCount || 0,
    },
  ];

  const handleChangeTab = (tabIndex: number) => {
    updateQueryParams({
      status: tabStatesMap[tabIndex],
      offset: 0,
    });
    setActiveTab(tabIndex);
    actions.setData(null);

    if (!tabs[tabIndex].status && !isCampaignByClient) {
      return handleClearFilters();
    }

    if (!tabs[tabIndex].status && isCampaignByClient) {
      setQueryParam("status", null);
      return;
    }
  };

  const handleAddCampaign = () => setIsOpenCreateEditModal(true);

  const handleCloseAddEditModal = () => {
    setIsDuplication(false);
    setIsOpenCreateEditModal(false);
    setEditId("");
    setCampaignId("");
  };

  const handleEdit = (clientId: string, campaignId: string) => {
    setEditId(clientId);
    setCampaignId(campaignId);
    setIsOpenCreateEditModal(true);
  };

  const handleDuplicateClick = (clientId: string, campaignId: string) => {
    setIsDuplication(true);
    handleEdit(clientId, campaignId);
  };

  const handleTriggerChart = () => {
    setIsInGanttChartView((prevState) => !prevState);
    handleClearFilters();
  };

  return {
    searchValue,
    editId,
    campaignId,
    activeTab,
    loading,
    isDuplication,
    isCampaignPage,
    isOpenCreateEditModal,
    isMobileDevice,
    error: error?.message,
    tabs,
    pagination,
    handleAddCampaign,
    handleCloseAddEditModal,
    refresh,
    setSearch,
    handleDuplicateClick,
    handleEdit,
    handleChangeTab,
    isInGanttChartView,
    handleTriggerChart,
  };
};

export default useCampaignsListHook;
