import React, { FC } from "react";
import {
  useQuery,
  TableRow,
  TableCell,
  notification,
  MenuButton,
  Menu,
  useCall,
  TextEllipsis,
} from "@epcnetwork/core-ui-kit";

import { deleteClient } from "api";
import { PermitComponent, ImageLink } from "components";
import { ClientModel, AuthorizationEntityName, AuthorizationActionType } from "models";
import { CAMPAIGNS_LIST_PAGE } from "constants/routes.constants";

import styles from "./table-row.module.css";

type Props = {
  item: ClientModel;
  handleEdit: (id: string) => void;
  refresh: VoidFunction;
};

const Row: FC<Props> = ({ item, handleEdit, refresh }: Props) => {
  const { stringify } = useQuery();

  const { id, name, email, campaigns, campaignsActive, campaignsPending, campaignsAll } = item;

  const clientQuery = { clientId: [id] };

  const linkToCampaignList = `${CAMPAIGNS_LIST_PAGE.path}${stringify(clientQuery)}`;

  const { submit, onCallSuccess, onCallError } = useCall(deleteClient);

  onCallSuccess(() => {
    notification.success("Client deleted", "Successfully deleted Client");
    refresh();
  });

  onCallError((error) => {
    notification.error("Deleting error", error.message);
    refresh();
  });

  const handleRemoveItem = () => {
    const entities = [];
    const mediaBuysCount = campaigns.reduce((acc, current) => {
      if (!current) return acc;

      acc += current.allMediaBuysCount;
      return acc;
    }, 0);

    if (campaigns.length) {
      entities.push({
        text: `See all Campaigns`,
        link: linkToCampaignList,
      });
    }

    if (mediaBuysCount) {
      entities.push({ text: `See all Media Buys`, link: "" });
    }

    const message = campaigns.length
      ? `Deleting Client "${name}" you automatically delete connected:`
      : "You will not be able to recover it";

    notification.confirm("Delete Client ?", message, {
      onOk: () => {
        submit({ params: { clientId: id } });
      },
      okText: "Delete",
      icon: "delete",
      entities,
    });
  };

  return (
    <TableRow id={id}>
      <TableCell>#{id}</TableCell>
      <TableCell className={styles.nameCell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {name}
        </TextEllipsis>
      </TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>
        <ImageLink to={linkToCampaignList}>{campaignsAll}</ImageLink>
      </TableCell>
      <TableCell>{campaignsActive}</TableCell>
      <TableCell>{campaignsPending}</TableCell>
      <TableCell className={styles.hideOnMobile}>
        <PermitComponent
          authAction={AuthorizationActionType.update}
          authEntity={AuthorizationEntityName.clients}
          disabled
        >
          <Menu>
            <MenuButton onClick={() => handleEdit(id)} icon="edit">
              Edit
            </MenuButton>
            <MenuButton appearance="delete" onClick={handleRemoveItem} icon="delete">
              Delete
            </MenuButton>
          </Menu>
        </PermitComponent>
      </TableCell>
    </TableRow>
  );
};

export default Row;
