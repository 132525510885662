import React, { memo, useContext } from "react";
import { Button } from "@epcnetwork/core-ui-kit";

import { NavigationContext } from "../navigation/navigation";
import Topbar from "../topbar/topbar";

import styles from "./navbar.module.css";
import openedImg from "assets/images/navigation-menu-opened.svg";
import closedImg from "assets/images/navigation-menu-closed.svg";

type Props = {
  toggleSidebar: VoidFunction;
  isSidebarOpen: boolean;
};

export const Navbar = memo(({ toggleSidebar, isSidebarOpen }: Props) => {
  const context = useContext(NavigationContext);
  const { activeRoute } = context || {};
  const openHamburgerStyles = isSidebarOpen ? styles.open : "";
  const menuIcon = isSidebarOpen ? closedImg : openedImg;

  return (
    <>
      <Topbar />
      <header className={styles.container} data-testid="navbar">
        <Button
          appearance="transparent"
          className={`${styles.hamburger} ${openHamburgerStyles}`}
          onClick={toggleSidebar}
        >
          <img src={menuIcon} alt="" />
        </Button>
        <div className={styles.title}>
          {activeRoute?.name} {isSidebarOpen}
        </div>
        <div className={styles.spacer} />
      </header>
    </>
  );
});

export default Navbar;
