import { useState } from "react";
import { FormikHelpers } from "formik";
import {
  NonNullableKeys,
  useAutocomplete,
  useFormValues,
  useSubmit,
  notification,
} from "@epcnetwork/core-ui-kit";

import { getSecret, getSecretProviderList, postSecret, putSecret } from "api";
import { FormPropsType, InitialValues, SecretAPIContentType } from "./secrets-form.types";
import { initialValues } from "./secrets-form.constants";

interface Output {
  formProps: FormPropsType;
  providersAutocomplete: ReturnType<typeof useAutocomplete>;
  error: string;
  onSubmit: (values: InitialValues, helpers: FormikHelpers<InitialValues>) => Promise<void>;
  clearErrorMessage: VoidFunction;
}

const useSecretsFormHook = (onCloseModal: () => void, id: string, refresh: () => void): Output => {
  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    postSecret,
    putSecret.setParams({ secretId: id }),
    !!id,
  );
  const { formProps, mapInitialValues } = useFormValues(
    initialValues,
    getSecret.setParams({ secretId: id }),
    !!id,
  );
  const providersAutocomplete = useAutocomplete(getSecretProviderList, "key", "value", {});

  const [error, setError] = useState("");

  const onSubmit = onSubmitMapping((values: NonNullableKeys<InitialValues>) => {
    return {
      name: values.secretName,
      apiType: values.apiType,
      properties: values.apiTypeContent,
    };
  });

  onSubmitSuccess(() => {
    const title = id ? "Secret updated" : "Secret created";
    const message = id ? "Successfully updated secret" : "Successfully created secret";
    notification.success(title, message);
    onCloseModal();
    refresh();
  });

  onSubmitError((err) => {
    setError(err.message);
  });

  mapInitialValues((payload) => {
    return {
      secretName: payload.name,
      apiType: payload.apiType,
      apiTypeContent: payload.properties as SecretAPIContentType,
    };
  });

  const clearErrorMessage = () => setError("");

  return {
    formProps,
    providersAutocomplete,
    error,
    onSubmit,
    clearErrorMessage,
  };
};

export default useSecretsFormHook;
