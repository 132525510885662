import React, { FC, ReactNode } from "react";
import cn from "classnames";
import { TextEllipsis } from "@epcnetwork/core-ui-kit";

import styles from "./view-external-vendor-content.module.css";

type Props = {
  children?: ReactNode;
  title: string;
  body?: string;
  className?: string;
};

const ContentRow: FC<Props> = ({
  title = "",
  body = "",
  children = null,
  className = "",
}: Props) => {
  const calendarStyle = children ? styles.calendarBody : "";
  return (
    <div className={styles.row}>
      <span className={styles.rowName}>{title}:</span>
      <TextEllipsis
        tooltipTrigger="hover"
        tooltipPosition="bottom-left"
        className={cn(className, styles.body, calendarStyle)}
      >
        {children}
        {body}
      </TextEllipsis>
    </div>
  );
};

export default ContentRow;
