import React, { FC } from "react";
import { format } from "date-fns";
import cn from "classnames";
import { Button, Table, TextEllipsis, useFetch } from "@epcnetwork/core-ui-kit";

import { getMember } from "api";
import { getDateWithTimezone, getNameAndLastName } from "utils";
import { TABLE_COLUMNS } from "./users-details.constants";
import TableRow from "./table-row/table-row";

import styles from "./users-details.module.css";
import userImg from "assets/images/user.svg";
import calendarImg from "assets/images/calendar-grey.svg";
import clockImg from "assets/images/clock-grey.svg";

type Props = {
  id: string;
  handleCloseModal: VoidFunction;
};

const UsersDetailsPage: FC<Props> = ({ id, handleCloseModal }: Props) => {
  const { payload, loading, refresh, error } = useFetch(getMember.setParams({ memberId: id }));
  if (!payload) return null;

  const { user, createdAt } = payload;
  const date = getDateWithTimezone(createdAt);

  return (
    <div className={styles.container}>
      <div className={styles.pageTitle}>View</div>
      <div className={styles.userWrapper}>
        <div className={styles.imgWrapper}>
          <img src={userImg} alt="" className={styles.userImg} />
          <div className={styles.status} />
        </div>
        <div className={cn(styles.info, styles.userInfo)}>
          <div className={styles.row}>
            <div className={styles.title}>User:</div>
            <TextEllipsis
              tooltipTrigger="hover"
              tooltipPosition="bottom-left"
              className={styles.label}
            >
              {getNameAndLastName(user?.name, user?.lastname)}
            </TextEllipsis>
          </div>
          <div className={styles.row}>
            <div className={styles.title}>Email:</div>
            <TextEllipsis
              tooltipTrigger="hover"
              tooltipPosition="bottom-left"
              className={styles.label}
            >
              {user?.email || ""}
            </TextEllipsis>
          </div>
        </div>
        <div className={styles.info}>
          <div className={cn(styles.row, styles.createdAt)}>
            <div className={styles.titleDate}>Created at:</div>
            <div className={styles.date}>
              <img src={calendarImg} alt="" />
              {format(date, "EEE, MMM dd, yyyy")}
              <img src={clockImg} alt="" />
              {format(date, "hh:mm")}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.tableHeader}>Group access</div>
      <div className={styles.tableWrapper}>
        <Table
          columns={TABLE_COLUMNS}
          list={[payload]}
          error={error?.message}
          loading={loading}
          refresh={refresh}
          contentClassName={styles.content}
          isTabTable
          row={(item) => <TableRow item={item} />}
        />
      </div>

      <Button className={styles.button} onClick={handleCloseModal}>
        Okay
      </Button>
    </div>
  );
};

export default UsersDetailsPage;
