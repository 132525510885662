import React, { memo, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import cn from "classnames";
import { Button, Tooltip, Select, useCall, SelectOption } from "@epcnetwork/core-ui-kit";

import { changeOrganization } from "api";
import { useAuthorization } from "hooks";
import {
  AuthorizationActionType,
  AuthorizationEntityName,
  RegisteredOrganizationModel,
} from "models";
import { NavigationContext } from "../navigation/navigation";
import {
  ME_ROLE,
  TOKEN_STORAGE_FIELD,
  REFRESH_TOKEN_STORAGE_FIELD,
} from "constants/auth.constants";
import { SUPER_ADMIN_OPTIONS } from "constants/auth.constants";
import Menu from "../navbar/components/menu";
import store, { RootState, setToken, setUser } from "store";

import styles from "./topbar.module.css";
import userImg from "assets/images/user.svg";

export const groupOptions = [
  { value: "all", label: "All group" },
  { value: "admin", label: "Admin group" },
];

export const Topbar = memo(() => {
  const history = useHistory();
  const ability = useAuthorization();
  const { submit, onCallSuccess } = useCall(changeOrganization);
  const context = useContext(NavigationContext);

  const isShowOrganization = ability.can(
    AuthorizationActionType.read,
    AuthorizationEntityName.organizationSwitcher,
  );
  const isShowGroup = ability.can(
    AuthorizationActionType.read,
    AuthorizationEntityName.groupSwitcher,
  );

  if (!context) {
    return null;
  }

  const { organizationAutocomplete } = context;

  const [_, setGroup] = useState<{ value: string; label: string }>(groupOptions[0]);
  const { user } = useSelector((state: RootState) => state.auth);
  const isSuperAdmin = user?.type === ME_ROLE.super_admin;

  const currentOrganization = organizationAutocomplete.fetchOptions.filter(
    (org: RegisteredOrganizationModel) => {
      if (isSuperAdmin && !user?.member) {
        return org.id === "-1";
      }

      return org.id === user?.member?.organizationId;
    },
  );

  const handleChangeOrganization = (option: SelectOption) => {
    if (currentOrganization[0]?.id === option.value) return false;

    if (option.value === SUPER_ADMIN_OPTIONS.value && user) {
      const currentUser = {
        ...user,
        member: null,
      };

      store.dispatch(setUser(currentUser));
      return history.push("/");
    }

    submit({ data: { organizationId: +option.value } });
  };

  onCallSuccess((payload) => {
    localStorage.setItem(TOKEN_STORAGE_FIELD, payload.token);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_FIELD, payload.refreshToken);

    store.dispatch(setToken(payload.token));
    window.location.reload();
  });

  return (
    <div className={styles.container}>
      <div className={styles.selectWrapper}>
        {organizationAutocomplete.fetchOptions.length >= 2 && isShowOrganization && (
          <div className={styles.wrapper}>
            <span className={cn(styles.tip, styles.orgTip)}>Switch to organization</span>
            <Select
              asyncOptions={organizationAutocomplete}
              selectedOptionsKeys={currentOrganization[0]?.id}
              onChange={handleChangeOrganization}
              disableSingleOptionUncheck
              disableClearing
            />
          </div>
        )}
        {isShowGroup && (
          <div className={styles.wrapper}>
            <span className={styles.tip}>Switch to group</span>
            <Select
              onChange={(option) => setGroup(option)}
              options={groupOptions}
              selectedOptionsKeys={groupOptions[0].value}
              disableClearing
            />
          </div>
        )}
      </div>

      <Tooltip
        triggerElement={
          <Button appearance="transparent" className={styles.userBtn}>
            <img src={userImg} alt="" />
          </Button>
        }
        triggerClassName={styles.triggerBtn}
        position="bottom-right"
        trigger="click"
      >
        <Menu history={history} />
      </Tooltip>
    </div>
  );
});

export default Topbar;
