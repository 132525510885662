import React, { FC } from "react";
import { useFormikContext } from "formik";
import { FormField, Label, useDidMount, useWillUnmount } from "@epcnetwork/core-ui-kit";

import { VendorFormInitialValuesType } from "pages/external-vendors/form/vendors-form.types";
import { ContentTypes, HttpMethods } from "constants/forms.constants";
import RestApiDispatcher from "./rest-api-dispatcher";
import UrlParameter, { createInitialUrlParameter } from "./components/url-parameter";
import { resetFormState } from "../vendors-form.utils";

import urlImf from "assets/images/url.svg";

const options = [
  {
    value: HttpMethods.get,
    label: HttpMethods.get,
  },
  {
    value: HttpMethods.post,
    label: HttpMethods.post,
  },
  {
    value: HttpMethods.put,
    label: HttpMethods.put,
  },
];

const RestApi: FC = (): JSX.Element => {
  const { setFormikState } = useFormikContext<VendorFormInitialValuesType>();

  useDidMount(() => {
    setFormikState((prevState) => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          url: "",
          contentType: ContentTypes.json,
          httpMethod: HttpMethods.get,
          queryParams: createInitialUrlParameter(),
          bodyParams: undefined,
        },
      };
    });
  });

  useWillUnmount(() => setFormikState(resetFormState));

  return (
    <>
      <FormField type="text" name="url" label="URL" placeholder="" required icon={urlImf} />
      <UrlParameter btnName="Add URL Parameter" prefix="queryParams" />
      <Label text="Http Method" isRequired floating={false} />
      <FormField type="radio" name="httpMethod" options={options} />
      <RestApiDispatcher />
    </>
  );
};

export default RestApi;
