import { array, object, string, number, AnyObjectSchema } from "yup";

import { Values } from "./users-form.types";

export const initialData: Values = {
  email: "",
  items: [{ role: 0, groupId: undefined }],
};

export const initialContent = [
  {
    id: 0,
    group: { value: "", label: "" },
    role: { value: "", label: "" },
  },
];

const groupIdValidation = (requiredMessage = "This field is required") => {
  return string().when("role", (role) => {
    return role && role !== 50
      ? string().min(1, requiredMessage).typeError(requiredMessage).required(requiredMessage)
      : string().notRequired();
  });
};

export const validationSchema = (): AnyObjectSchema =>
  object().shape({
    email: string().email("Email is not valid").required("Email is required"),
    items: array().of(
      object().shape({
        role: number()
          .min(1, "This field is required")
          .typeError("This field is required")
          .required("This field is required"),
        groupId: groupIdValidation(),
      }),
    ),
  });
