import { FormikState } from "formik";
import { VendorFormInitialValuesType } from "./vendors-form.types";

export const resetFormState = <T extends FormikState<VendorFormInitialValuesType>>(
  prevState: T,
): T => ({
  ...prevState,
  values: {
    name: prevState.values.name,
    apiType: prevState.values.apiType,
    list: null,
  },
});
