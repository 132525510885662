import { List } from "@epcnetwork/core-ui-kit";

import {
  ExternalVendorsModel,
  ExternalVendorsProvider,
  ExternalVendorsSubscriptionList,
} from "models";
import { apiMiddleware } from "../api.middleware";
import { PostVendorData } from "./external-vendors.interface";

export const getExternalVendors = apiMiddleware<List<ExternalVendorsModel>>()({
  method: "get",
  endpoint: "/external-vendor",
});

export const getExternalVendor = apiMiddleware<ExternalVendorsModel>()({
  method: "get",
  endpoint: `/external-vendor/:externalVendorId`,
});

export const getVendorProviderList = apiMiddleware<ExternalVendorsSubscriptionList[]>()({
  method: "get",
  endpoint: "/external-vendor/lists/:secretId",
});

export const getVendorProviderTypesList = apiMiddleware<ExternalVendorsProvider[]>()({
  method: "get",
  endpoint: "/external-vendor/types/list",
});

export const postVendor = apiMiddleware<ExternalVendorsModel, PostVendorData>()({
  method: "post",
  endpoint: "/external-vendor",
});

export const postVendorExecute = apiMiddleware()({
  method: "post",
  endpoint: `/external-vendor/:vendorId/execute`,
});

export const putVendor = apiMiddleware<ExternalVendorsModel, PostVendorData>()({
  method: "put",
  endpoint: `/external-vendor/:vendorId`,
});

export const deleteVendor = apiMiddleware()({
  method: "delete",
  endpoint: `/external-vendor/:vendorId`,
});
