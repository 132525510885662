import { List } from "@epcnetwork/core-ui-kit";

import { OrganizationModel, RegisteredOrganizationModel, FormDataOrganization } from "models";
import { apiMiddleware } from "../api.middleware";

export const getOrganizations = apiMiddleware<List<OrganizationModel>>()({
  method: "get",
  endpoint: "/organizations",
});

export const getOrganization = apiMiddleware<OrganizationModel>()({
  method: "get",
  endpoint: "/organizations/:id",
});

export const deleteOrganization = apiMiddleware<OrganizationModel>()({
  method: "delete",
  endpoint: `/organizations/:id`,
});

export const postOrganization = apiMiddleware<OrganizationModel, FormDataOrganization>()({
  method: "post",
  endpoint: "/organizations",
});

export const putOrganization = apiMiddleware<OrganizationModel, FormDataOrganization>()({
  method: "put",
  endpoint: "/organizations/:id",
});

export const getRegisteredOrganizations = apiMiddleware<List<RegisteredOrganizationModel>>()({
  method: "get",
  endpoint: "/organizations/switch/list",
});
