import React, { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import {
  useFormValues,
  Form,
  useSubmit,
  notification,
  NonNullableKeys,
  MessageField,
  FormField,
  Button,
  FormButtons,
  SelectOption,
} from "@epcnetwork/core-ui-kit";

import { getOrganization, postOrganization, putOrganization } from "api";
import { OrganizationModel } from "models";
import { setUserOrganization } from "store";
import { NavigationContext } from "components/layout/navigation/navigation";
import { initialData, validationSchema } from "./organizations-form.constants";
import { Values } from "./organizations-form.types";

import styles from "./organizations-form.module.css";
import emptyImgIcon from "assets/images/empty-image.svg";

type Props = {
  id: string | null;
  handleCloseModal: VoidFunction;
  handleItemChange?: (value: OrganizationModel) => void;
};

const OrganizationsForm: FC<Props> = ({ id, handleItemChange, handleCloseModal }: Props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [adminsOptions, setAdminsOptions] = useState<SelectOption[]>([]);
  const { formProps, mapInitialValues } = useFormValues(
    initialData,
    getOrganization.setParams({ id: id as string }),
    !!id,
  );

  const context = useContext(NavigationContext);

  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    postOrganization,
    putOrganization.setParams({ id: id as string }),
    !!id,
  );

  mapInitialValues((payload) => {
    setAdminsOptions(payload?.admins?.map((admin) => ({ label: admin.name, value: admin.id })));
    return {
      ...payload,
      city: payload?.city,
      country: payload?.country,
      name: payload?.name,
      picture: payload?.image || "",
      owner: payload?.admins?.filter((admin) => admin.owner)[0]?.id,
    };
  });

  onSubmitSuccess((payload) => {
    handleItemChange && handleItemChange(payload);
    dispatch(setUserOrganization(payload));
    context?.refreshOrganizationsList();
    const title = id ? "Organization edited" : "Organization created";
    const subtitle = id ? "Successfully edited organization" : `Successfully created organization`;
    notification.success(title, subtitle);
    handleCloseModal();
  });

  onSubmitError((error) => {
    setError(error.message);
  });

  const onSubmit = onSubmitMapping((values: NonNullableKeys<Values>) => {
    if (id) {
      return {
        image: values.picture,
        name: values.name,
        city: values.city,
        country: values.country,
        owner: values.owner,
      };
    }

    return {
      image: values.picture,
      name: values.name,
      city: values.city,
      country: values.country,
    };
  });

  return (
    <div className={styles.container}>
      <Form {...formProps} onSubmit={onSubmit} validationSchema={validationSchema}>
        <div className={styles.title}>{!id ? "New organization" : "Edit organization"}</div>
        <MessageField message={error} />
        <div className={styles.wrapper}>
          <div className={styles.imageWrapper}>
            <div className={styles.imagePreviewContainer}>
              <div className={cn(styles.imgPreview, styles.imgPreviewCircle)}>
                <img src={emptyImgIcon} alt="" width={64} height={64} />
              </div>
              <div className={styles.uploadInfo}>
                <span className={styles.uploadText}>
                  Drag & Drop Hero Image you want to upload or{" "}
                  <Button
                    appearance="text"
                    type="button"
                    className={styles.uploadBtn}
                    onClick={() => false}
                  >
                    Browse
                  </Button>
                </span>
                <span className={styles.acceptedText}>
                  <span>Accepted: JPG, PNG, JPEG</span>
                  <span>
                    image size {122}px x {122}px, max 1 MB)
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className={styles.fieldsWrapper}>
            <div className={styles.label}>Organization setup</div>
            <FormField
              type="text"
              name="name"
              label="Organization name"
              placeholder="Enter the name of the organization"
              required
              className={styles.field}
            />
            <div className={styles.placeWrapper}>
              <FormField
                type="text"
                name="country"
                label="Country"
                placeholder="Enter the name of the country"
                className={styles.field}
              />
              <FormField
                type="text"
                name="city"
                label="City"
                placeholder="Enter the name of the city"
                className={styles.field}
              />
            </div>

            {!!id && (
              <>
                <div className={styles.label}>Organization owner</div>
                <FormField
                  type="select"
                  name="owner"
                  label="Owner"
                  options={adminsOptions}
                  placeholder="Choose a new owner"
                  disableSingleOptionUncheck
                  className={styles.field}
                />
              </>
            )}
          </div>
        </div>
        <FormButtons className={styles.buttonWrapper}>
          <Button
            type="button"
            onClick={handleCloseModal}
            appearance="secondary"
            className={styles.btn}
          >
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </FormButtons>
      </Form>
    </div>
  );
};

export default OrganizationsForm;
